import { FC, PropsWithChildren, ReactNode, RefObject } from "react"
import { Box, Flex, Heading, Text, BoxProps, Image } from "@chakra-ui/react"
import { useUserEmail } from "~/store/selectors"
import { DownloadButton } from "./DownloadButton"
import IconLoviApp from "./assets/icon-lovi.svg?react"
import screen1Url from "./assets/screen-1.webp?url"
import screen2Url from "./assets/screen-2.webp?url"
import screen3Url from "./assets/screen-3.webp?url"
import arrowStep2Url from "./assets/arrow-step-2.svg?url"
import arrowStep3Url from "./assets/arrow-step-3.svg?url"
import arrowStep4Url from "./assets/arrow-step-4.svg?url"
import { Trans, useTranslation } from "react-i18next"

const StepContent: FC<
  BoxProps & PropsWithChildren<{ orderNumber: number; titleElement: ReactNode }>
> = ({ orderNumber, children, titleElement, ...rest }) => (
  <Flex
    backgroundColor="Base/neutralPrimary"
    shadow="LoviShort"
    width="100%"
    padding={6}
    marginTop={2}
    borderRadius="brand24"
    direction="column"
    alignItems="center"
    {...rest}
  >
    <Flex
      textStyle="Subtitle/Primary"
      backgroundColor="Base/accentSecondary"
      color="Base/neutralPrimary"
      width="7"
      height="7"
      alignItems="center"
      justifyContent="center"
      borderRadius="full"
      shadow="LoviShort"
      marginBottom={4}
    >
      {orderNumber}
    </Flex>
    <Heading marginBottom={8} textAlign="center" size="Header/Secondary" maxWidth="340px">
      {titleElement}
    </Heading>
    {children}
  </Flex>
)

const IMAGE_WRAPPER_MAX_WIDTH = "325px"
const IMAGE_MAX_WIDTH = "45%"

export const StepsContent: FC<{ downloadButtonRef: RefObject<HTMLDivElement> }> = ({
  downloadButtonRef,
}) => {
  const email = useUserEmail()
  const { t } = useTranslation()
  return (
    <>
      <StepContent orderNumber={1} titleElement={t("instructions.title_download")}>
        <Flex
          backgroundColor="Base/neutralSecondary"
          padding={2}
          borderRadius="brand16"
          width="100%"
          marginBottom={6}
        >
          <IconLoviApp />
          <Box marginLeft={3}>
            <Text textStyle="Subtitle/Primary">Lóvi</Text>
            <Text textStyle="Paragraph/Primary">{t("instructions.cosmetologist_app")}</Text>
          </Box>
        </Flex>
        <Box width="100%" ref={downloadButtonRef}>
          <DownloadButton />
        </Box>
      </StepContent>
      <StepContent
        orderNumber={2}
        titleElement={<Trans i18nKey="instructions.open_and_choose" components={{ br: <br /> }} />}
      >
        <Flex maxWidth={IMAGE_WRAPPER_MAX_WIDTH} alignItems="center">
          <Image src={screen1Url} alt="App welcome screen" maxWidth={IMAGE_MAX_WIDTH} />
          <Text
            textStyle="Paragraph/Primary"
            marginLeft="8%"
            marginBottom="6%"
            textAlign="center"
            maxWidth="135px"
            position="relative"
            _before={{
              content: '" "',
              display: "block",
              position: "absolute",
              top: "42px",
              left: "-14px",
              width: "93px",
              height: "103px",
              backgroundImage: `url(${arrowStep2Url})`,
            }}
          >
            <Trans i18nKey="instructions.tap_i_have_account" components={{ b: <b /> }} />
          </Text>
        </Flex>
      </StepContent>
      <StepContent
        orderNumber={3}
        titleElement={
          <>
            <span>{t("instructions.enter_your_email_step")}</span>
            <Text color="Base/accentPrimary">{email}</Text>
          </>
        }
      >
        <Flex maxWidth={IMAGE_WRAPPER_MAX_WIDTH}>
          <Image src={screen2Url} alt="Email input screen" maxWidth={IMAGE_MAX_WIDTH} />
          <Text
            textStyle="Paragraph/Primary"
            marginLeft="12%"
            marginTop="16%"
            textAlign="center"
            position="relative"
            _before={{
              content: '" "',
              display: "block",
              position: "absolute",
              top: "24px",
              left: "-21px",
              width: "99px",
              height: "75px",
              backgroundImage: `url(${arrowStep3Url})`,
            }}
          >
            {t("instructions.tne_one_you_used")}
          </Text>
        </Flex>
      </StepContent>
      <StepContent
        orderNumber={4}
        marginBottom={110} // compensate for the Download button
        titleElement={t("instructions.start_plan_title")}
      >
        <Flex maxWidth={IMAGE_WRAPPER_MAX_WIDTH}>
          <Image src={screen3Url} alt="App home screen" maxWidth={IMAGE_MAX_WIDTH} />
          <Text
            textStyle="Paragraph/Primary"
            marginLeft="17%"
            marginTop="8%"
            textAlign="center"
            position="relative"
            maxWidth="100px"
            _before={{
              content: '" "',
              display: "block",
              position: "absolute",
              top: "47px",
              left: "-38px",
              width: "91px",
              height: "59px",
              backgroundImage: `url(${arrowStep4Url})`,
            }}
          >
            <Trans i18nKey="instructions.enjoy_your_first_task" components={{ b: <b /> }} />
          </Text>
        </Flex>
      </StepContent>
    </>
  )
}
