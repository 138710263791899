import { PersonalProducts } from "~/pages/Main/PersonalProducts/PersonalProducts"
import cleanserSrc from "./images/cleanser.webp?url"
import moisturizerSrc from "./images/moisturizer.webp?url"
import spfSrc from "./images/spf.webp?url"
import tonerSrc from "./images/toner.webp?url"
import treatmentSrc from "./images/treatment.webp?url"

const productsList = [
  { img: cleanserSrc, title: "Cleanser", description: "3 options for any budget" },
  { img: tonerSrc, title: "Toner", description: "5 options for any budget" },
  { img: treatmentSrc, title: "Treatment", description: "4 options for any budget" },
  { img: moisturizerSrc, title: "Moisturizer", description: "3 options for any budget" },
  { img: spfSrc, title: "SPF", description: "2 options for any budget" },
]

export const PersonalProductsContainer = () => {
  return <PersonalProducts productsList={productsList}></PersonalProducts>
}
