import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useDisclosure,
  Image,
  Heading,
} from "@chakra-ui/react"
import { FC } from "react"
import { noop } from "~/utils"
import { useUpsaleSubscriptionDiscountedPrice } from "~/store/selectors"

export const DiscountPrefOfferModal: FC<{ handleConfirm: () => void }> = ({ handleConfirm }) => {
  const { isOpen } = useDisclosure({ defaultIsOpen: true })
  const { economy_percent: discount = "" } = useUpsaleSubscriptionDiscountedPrice() ?? {}

  return (
    <Modal onClose={noop} isOpen={isOpen}>
      <ModalOverlay backdropFilter={"blur(6px)"} />
      <ModalContent
        px={8}
        pb={8}
        my={5}
        mx={6}
        textAlign="center"
        borderRadius="32px"
        bgColor="Utils/screenBgColor"
      >
        <ModalBody p={0} maxWidth={450} display="flex" alignItems="center">
          <Flex gap={6} flexDirection="column" alignItems="center">
            <Image h="160px" src="https://storage.pora.ai/4999c8ce3b5979095ac077091a448d36.webp" />
            <Heading as="h1" size="Header/Secondary">
              We’ve got this!
              <br />
              Commitment is hard.
            </Heading>
            <Text textStyle="Paragraph/Primary">
              We are interested in your success though so let’s try to make it easier for you.
              <br />
              <br />
              If you skip trial today, we will reduce your payment and take <br />
              <Box as="span" textColor="Base/accentPrimary">
                extra {discount} off your program
              </Box>
            </Text>
          </Flex>
        </ModalBody>

        <ModalFooter px={0} pb={0} pt={6} width="full" maxWidth={450}>
          <Box width="full">
            <Button variant="next" w="full" onClick={handleConfirm}>
              Continue
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
