import { FC, ReactNode } from "react"
import { Center, Heading, Text, TextProps } from "@chakra-ui/react"
import { TimerFloatContainer } from "~/components"
import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"
import { HeaderLogo } from "./HeaderLogo"

export const Highlight: FC<{
  children: ReactNode
  color?: TextProps["color"]
  isMedium?: boolean
}> = ({ children, color = "Base/accentPrimary", isMedium = false }) => (
  <Text as="span" color={color} fontWeight={isMedium ? "medium" : "auto"}>
    {children}
  </Text>
)

export const Header: FC = () => (
  <>
    <TimerFloatContainer variant={2} />
    <Center>
      <HeaderLogo />
    </Center>
    <Heading size="MediumHeaderPrimary" textAlign="center" marginTop={8} marginBottom={4}>
      Your personal
      <br />
      <Highlight>12-week</Highlight> program
      <br />
      is ready
    </Heading>
    <Text textAlign="center" color="Base/baseSecondary" textStyle="mediumCondensed">
      {/* fixme */}
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      pora is the greatest beauty investment you've ever made
    </Text>
  </>
)

export const HeaderBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props}>
    <Header />
  </BlockWrapper>
)
