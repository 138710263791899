import { Box, Button, Flex, Image } from "@chakra-ui/react"
import { FC, ReactNode, useCallback } from "react"
import { useAmplitude } from "~/utils/analytics/useAmplitude"
import {
  TextPart,
  TextMoneyBack,
  TextYouInformationIsSafe,
  TextNeedHelp,
  TextMoneyBack3,
  TextCopyright,
} from "~/components/StaticTexts"
import { useScrollToOffer } from "../../scrollToOffer"
import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"

const chartSrc = "https://storage.pora.ai/8eb5c26058b9a49f26c4036813a63076.webp"
const mapSrc = "https://storage.pora.ai/f5013f290803c334b66f2992c885f2e8.webp"

const MoneyBackInformationIsSafeContent: FC<{ children?: ReactNode }> = ({ children = null }) => (
  <>
    <Flex direction="column" gap={2}>
      {children}
      <TextPart>
        <TextMoneyBack />
      </TextPart>
      <TextPart>
        <TextYouInformationIsSafe />
      </TextPart>
    </Flex>

    <Box w="full" paddingX={6} marginTop={8}>
      <TextNeedHelp />
    </Box>
  </>
)

export const MoneyBackBlock: FC<BlockWrapperProps> = (props) => {
  const scrollToOffer = useScrollToOffer("money_back")
  const log = useAmplitude()
  const onClickFn = useCallback(() => {
    log.click({ source: "money_back_action_button" })
    scrollToOffer()
  }, [scrollToOffer, log])

  return (
    <BlockWrapper {...props}>
      <MoneyBackInformationIsSafeContent>
        <Button variant="action" w="full" onClick={onClickFn}>
          Get The Program
        </Button>
      </MoneyBackInformationIsSafeContent>
    </BlockWrapper>
  )
}

export const MoneyBack2Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props}>
    <TextPart>
      <TextMoneyBack />
    </TextPart>
  </BlockWrapper>
)

export const MoneyBack3Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props}>
    <TextMoneyBack3 />
  </BlockWrapper>
)

export const MoneyBackIntroBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props}>
    <MoneyBackInformationIsSafeContent />
  </BlockWrapper>
)

export const StaticMilestonesBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props} header="Program milestones">
    <Image src={chartSrc} alt="chart" w={311} />
  </BlockWrapper>
)

export const StaticMapBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper
    {...props}
    header={
      <>
        Join over 90K+ Lóvi users
        <br />
        for dream skin care
      </>
    }
  >
    <Image src={mapSrc} alt="map" w={327} />
  </BlockWrapper>
)

export const StaticCopyrightBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props}>
    <Box textStyle="Paragraph/Tertiary" textAlign="center">
      <TextCopyright />
    </Box>
  </BlockWrapper>
)

export const StaticSpanishComparisonBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props} header="Gasta menos, Luce más con Lóvi">
    <Image
      src="https://storage.pora.ai/ceebce08a29431c5b2dbbdb9fdb7440b.webp"
      alt="chart"
      w={311}
    />
  </BlockWrapper>
)
