import { FaceExercises } from "~/pages/Main/FaceExercises/FaceExercises"
import enhanceContoursSrc from "./images/fe_enhance-contours.webp?url"
import improveWrinklesSrc from "./images/fe_improve-wrinkles.webp?url"
import liftSkinSrc from "./images/fe_lift-skin.webp?url"
import maintainShapeSrc from "./images/fe_maintain-shape.webp?url"
import underlyingMusclesSrc from "./images/fe_underlying-muscles.webp?url"

const headerTexts = ["Easy-to-follow face exercises", "10 min a day"]
const exercises = [
  {
    title: "Improve the appearance of wrinkles",
    descriptions: ["3 min", "6 times"],
    img: improveWrinklesSrc,
  },
  {
    title: "Enhance facial contours",
    descriptions: ["1 min", "repeat 4 times"],
    img: enhanceContoursSrc,
  },
  {
    title: "Tone and strength the underlying muscles",
    descriptions: ["3 min", "repeat 6 times"],
    img: underlyingMusclesSrc,
  },
  {
    title: "Lift and firm sagging skin",
    descriptions: ["1 min", "repeat 4 times"],
    img: liftSkinSrc,
  },
  {
    title: "Maintain a youthful face shape",
    descriptions: ["2 min", "repeat 6 times"],
    img: maintainShapeSrc,
  },
]

export const FaceExercisesContainer = () => {
  return <FaceExercises headerTexts={headerTexts} faceExercises={exercises}></FaceExercises>
}
