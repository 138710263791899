import { FC, ReactNode, useCallback } from "react"
import {
  Box,
  Text,
  Tag,
  TagLabel,
  Icon,
  Image,
  Flex,
  Button,
  ButtonProps,
  FlexProps,
} from "@chakra-ui/react"

import { useScrollToOffer } from "~/pages/Main/scrollToOffer"
import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"

import { WarningIcon, DollarIcon } from "./Icons"
import { AffiliatedNotice } from "./AffiliatedNotice"

type TagIcon = "warning" | "price"

type ProductTagType = {
  title: string
  icon?: TagIcon
}

export type Product = {
  title: string
  producer: string
  button?: boolean
  imageSrc: string
  tags: ProductTagType[]
}

const getIcon = (icon: TagIcon): typeof WarningIcon => {
  if (icon === "price") {
    return DollarIcon
  }

  return WarningIcon
}

const ProductTag: FC<{ icon?: TagIcon | undefined; children: ReactNode }> = ({
  children,
  icon,
}) => (
  <Tag variant="error">
    {/* FIXME move display to theme. But it doesn't work for "label" theme field */}
    <TagLabel display="inline-block">
      {icon && <Icon as={getIcon(icon)} fontSize="16px" marginInlineEnd={1} marginTop={-0.5} />}
      {children}
    </TagLabel>
  </Tag>
)

const ProductTagList: FC<{ list: ProductTagType[] }> = ({ list }) => (
  <>
    {list.map(({ title, icon }) => (
      <ProductTag key={title} icon={icon}>
        {title}
      </ProductTag>
    ))}
  </>
)

const ProductImage: FC<{ src: string }> = ({ src }) => (
  <Image boxSize="64px" objectFit="contain" src={src} borderRadius="18px" bgColor="white" />
)
/* Maybe use ActionButton ? */
const ScrollButton: FC<ButtonProps> = () => {
  const scrollFn = useScrollToOffer("harmful_products_list")
  const onClick = useCallback(() => scrollFn(), [scrollFn])

  return (
    <Flex
      w="full"
      flexGrow="1"
      justifyContent="center"
      alignItems="flex-end"
      alignContent="space-between"
    >
      <Button variant="nextSmall" w="full" onClick={onClick}>
        Check in pora
      </Button>
    </Flex>
  )
}

export const ProductCard: FC<{
  title: string
  producer: string
  imageSrc: string
  tags: ProductTagType[]
  children?: ReactNode
}> = ({ title, producer, imageSrc, tags, children }) => (
  <Flex
    direction="column"
    borderRadius="28px"
    bgColor="Base/neutralDisabled"
    padding={4}
    gap={4}
    justify="flex-start"
    align="flex-start"
    minW="248px"
  >
    <Flex direction="row" justify="center" align="flex-start" gap={2} w="full">
      <ProductImage src={imageSrc} />
      <Flex direction="column" justify="flex-start" align="flex-start" gap={0.5} flex="1">
        <Box color="Base/baseSecondary" textStyle="RegularParagraphTertiary">
          {producer}
        </Box>
        <Box textStyle="MediumSubtitleSecondary">{title}</Box>
      </Flex>
    </Flex>
    <Flex direction="column" justify="flex-start" align="flex-start" w="full" gap={2}>
      <Box color="Base/baseSecondary" textStyle="RegularParagraphTertiary">
        Be aware:
      </Box>
      <Flex direction="column" justify="flex-start" align="flex-start" gap={1}>
        <ProductTagList list={tags} />
      </Flex>
    </Flex>
    {children}
  </Flex>
)

export const HarmfulProductsList: FC<FlexProps & { list: Product[] }> = ({ list, ...props }) => (
  <Flex
    direction="row"
    gap={2}
    w="full"
    overflowX="auto"
    sx={{
      "::-webkit-scrollbar": { display: "none" },
    }}
    {...props}
  >
    {list.map(({ title, producer, imageSrc, tags, button }) => (
      <ProductCard key={title} {...{ title, producer, imageSrc, tags }}>
        {button && <ScrollButton />}
      </ProductCard>
    ))}
  </Flex>
)

const U: FC<{ children: ReactNode }> = ({ children }) => (
  <Text as="span" textDecoration="underline wavy" textUnderlineOffset="5px" color="Other/Error">
    {children}
  </Text>
)

const BlockHeader: FC = () => (
  <Box paddingX={6}>
    See all the <U>warnings</U> about your skincare products
  </Box>
)

export const HarmfulProductsBlock: FC<BlockWrapperProps & { data: Product[] }> = ({
  data,
  ...props
}) => (
  <BlockWrapper {...props} header={<BlockHeader />} paddingX={0}>
    <HarmfulProductsList list={data} paddingX={6} />
    <AffiliatedNotice marginTop={3} marginBottom={-1} />
  </BlockWrapper>
)
