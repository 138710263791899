import { FC, ReactNode, useCallback, useState } from "react"
import {
  Box,
  Button,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react"
import { VFlex } from "~/components"

import chartSrc from "./chart.svg"
import { useUpsaleSubscriptionOnExit } from "./useUpsaleSubscriptionOnExit"
import { useScrollToOffer } from "~/pages/Main/scrollToOffer"
import { Trans, useTranslation } from "react-i18next"

const Content: FC<{ onClick: () => void; discountPercent: number }> = ({
  onClick,
  discountPercent,
}) => {
  const { t } = useTranslation()
  return (
    <VFlex gap={4} alignItems="center">
      <VFlex gap={2} alignItems="center">
        <Heading size="Header/Secondary">{t("upsale_subscription_popup.did_you_know")}</Heading>
        <Box
          textStyle="Paragraph/Primary"
          textAlign="center"
          sx={{
            "& em": {
              color: "Base/accentPrimary",
              fontWeight: 600,
              fontStyle: "normal",
            },
          }}
        >
          <Trans
            i18nKey="upsale_subscription_popup.lovi_user_advance"
            components={{
              em: <em />,
              br: <br />,
            }}
          />
        </Box>
      </VFlex>
      <Box alignItems="flex-end" w="full">
        <Image src={t("upsale_subscription_popup.image")} minHeight="196px" w="full" pl={4} />
      </Box>
      <VFlex
        gap={2}
        padding={2}
        bgColor="Base/accentPrimaryBg"
        alignItems="center"
        borderRadius="brand24"
        w="full"
      >
        <Box whiteSpace="pre-line" textStyle="Paragraph/Primary" textAlign="center">
          {t("upsale_subscription_popup.we_want_to_see", { discount: discountPercent })}
        </Box>
        <Box textStyle="Header/Primary" fontFamily="heading" color="Base/accentPrimary">
          –{discountPercent}% {t("upsale_subscription_popup.off")}
        </Box>
      </VFlex>
      <Button variant="next" w="full" onClick={onClick}>
        {t("upsale_subscription_popup.wow")}
      </Button>
    </VFlex>
  )
}

export const UpsaleSubscriptionOnExitModal: FC<{
  isOpen: boolean
  onClose: () => void
  children: ReactNode
}> = ({ isOpen, onClose, children }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent borderRadius="brand32" bg="white" marginX={4}>
      <ModalCloseButton
        borderRadius="full"
        bgColor="Base/neutralSecondary"
        color="Base/baseSecondary"
        top={4}
        right={4}
      />
      <ModalBody paddingX={6} paddingBottom={6} paddingTop={16}>
        {children}
      </ModalBody>
    </ModalContent>
  </Modal>
)

export const UpsaleSubscriptionOnExitModalContainer: FC = () => {
  const { popupIsOpen, close: onClose, data } = useUpsaleSubscriptionOnExit()
  const scrollToOffer = useScrollToOffer("upsale_on_exit")

  const handleCloseModal = useCallback(() => {
    onClose()
    scrollToOffer({
      openModal: false,
      scroll: true,
    })
  }, [onClose, scrollToOffer])

  return (
    <>
      <link href={chartSrc} rel="preload" as="image" />
      <UpsaleSubscriptionOnExitModal isOpen={popupIsOpen} onClose={handleCloseModal}>
        <Content onClick={handleCloseModal} discountPercent={data.discount_percent} />
      </UpsaleSubscriptionOnExitModal>
    </>
  )
}
