import { Flex, FlexProps } from "@chakra-ui/react"
import { FC } from "react"
import { SkinIssueCard, SkinIssues } from "."

export const SkinIssueList: FC<{
  items: SkinIssues[]
  marginX?: FlexProps["marginX"]
  paddingX?: FlexProps["paddingX"]
}> = ({ items, marginX, paddingX }) => (
  <Flex direction="row" gap={2} overflowX="auto" marginX={marginX} paddingX={paddingX}>
    {items.map((item, i) => (
      <SkinIssueCard key={i} issue={item} />
    ))}
  </Flex>
)
