import { Box, BoxProps, Center, Heading } from "@chakra-ui/react"
import { FC, ReactNode } from "react"

import { RiveOrImage } from "~/components/RiveImage"
import { Underline } from "~/pages/Main/HeaderPrePaywall/Icons"
import { formatDate, noop } from "~/utils"

import { VFlex } from "~/components"
import { HeaderLogo } from "./HeaderLogo"
import { HeaderHeadingPart1, HeaderHeadingPart2 } from "./HeaderHeadingPart"
import { GoalsList } from "./GoalsList"

const formatDateShort = formatDate("MONTH_DAY")

const TwoPartsBox: FC<{ color?: string; topSlot: ReactNode; bottomSlot: ReactNode }> = ({
  color = "Base/accentPrimaryBg",
  topSlot,
  bottomSlot,
}) => (
  <Box borderRadius="brand24" borderWidth={1} borderColor={color} w="full" overflow="hidden">
    <Box bgColor={color}>{topSlot}</Box>
    <Box bgColor="white">{bottomSlot}</Box>
  </Box>
)

const Content1: FC<
  { color: string; timeBlock: ReactNode; timerDescription: string } & BoxProps
> = ({ color = "Base/accentPrimary", timeBlock, timerDescription, ...props }) => (
  <Box {...props}>
    <Box mb={2} textStyle="Paragraph/Secondary">
      {timerDescription}
    </Box>
    <Box mb={2} textStyle="Subtitle/Primary" textColor={color}>
      Time remaining {timeBlock}
    </Box>
    <Box textStyle="Paragraph/Secondary" textColor="Base/baseSecondary">
      Scroll down to get started!
    </Box>
  </Box>
)

const Content2: FC<
  {
    shortEndDate: string
    resultsText: string
    graphImg: string
  } & BoxProps
> = ({ shortEndDate, resultsText, graphImg, ...props }) => (
  <Box justifyContent="center" {...props}>
    <Box textStyle="Paragraph/Secondary" textColor="Base/baseSecondary" textAlign="center">
      Based on your answers you can reach&nbsp;your&nbsp;goal
    </Box>
    <Heading
      as="h4"
      mt={3}
      mb={2}
      size="Header/Secondary"
      sx={{ textWrap: "balance" }}
      textAlign="center"
    >
      {resultsText} by{" "}
      <Box as="span" pos="relative" whiteSpace="nowrap">
        {shortEndDate}
        <Box pos="absolute" left="-9px" right="6px" bottom="-13px">
          <Underline color="Base/accentPrimary" />
        </Box>
      </Box>
    </Heading>
    <RiveOrImage ratio={757 / 581} src={graphImg} />
  </Box>
)

export const HeaderLuvly: FC<{
  title: ReactNode
  endDate: Date
  resultsText: string
  graphImg: string
  onTimerBlockClick?: () => void
  programLengthWeeks: number
  timeBlock: ReactNode
  timerDescription: string
  variant: 1 | 2
}> = ({
  title,
  endDate,
  timeBlock,
  timerDescription,
  resultsText,
  graphImg,
  onTimerBlockClick = noop,
  variant = 1,
}) => (
  <VFlex gap={6}>
    {variant === 1 ? (
      <>
        <VFlex alignItems="center">
          <HeaderLogo />
          <HeaderHeadingPart1 paddingTop={10}>{title}</HeaderHeadingPart1>
        </VFlex>
      </>
    ) : (
      <>
        <HeaderLogo />
        <HeaderHeadingPart2>{title}</HeaderHeadingPart2>
      </>
    )}

    <GoalsList />

    <TwoPartsBox
      color={variant === 1 ? "Base/accentSecondaryBg" : "Base/accentPrimaryBg"}
      topSlot={
        <Content1
          paddingX={6}
          paddingY={4}
          timeBlock={timeBlock}
          timerDescription={timerDescription}
          onClick={onTimerBlockClick}
          color={variant === 1 ? "Base/accentSecondary" : "Base/accentPrimary"}
        />
      }
      bottomSlot={
        <Content2
          shortEndDate={formatDateShort(endDate)}
          resultsText={resultsText}
          graphImg={graphImg}
          paddingX={6}
          paddingTop={6}
          paddingBottom={2}
        />
      }
    />
  </VFlex>
)
