import { ProgramBenefitsList } from "./ProgramBenefitsList"

const benefits = [
  { text: "Quick and visible results" },
  { text: "Product picks that perfectly fit you skin" },
  { text: "Monitor your skin with Face scanner" },
  { text: "Routine tailored to your goals" },
  { text: "Dermatologist support" },
]

export const ProgramBenefitsGTContainer = () => <ProgramBenefitsList benefits={benefits} />
