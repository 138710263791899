import { Box, Flex, Image } from "@chakra-ui/react"
import { ReactNode, useCallback, useMemo } from "react"
import { AttributedStringType } from "~/components/AttributedString"
import { HeaderHeadingPartWithLogo } from "~/pages/Main/Blocks/HeaderBlock/HeaderHeadingPart"
import { CosmeticsIcon, SmileIcon, TargetIcon } from "~/pages/Main/HeaderPrePaywall/Icons"
import { useIsLoviTheme } from "~/theme/theme-from-interview/themeProvider"
import { formatDate, noop } from "~/utils"
import { useAmplitude } from "~/utils/analytics/useAmplitude"

const formatDateLong = formatDate("FULL_NUMERIC")
const formatDateShort = formatDate("MONTH_DAY")

export const HeaderGT = ({
  title,
  startDate,
  endDate,
  timeBlock,
  resultsText,
  graphImg,
  onTimerBlockClick = noop,
  programLengthWeeks,
  timerDescription,
}: {
  title: AttributedStringType
  startDate: Date
  endDate: Date
  timeBlock: ReactNode
  resultsText: string
  graphImg: string
  onTimerBlockClick?: () => void
  programLengthWeeks: number
  timerDescription: string
}) => {
  const log = useAmplitude()
  const onTimerBlockClickFn = useCallback(() => {
    log.click({ source: "header_timer" })
    onTimerBlockClick()
  }, [log, onTimerBlockClick])

  const longEndDate = useMemo(() => formatDateLong(endDate), [endDate])
  const shortStartDate = useMemo(() => formatDateShort(startDate), [startDate])
  const shortEndDate = useMemo(() => formatDateShort(endDate), [endDate])
  const isLoviTheme = useIsLoviTheme()
  const iconColor = isLoviTheme ? "Base/basePrimary" : "Base/accentPrimary"

  return (
    <>
      <Box mb={8} w="full">
        <HeaderHeadingPartWithLogo title={title} />
      </Box>
      {/* TODO use <GoalsList/> */}
      <Flex textStyle="Paragraph/Primary" gap={4} px={6} flexDirection="column" mb={8}>
        <Flex alignItems="center" gap={4}>
          <TargetIcon fontSize="36px" color={iconColor} />
          <Box>
            <Box textStyle="Subtitle/Secondary" as="span">
              See visible results
            </Box>{" "}
            by {longEndDate} and&nbsp;feel great
          </Box>
        </Flex>
        <Flex alignItems="center" gap={4}>
          <CosmeticsIcon fontSize="36px" color={iconColor} />
          <Box>Get personalized product recommendations</Box>
        </Flex>
        <Flex alignItems="center" gap={4}>
          <SmileIcon fontSize="36px" color={iconColor} />
          <Box>Boost the result with easy-to-follow skincare program</Box>
        </Flex>
      </Flex>
      <Box
        onClick={onTimerBlockClickFn}
        borderRadius="3xl"
        borderWidth={1}
        borderColor="Base/neutralActive"
        bgColor="Base/accentSecondaryBg"
        mb={2}
        p={6}
      >
        <Box mb={2} textStyle="Paragraph/Secondary">
          {timerDescription}
        </Box>
        <Box mb={2} textStyle="Subtitle/Primary" textColor="Base/accentSecondary">
          Time remaining {timeBlock}
        </Box>
        <Box textStyle="Paragraph/Secondary" textColor="Base/baseSecondary">
          Scroll down to get started!
        </Box>
      </Box>
      <Box
        borderRadius="3xl"
        borderWidth={1}
        borderColor="Base/neutralActive"
        bgColor="white"
        pt={4}
        px={6}
        pb={5}
      >
        <Box mb={2} textAlign="center" textStyle="Paragraph/Secondary">
          {resultsText}
        </Box>
        <Image src={graphImg} alt="graph" mb={2} />
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <Box as="span" textColor="Base/basePrimary" textStyle="Subtitle/Tertiary">
              {shortStartDate}
            </Box>
            <Box as="span" textColor="Base/baseDisabled" textStyle="Subtitle/Tertiary">
              {" "}
              Today
            </Box>
          </Box>
          <Box textColor="Base/accentSecondaryFocused">
            <Box as="span" opacity={0.7} textStyle="Subtitle/Tertiary">
              in {programLengthWeeks} weeks
            </Box>
            <Box as="span" textStyle="Subtitle/Tertiary">
              {" "}
              {shortEndDate}
            </Box>
          </Box>
        </Flex>
      </Box>
    </>
  )
}
