import reviewImg1 from "./images/avatars/1.webp?url"
import reviewImg2 from "./images/avatars/2.webp?url"
import reviewImg3 from "./images/avatars/3.webp?url"
import { ReviewsPrePaywall } from "./ReviewsPrePaywall"

const reviews = [
  {
    author: "Melissa Joykong",
    img: reviewImg1,
    usageLength: 3,
    date: "Jan 27th, 2023",
    title: "Pora is a game-changer!",
    text: "Wow! I was recommended products that fits me 100% and more importantly don't cost like a spaceship. Finally, I don't need to overpay. Thanks, Pora! From me and my wallet :)",
  },
  {
    author: "Chelsey Johnson",
    img: reviewImg2,
    usageLength: 4,
    date: "Feb 15th, 2023",
    title: "Just thank you!",
    text: "Wow, what a game-changer! Thank you to the genius behind this incredible skincare app. Finding the right cosmetics has never been easier, and the recommendations have been spot on. The step-by-step video instructions made it easy to start using the products and now I can apply them with confidence on my own. My colleagues even commented on how rested and refreshed my face looks, as if I just got back from a vacation. Thank you so much!",
  },
  {
    author: "Merilyn Dean-Fox",
    img: reviewImg3,
    usageLength: 2,
    date: "Jan 18th, 2023",
    title: "Love it!",
    text: "Great way to make time for yourself daily",
  },
]

export const ReviewsPrePaywallContainer = () => {
  return <ReviewsPrePaywall reviews={reviews} />
}
