import { FC, ReactNode, useCallback, useMemo } from "react"
import { useSet } from "@uidotdev/usehooks"
import { motion } from "framer-motion"
import { Box, Button, Flex, IconButton, SpaceProps, Text, TextProps } from "@chakra-ui/react"
import { Link, VFlex } from "~/components"
import { CurrencyCode, formatPrice, sum } from "~/utils"
import { CheckoutProduct } from "~/generated/interview_service"

import { Card, CardPlain } from "./components"
import IconTrash from "./IconTrash.min.svg?react"
import IconClockwise from "./IconClockwise.min.svg?react"
import { PriceProps, prevPrice } from "./constants"

type ProductId = string
export interface CheckoutProductView extends CheckoutProduct {
  _id: ProductId
}

const CheckoutListItemAvailable: FC<{
  image?: string
  title?: ReactNode
  subTitle?: ReactNode
  slotTags?: ReactNode
  canBeRemoved?: boolean
  onRemove?: () => void
}> = ({ image, title, subTitle, slotTags, canBeRemoved, onRemove }) => (
  <Card gap={4}>
    {image && (
      <Box
        bgImage={image}
        bgRepeat="no-repeat"
        bgSize="contain"
        bgPosition="center"
        minW="64px"
        h="64px"
        borderColor="Base/neutralDisabled"
        borderWidth={1}
        borderRadius="brand12"
        sx={{ aspectRatio: 1 }}
      />
    )}
    <VFlex w="fit-content" flexGrow={1}>
      {title && <Box textStyle="Subtitle/Tertiary">{title}</Box>}
      {subTitle && (
        <Box mt={0.5} textStyle="Paragraph/Secondary" color="Base/baseSecondary">
          {subTitle}
        </Box>
      )}
      {slotTags && <Box mt={2}>{slotTags}</Box>}
    </VFlex>
    {canBeRemoved && (
      <Box w={5}>
        <IconButton
          position="relative"
          aria-label="Remove"
          marginTop={-3}
          marginLeft={-4}
          w="48px"
          h="48px"
          variant="ghost"
          color="Base/baseDisabled"
          icon={<IconTrash />}
          onClick={onRemove}
          _hover={{ bgColor: "none" }}
        />
      </Box>
    )}
  </Card>
)

const CheckoutListItemRemoved: FC<{
  title?: string
  subTitle?: string
  onRestore?: () => void
}> = ({ title, subTitle, onRestore }) => (
  <Card gap={4} color="Base/baseSecondary" justifyContent="space-between">
    <VFlex w="fit-content" color="Base/baseSecondary" textStyle="Subtitle/Tertiary">
      {title} {subTitle} deleted
    </VFlex>
    <Button
      flexGrow={1}
      minWidth="102px"
      maxWidth="102px"
      h="auto"
      onClick={onRestore}
      leftIcon={<IconClockwise />}
      mr={-3}
      mt={-3}
      mb={-3}
      variant="ghost"
      color="Base/accentPrimary"
      textStyle="Subtitle/Secondary"
      _hover={{ bgColor: "none", color: "Base/accentDisabled" }}
    >
      Restore
    </Button>
  </Card>
)

const StrikeThrough: FC<TextProps> = ({ ...props }) => (
  // @ts-ignore FIXME
  <Text
    // @ts-ignore FIXME
    as="span"
    textDecoration="line-through"
    textDecorationColor="Base/accentPrimary"
    {...props}
  />
)

export const CheckoutListItem: FC<{
  isSelectedToOrder: boolean
  image?: string
  title?: string
  subTitle?: string
  price?: number
  currency?: CurrencyCode
  slotTags?: ReactNode
  canBeRemoved?: boolean
  onRestore?: () => void
  onRemove?: () => void
}> = ({
  isSelectedToOrder,
  image,
  title,
  subTitle,
  price = 0,
  currency = "USD",
  slotTags,
  canBeRemoved,
  onRemove,
  onRestore,
}) => (
  <motion.div
    key={isSelectedToOrder ? "available" : "removed"}
    initial={{ rotateX: "90deg" }}
    animate={{ rotateX: "0deg" }}
    exit={{ rotateX: "90deg" }}
    transition={{ ease: "easeOut", duration: 0.2 }}
  >
    {isSelectedToOrder ? (
      <CheckoutListItemAvailable
        image={image}
        title={title}
        subTitle={
          <>
            {subTitle}
            {" · "}
            {formatPrice(currency, price)}
            <StrikeThrough
              textStyle="Paragraph/Tertiary"
              color="Base/baseDisabled"
              position="relative"
              left="1ex"
              top={-0.5}
            >
              {formatPrice(currency, prevPrice(price))}
            </StrikeThrough>
          </>
        }
        slotTags={slotTags}
        canBeRemoved={canBeRemoved}
        onRemove={onRemove}
      />
    ) : (
      <CheckoutListItemRemoved title={title} subTitle={subTitle} onRestore={onRestore} />
    )}
  </motion.div>
)

export const CheckoutSummaryCard: FC<PriceProps> = ({
  price,
  currency,
  previousPrice = 0,
  period = "",
}) => (
  <Card gap={4} alignItems="center">
    <Box textStyle="Paragraph/Secondary" color="Base/baseSecondary" w="64px">
      Total
    </Box>
    <VFlex>
      <Flex textStyle="Subtitle/Primary" gap={1}>
        <Text as="span">{formatPrice(currency, price)}</Text>
        {previousPrice > 0 && (
          <StrikeThrough color="Base/baseDisabled">
            {formatPrice(currency, previousPrice)}
          </StrikeThrough>
        )}
      </Flex>
      <Box textStyle="Paragraph/Secondary" color="Base/baseSecondary">
        {period}
      </Box>
    </VFlex>
  </Card>
)

const getProductId = (product: CheckoutProduct) => product.checkout_link_parameters
const toProductVew = (product: CheckoutProduct): CheckoutProductView => ({
  ...product,
  _id: getProductId(product),
})

export const useCheckoutList = (_products: CheckoutProduct[]) => {
  const products = useMemo(() => _products.map(toProductVew), [_products])
  const productsToCheckout = useSet<ProductId>(products.map(({ _id }) => _id))
  const totalPrice = useMemo(
    () => sum(products.filter(({ _id }) => productsToCheckout.has(_id)).map((v) => v.price)),
    [products, productsToCheckout.size]
  )

  const onRemoveItem = useCallback(
    (id: ProductId) => () => {
      productsToCheckout.delete(id)
    },
    [productsToCheckout]
  )

  const onRestoreItem = useCallback(
    (id: ProductId) => () => {
      productsToCheckout.add(id)
    },
    [productsToCheckout]
  )

  return { products, productsToCheckout, totalPrice, onRemoveItem, onRestoreItem }
}

export const CartSubscriptionText: FC<SpaceProps & PriceProps> = ({
  price,
  currency,
  ...props
}) => (
  <CardPlain p={4} {...props}>
    <VFlex
      color="Base/baseSecondary"
      textStyle="Paragraph/Tertiary"
      gap="1ex"
      sx={{ "& :link, & :visited": { color: "Base/basePrimary" } }}
    >
      <p>This is an autorenewing subscription.</p>
      <p>
        You&apos;re enrolling in Lóvi&apos;s monthly {/* FIXME */} skincare delivery for a
        consistent price of {formatPrice(currency, price)}. This charge recurs monthly until you
        decide to cancel. Manage your subscription or cancel anytime through support at{" "}
        <Link href="mailto:care@pora.ai">care@lovi.care</Link>.
      </p>
      <p>
        Please note that this subscription is bound by Lóvi&apos;s{" "}
        <Link href="https://lovi.care/privacy-policy/" target="_blank">
          Privacy Policy
        </Link>
        ,{" "}
        <Link href="https://lovi.care/terms/" target="_blank">
          Terms of Use
        </Link>
        , and{" "}
        <Link href="https://lovi.care/money-back/" target="_blank">
          Refund Policy
        </Link>
        .
      </p>
    </VFlex>
  </CardPlain>
)
