import { FC } from "react"
import { TimerFloatContainer } from "~/components"
import { BlockWrapper, BlockWrapperProps } from "./BlockWrapper"

export const TimerFloat4Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper variant="invisible" {...props}>
    <TimerFloatContainer variant={4} />
  </BlockWrapper>
)

export const TimerFloat5TopBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper variant="invisible" {...props}>
    <TimerFloatContainer variant="5top" />
  </BlockWrapper>
)

export const TimerFloat5BottomBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper variant="invisible" {...props}>
    <TimerFloatContainer variant="5bottom" />
  </BlockWrapper>
)

export const TimerFloat6Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper variant="invisible" {...props}>
    <TimerFloatContainer variant={6} />
  </BlockWrapper>
)
