import { useCallback, useMemo } from "react"
import UserAgentParser from "ua-parser-js"
import { getUserIdFromUrl } from "@poraai/shared/url"

import { sendLog } from "~/api/api"
import { CONFIG } from "~/config"
import { snakecase } from "~/utils"
import { useAnalyticsParameters } from "~/store/selectors"

const toJsonString = (c: unknown): string => JSON.stringify(c)

/* Copy in packages/quiz-app/src/hooks/analytics/useAmplitude.ts */
export const makeParams = (
  initObj: Record<string, unknown> = {},
  input: Record<string, { values?: string[] }>
): Record<string, string[] | string | number> => {
  const result = Object.fromEntries(
    Object.entries(initObj).map(([k, v]): [string, string | number | string[]] => {
      let value: string[] | string | number = ""
      if (typeof v === "number" || typeof v === "string") {
        value = v
      } else if (Array.isArray(v)) {
        value = v.map(toJsonString)
      }
      return [k, value]
    })
  )
  for (const i in input) {
    // todo remove
    // eslint-disable-next-line no-prototype-builtins
    if (input.hasOwnProperty(i)) {
      const values = input[i]?.values
      if (values) {
        result[i] = values
      }
    }
  }
  return result
}

// https://paltaskincare.notion.site/Analytics-Events-9fa8d78ac3ba418089402cfc7cd949a9
const createEventBuilder =
  (defaultParams: Record<string, { values?: string[] }>) =>
  (event: string) =>
  (eventParams: Record<string, unknown> = {}) => {
    //console.log(`Amplitude: sending ${eventName} ${JSON.stringify(opts)}`);
    const params = makeParams(eventParams, defaultParams)
    window.amplitude?.getInstance()?.logEvent(event, params)
    window.mixpanel?.track(event, params)
    window.posthog?.capture(event, params)
    sendLog(event, params)
  }

const amplitudeEvents = [
  "init",
  "paywallView",
  "offerResult",
  "clickActionButton",
  "clickOfferButton",
  "clickPrice",
  "click",
  "successContinuePress",
  "offerTryAgain",
  "offerSupportPress",
  "offerContinueResult",
  "offerStartPress",
  "paymentFormShow",
  "paymentFormHide",
  "paymentFormInitStarted",
  "paymentFormShowSuccess",
  "paymentFormShowFail",
  "paymentStatusChange",
  "paymentError",
  "paymentProcessing",
  "paymentFormButtonClick",
  "paymentFormSceneChange",
  "paymentFormInputFocusIn",
  "paymentFormInputFocusOut",
  "paymentFormChangeType",
  "paymentFormPopupOpen",
  "paymentFormPopupClose",
  "priceSelectPopupShow",
  "priceSelectPopupClickPrice",
  "priceSelectPopupConfirm",
  "paymentPaypalStatusChange",
  "paymentPaypalClick",
  "paymentApplePayClick",
  "paymentApplePayStatusChange",
  "scrollToOffer",
  "skipTrialView",
  "skipTrialConfirm",
  "skipTrialConfirmSuccess",
  "skipTrialConfirmDiscount",
  "skipTrialConfirmDiscountSuccess",
  "skipTrialError",
  "upsaleWorkbookView",
  "upsaleWorkbookBuy",
  "upsaleWorkbookBuySuccess",
  "upsaleWorkbookBuyFail",
  "upsaleWorkbookExit",
  "upsaleWorkbookBuyError",
  "upsaleAIScannerView",
  "upsaleAIScannerBuy",
  "upsaleAIScannerBuySuccess",
  "upsaleAIScannerBuyFail",
  "upsaleAIScannerExit",
  "upsaleAIScannerBuyError",
  "upsaleSubscriptionOnExitPopupShow",
  "upsaleSubscriptionOnExitPopupHide",
  "upsaleSubscriptionOnExitActivate",
  "upsaleNewSubscriptionView",
  "upsaleNewSubscriptionBuy",
  "upsaleNewSubscriptionBuySuccess",
  "upsaleNewSubscriptionBuyFail",
  "upsaleNewSubscriptionBuyExit",
  "upsaleAIAssistantView",
  "upsaleAIAssistantBuy",
  "upsaleAIAssistantBuySuccess",
  "upsaleAIAssistantBuyFail",
  "upsaleAIAssistantExit",
  "upsaleAIAssistantBuyError",
  "upsaleBundleView",
  "upsaleBundleBuy",
  "upsaleBundleBuySuccess",
  "upsaleBundleBuyFail",
  "upsaleBundleBuyExit",
] as const

type AmplitudeEvents = (typeof amplitudeEvents)[number]

export const useAmplitude = () => {
  const userId = getUserIdFromUrl()
  const defaultPrams = useAnalyticsParameters()
  const createEvent = useMemo(() => createEventBuilder(defaultPrams), [defaultPrams])

  const init = useCallback(() => {
    const userAgent = new UserAgentParser(window.navigator.userAgent).getResult()
    window.amplitude?.getInstance()?.setUserProperties({
      environment: CONFIG.environment,
      userAgent,
    })
    if (userId) {
      window.amplitude?.getInstance()?.setUserId(userId)
      window.mixpanel?.identify(userId)
      // Identify user in posthog after purchase
    }
  }, [userId])

  const result = useMemo(() => {
    const obj = Object.fromEntries(
      amplitudeEvents.map((e) => [e, createEvent(snakecase(e))])
    ) as Record<AmplitudeEvents, (eventParams?: Record<string, unknown>) => void>
    obj.init = init
    return obj
  }, [createEvent, amplitudeEvents])

  return result
}
