import { FC, ReactNode, useContext } from "react"
import { Button, Heading, Image, Box } from "@chakra-ui/react"
import { ButtonSlideUpContainer } from "~/components/ButtonSlideUpContainer"
import { VFlex } from "~/components"

import iconSuccess from "./assets/icon-success.svg"
import iconFailure from "./assets/icon-failure.svg"
import iconExclamation from "./assets/icon-exclamation.svg"

import { UpsaleContext } from "../UpsaleContext"
import i18n from "~/i18n"

const variantMap = {
  workbookSuccess: {
    title: "Congratulations!",
    description: (
      <>
        Lóvi Workbook is sent to your email.
        <br />
        Let&apos;s start your personal skincare journey in the Lóvi app!
      </>
    ),
    icon: iconSuccess,
    buttonTitle: "Continue",
  },
  workbookFail: {
    title: "Workbook wasn't purchased",
    description: "But let’s start your personal skincare journey in Lóvi app!",
    icon: iconFailure,
    buttonTitle: "Continue",
  },
  aiScanSuccess: {
    title: "Success",
    description: (
      <>
        AI Product Scan is now unlocked in the Lovi App and the Workbook is in your email.
        <br />
        Start skincare journey!
      </>
    ),
    icon: iconSuccess,
    buttonTitle: "Continue",
  },
  aiScanFail: {
    title: "AI product scan and Workbook weren’t purchased ",
    description: "Don’t worry, you will be able to purchase them in the app",
    icon: iconFailure,
    buttonTitle: "Continue",
  },
  bundleSuccess: {
    ...i18n.t("upsale_statuses.bundleSuccess", { returnObjects: true }),
    icon: iconSuccess,
  },
  bundleFail: {
    ...i18n.t("upsale_statuses.bundleFail", { returnObjects: true }),
    icon: iconFailure,
  },
  aiAssistantSuccess: {
    title: "Success!",
    description: (
      <>
        AI Assistant is now unlocked in the Lovi App and the Workbook is in your email.
        <br />
        Start skincare journey!
      </>
    ),
    icon: iconSuccess,
    buttonTitle: "Continue",
  },
  aiAssistantFail: {
    title: "AI Assistant and Workbook weren’t purchased",
    description: "Don’t worry, you will be able to purchase them in the app",
    icon: iconFailure,
    buttonTitle: "Continue",
  },
  shopifyProductsSuccess: {
    title: "Success!",
    description: (
      <>
        Your tailored skincare product kit is on its way! 🎉 While waiting, why not start your
        skincare journey with our app today?
      </>
    ),
    icon: iconSuccess,
    buttonTitle: "Continue",
  },
  shopifyProductsFail: {
    title: "No pressure!",
    description: (
      <>
        You can order your delivery of skincare products directly in the app during the first few
        days of your plan.
        <br />
        Start your journey with Lovi today!
      </>
    ),
    icon: iconFailure,
    buttonTitle: "Continue",
  },
  appSubscriptionSuccess: {
    ...i18n.t("upsale_statuses.appSubscriptionSuccess", { returnObjects: true }),
    icon: iconSuccess,
  },
  paymentFail: {
    ...i18n.t("upsale_statuses.paymentFail", { returnObjects: true }),
    icon: iconExclamation,
  },
} as const

export type PaymentSuccessVariant = keyof typeof variantMap

export const PaymentStatus: FC<{
  variant: PaymentSuccessVariant
  onClick?: () => void
}> = ({ variant, onClick }) => {
  const { title, description, icon, buttonTitle } = variantMap[variant]
  const { goNext } = useContext(UpsaleContext)
  const _onClick = onClick || goNext

  return (
    <PaymentStatusLayout
      onClick={_onClick}
      title={title}
      description={description}
      icon={icon}
      buttonTitle={buttonTitle}
    />
  )
}

export const PaymentStatusLayout: FC<{
  title: ReactNode
  description: ReactNode
  icon: string
  buttonTitle?: string
  onClick?: () => void
}> = ({ title, description, icon, onClick, buttonTitle }) => (
  <VFlex placeContent="center" placeItems="center" minH="$100vh" paddingTop={10} paddingX={6}>
    <Image src={icon} maxWidth="60%" />
    <Heading
      marginTop={6}
      marginBottom={4}
      textAlign="center"
      size="Header/Primary"
      sx={{ textWrap: "balance" }}
    >
      {title}
    </Heading>
    {/* margin bottom for Download button compensation */}
    <Box marginBottom={110} textAlign="center" maxWidth="85%" sx={{ textWrap: "balance" }}>
      {description}
    </Box>
    {onClick && buttonTitle && (
      <ButtonSlideUpContainer visible showGradient={false}>
        <Button onClick={onClick} variant="next" w="full">
          {buttonTitle}
        </Button>
      </ButtonSlideUpContainer>
    )}
  </VFlex>
)
