import { FC, useMemo } from "react"
import Rive, { Alignment, Layout, LayoutParameters, RuntimeLoader } from "@rive-app/react-canvas"
import { Image, AspectRatio, AspectRatioProps, ImageProps } from "@chakra-ui/react"

import riveWASMResource from "@rive-app/canvas/rive.wasm?url"
RuntimeLoader.setWasmUrl(riveWASMResource)

export { Alignment as RiveAlignment } from "@rive-app/react-canvas"

const alMap: Record<Alignment, ImageProps["objectPosition"]> = {
  [Alignment.TopRight]: "top right",
  [Alignment.TopCenter]: "top center",
  [Alignment.TopLeft]: "top left",

  [Alignment.CenterRight]: "center right",
  [Alignment.Center]: "center",
  [Alignment.CenterLeft]: "center left",

  [Alignment.BottomRight]: "bottom right",
  [Alignment.BottomCenter]: "bottom center",
  [Alignment.BottomLeft]: "bottom left",
}

const alignmentToObjectPosition = (a: Alignment): ImageProps["objectPosition"] =>
  alMap[a] ?? alMap[Alignment.Center]

export const RiveOrImage: FC<
  {
    src: string
    alignment?: LayoutParameters["alignment"]
    ratio: AspectRatioProps["ratio"]
  } & AspectRatioProps
> = ({ src, ratio, alignment = Alignment.Center, ...props }) => {
  const [layout, position] = useMemo(
    () => [new Layout({ alignment }), alignmentToObjectPosition(alignment)],
    [alignment]
  )
  const children = src.endsWith(".riv") ? (
    <Rive key={src || ""} src={src} layout={layout} shouldResizeCanvasToContainer />
  ) : (
    <Image src={src} objectFit="contain" objectPosition={position} />
  )

  return (
    <AspectRatio
      sx={{
        "& > img, & > video": {
          objectFit: "contain",
        },
      }}
      ratio={ratio}
      {...props}
    >
      {children}
    </AspectRatio>
  )
}
