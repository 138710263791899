import { Heading, Image, Text } from "@chakra-ui/react"
import { FC } from "react"
import { AppStoreIcon } from "./Icons"
import screenshot from "./screenshot.webp?url"

export const AppScreenshots: FC = () => (
  <>
    <Heading size="MediumHeaderSecondary" textAlign="center" marginBottom={4}>
      {/* fixme */}
      {/* eslint-disable-next-line no-irregular-whitespace */}
      Skincare at your fingertips with pora app
    </Heading>
    <Text
      textAlign="center"
      color="Base/baseSecondary"
      textStyle="mediumCondensed"
      marginBottom={4}
    >
      Daily routine, useful insights, face massage exercises, and a whole lot more
    </Text>
    <AppStoreIcon width="120px" height="41px" />
    <Image src={screenshot} alt="Pora app screenshot" w={327} h={387} mb={-8} />
  </>
)
