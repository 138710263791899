import { FC } from "react"
import { Box, BoxProps } from "@chakra-ui/react"

export const FaceScanResultRender: FC<{ image: string } & BoxProps> = ({ image, ...props }) => (
  <Box
    w="full"
    sx={{
      aspectRatio: "0.8",
    }}
    backgroundImage={image}
    backgroundRepeat="no-repeat"
    backgroundSize="cover"
    backgroundPosition="center"
    {...props}
  />
)
