import { FC, useEffect } from "react"
import { useNavigateWithSearchParams } from "@poraai/shared/useNavigateWithSearchParams"

import { useAmplitude } from "~/utils/analytics/useAmplitude"

import { PaymentStatus } from "./PaymentStatus"

export const ProcessingError: FC = () => {
  const log = useAmplitude()
  const navigate = useNavigateWithSearchParams()

  useEffect(() => {
    log.offerContinueResult({ result: "payment_error" })
  }, [log])

  const onClick = () => {
    log.offerTryAgain()

    navigate("/")
  }

  return <PaymentStatus variant="paymentFail" onClick={onClick} />
}
