import { FC, useEffect } from "react"

import { useUserEmail } from "~/store/selectors"
import { useAmplitude } from "~/utils/analytics/useAmplitude"
import { PaymentStatusLayout } from "./PaymentStatus"
import iconEllipsis from "./assets/icon-ellipsis.svg"

export const ProcessingInProgress: FC = () => {
  const log = useAmplitude()
  const email = useUserEmail("") ?? ""

  useEffect(() => {
    log.paymentProcessing()
  }, [log])

  const description = (
    <>
      When the payment will be done, we’ll send notification to e-mail address <b>{email}</b>
    </>
  )
  return (
    <PaymentStatusLayout title="Processing Payment" description={description} icon={iconEllipsis} />
  )
}
