import { FC } from "react"
import { PRICE_SELECT_ID } from "~/constants"
import { PriceSelectWithPaymentForm } from "../PriceSelectWithPaymentForm"
import { BlockWrapper, BlockWrapperProps } from "./BlockWrapper"

export const PriceSelectWithPaymentFormBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper id={PRICE_SELECT_ID} {...props}>
    <PriceSelectWithPaymentForm withPopup={false} />
  </BlockWrapper>
)
