import { FC, ReactNode, useMemo } from "react"
import { Box, Flex, Icon } from "@chakra-ui/react"

import { ExternalBox, VFlex } from "~/components"
import { CurrencyCode, formatPrice, getCurrencySymbol, noop } from "~/utils"

import { CheckedRadio, UncheckedRadio } from "./Icons"
import { Strike } from "./Strike"
import type { PriceItemProps } from "./types"
import { toFractionTuple } from "./toFractionTuple"

const Triangle: FC = () => (
  <svg viewBox="0 0 23 52" width="23" height="52">
    <path
      d="M 1 23 L 23 0 L 23.5 0 L 23.5 52 L 23 52 L 1.06 28.7 C -0.35 27.18 -0.354 25 1 23.28 Z"
      fill="currentColor"
    />
  </svg>
)

const PriceItemBadge: FC<{ children: ReactNode }> = ({ children }) => (
  <Box
    paddingX={2}
    paddingY={0.25}
    borderRadius="8px"
    borderBottomLeftRadius={0}
    background="Base/accentSecondary"
    color="Base/neutralPrimary"
    textStyle="Other/PriceTag"
  >
    {children}
  </Box>
)

const PriceLayout: FC<{
  isSelected?: boolean
  label?: ReactNode
  children: ReactNode
  onClick?: () => void
}> = ({ isSelected = false, label, children, onClick = noop }) => (
  <Box as="label" aria-checked={isSelected}>
    <input type="checkbox" aria-checked={isSelected} hidden />

    {label && (
      <ExternalBox top="-8px">
        <PriceItemBadge>{label}</PriceItemBadge>
      </ExternalBox>
    )}
    <Flex
      paddingStart={4}
      paddingEnd={2}
      paddingY={2}
      direction="row"
      justify="flex-start"
      align="center"
      gap={3}
      borderColor="Base/neutralSecondary"
      borderWidth={2}
      borderRadius={20}
      borderTopLeftRadius={label ? 0 : undefined}
      onClick={onClick}
      w="full"
      cursor="pointer"
      userSelect="none"
      aria-checked={isSelected}
      _checked={{
        borderColor: "Base/accentSecondary",
      }}
    >
      <Icon
        fontSize={28}
        as={isSelected ? CheckedRadio : UncheckedRadio}
        aria-checked={isSelected}
        color="Base/baseDisabled"
        _checked={{
          color: "Base/accentSecondary",
        }}
      />
      {children}
    </Flex>
  </Box>
)

const PriceNumbers: FC<{
  currency: CurrencyCode
  prevPricePerPeriod: number
  pricePerPeriod: number
  isSelected?: boolean
}> = ({ currency, pricePerPeriod, prevPricePerPeriod, isSelected = false }) => {
  const price = useMemo(() => toFractionTuple(pricePerPeriod), [pricePerPeriod])
  const priceSymbol = useMemo(() => getCurrencySymbol(currency), [currency])
  return (
    <Flex justify="flex-start" align="center">
      <ExternalBox textStyle="Subtitle/Hint" left="-100%" top="11px">
        <Strike textDecorationColor="Base/accentSecondary">
          {formatPrice(currency, prevPricePerPeriod)}
        </Strike>
      </ExternalBox>
      <Box
        position="relative"
        aria-checked={isSelected}
        color="Base/neutralDisabled"
        _checked={{
          color: "Base/accentSecondary",
        }}
        sx={{
          "@supports (font: -apple-system-body) and (-webkit-appearance: none) &": {
            backgroundColor: "red",
            right: "-0.3px",
          },
        }}
      >
        <Triangle />
      </Box>
      <Flex
        padding={1}
        aria-checked={isSelected}
        borderRightRadius="12px"
        gap={0.5}
        color="Base/basePrimary"
        bgColor="Base/neutralDisabled"
        _checked={{
          color: "Base/neutralPrimary",
          bgColor: "Base/accentSecondary",
        }}
      >
        <Box paddingY={1} textStyle="Subtitle/Secondary">
          {priceSymbol}
        </Box>
        <Box fontFamily="heading" textStyle="Lead/Monospaced">
          {price[0]}
        </Box>
        <VFlex paddingY={0.5}>
          <Box textStyle="Subtitle/Secondary">{price[1]}</Box>
          <Box textStyle="Subtitle/Hint">per day</Box>
        </VFlex>
      </Flex>
    </Flex>
  )
}

const PriceLayoutTitle: FC<{ title: ReactNode; subtitle: ReactNode }> = ({ title, subtitle }) => (
  <VFlex gap={1} flex="1">
    <Box textStyle="Subtitle/Tertiary">{title}</Box>

    <Flex direction="row" gap={1} textStyle="Subtitle/Hint">
      {subtitle}
    </Flex>
  </VFlex>
)

export const PriceItemWrapper: FC<PriceItemProps & { children: ReactNode }> = ({
  currency_code,
  children,
  isSelected,
  pricePerDay,
  previousPricePerDay,
  isPopular = false,
  onClick = noop,
}) => {
  let label = null
  if (isPopular) {
    label = "MOST POPULAR"
  }

  return (
    <PriceLayout isSelected={isSelected} label={label} onClick={onClick}>
      {children}
      <PriceNumbers
        currency={currency_code}
        isSelected={isSelected}
        /* FIXME parseFloat */
        pricePerPeriod={parseFloat(pricePerDay)}
        /* FIXME parseFloat */
        prevPricePerPeriod={parseFloat(previousPricePerDay)}
      />
    </PriceLayout>
  )
}

export const PriceItem2: FC<PriceItemProps> = (props) => {
  const { currency_code, title, trialPrice, previousPrice } = props
  const subtitle = (
    <>
      <Strike color="Base/baseSecondary">
        {formatPrice(currency_code, parseFloat(previousPrice))}
      </Strike>{" "}
      {formatPrice(currency_code, trialPrice / 100)}
    </>
  )

  return (
    <PriceItemWrapper {...props}>
      <PriceLayoutTitle title={title} subtitle={subtitle} />
    </PriceItemWrapper>
  )
}
