import { FC, ReactNode } from "react"
import { Box, Divider, Flex, Heading, Text } from "@chakra-ui/react"
import { SkinIssues } from "~/generated/paywall"
import { VFlex, BlockPropertyContent, ProgressBar } from "~/components"
import { ActionButton } from "~/pages/Main/ActionButton"
import { useFaceScanResultIssues } from "~/store/selectors"
import { getScaleValue } from "~/utils/getScaleValue"

import { IssuesListItem } from "./IssueListItem"
import { LockIcon } from "./icons"
import { FaceScanResultRenderContainer } from "./FaceScanResultRenderContainer"
import { Trans, useTranslation } from "react-i18next"

const FaceScanImage: FC = () => (
  <FaceScanResultRenderContainer
    width="calc(100% + 24px + 24px)"
    marginX={-6}
    sx={{ aspectRatio: "1" }}
  />
)

const TargetPoints: FC<{ data: SkinIssues[] }> = ({ data = [] }) => {
  const { t } = useTranslation()
  return (
    <VFlex gap={3} w="full">
      <Heading as="h5" size="Header/Tertiary">
        {t("face_scan_result.your_target_points")}
      </Heading>

      <Box>
        <Flex
          gap={3}
          marginX={-6}
          // w="calc(100% + 24px + 24px)"
          overflow="auto"
          sx={{
            "& > div:first-of-type": {
              paddingLeft: 6,
            },
            "& > div:last-of-type": {
              paddingRight: 6,
            },
          }}
        >
          {data.map((issue, i) => (
            <IssuesListItem issue={issue} key={i} />
          ))}
        </Flex>
      </Box>
    </VFlex>
  )
}

const TargetPointsContainer: FC = () => {
  const data = useFaceScanResultIssues()
  return data ? <TargetPoints data={data} /> : null
}

const ProgressNumber: FC<{
  color: string
  bgColor: string
  value: number
  title: string
}> = ({ color, bgColor, value, title }) => (
  <VFlex gap={0.5} justifyContent="stretch" w="full">
    <Box fontFamily="heading" textStyle="Header/Secondary">
      {value}%
    </Box>
    <ProgressBar bgColor={bgColor} color={color} value={value} />
    <Box textStyle="Paragraph/Primary">{title}</Box>
  </VFlex>
)

const FakeNumbers: FC = () => {
  const { t } = useTranslation()
  return (
    <Flex gap={4}>
      <ProgressNumber
        color="Other/Info"
        bgColor="Other/Info/20"
        value={getScaleValue("HYDRATION", false)}
        title={t("face_scan_result.scales.hydration")}
      />
      <ProgressNumber
        color="Other/Green"
        bgColor="Other/Green/20"
        value={getScaleValue("ELASTICITY", false)}
        title={t("face_scan_result.scales.elasticity")}
      />
      <ProgressNumber
        color="Other/New"
        bgColor="Other/New/20"
        value={getScaleValue("COMPLEXION", false)}
        title={t("face_scan_result.scales.complexion")}
      />
    </Flex>
  )
}

const Em: FC<{ color: string; children?: ReactNode }> = ({ children, color }) => (
  <Text as="span" textStyle="Subtitle/Secondary" color={color}>
    {children}
  </Text>
)

const ReportText: FC = () => (
  <VFlex gap={3}>
    <Text textStyle="Paragraph/Primary">
      <Trans
        i18nKey="face_scan_result.reports.skin_hydration"
        components={{
          em: <Em color="Other/Info" />,
        }}
      />
    </Text>
    <Text textStyle="Paragraph/Primary">
      <Trans
        i18nKey="face_scan_result.reports.skin_elasticity"
        components={{
          em: <Em color="Other/Green" />,
        }}
      />
    </Text>
    <Text textStyle="Paragraph/Primary">
      <Trans
        i18nKey="face_scan_result.reports.complexion"
        components={{
          em: <Em color="Other/New" />,
        }}
      />
    </Text>
  </VFlex>
)
const FADE_BG =
  "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.26) 18.17%, rgba(255, 255, 255, 0.9) 47.6%, #FFF 74.28%)"

const Fade: FC = () => {
  const { t } = useTranslation()
  return (
    /* ExternalBox ? */
    <Box pos="relative" top="-222px" w="full">
      <Box pos="absolute" w="full">
        <VFlex
          gap={4}
          bg={FADE_BG}
          height="222px"
          w="full"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Box textAlign="center">
            <LockIcon fontSize="24px" color="Base/accentPrimary" />

            <Box textStyle="Subtitle/Primary" color="Base/accentPrimary">
              {t("face_scan_result.more_data")}
            </Box>
          </Box>
          <ActionButton actionSource="PB_FACESCAN_RESULTS">
            <BlockPropertyContent propKey="PB_FACESCAN_RESULTS.button_text">
              {t("face_scan_result.get_full")}
            </BlockPropertyContent>
          </ActionButton>
        </VFlex>
      </Box>
    </Box>
  )
}

export const FaceScanResult: FC = () => (
  <VFlex gap={6} w="full">
    <FaceScanImage />
    <TargetPointsContainer />
    <Divider color="Base/neutralSecondary" />
    <FakeNumbers />
    <ReportText />
    <Fade />
  </VFlex>
)
