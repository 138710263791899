import { createIcon } from "@chakra-ui/react"

export const FlashIcon = createIcon({
  viewBox: "0 0 16 18",
  defaultProps: {
    width: "16px",
    height: "18px",
    fill: "none",
  },
  path: (
    <path
      d="M3.02344 9.87793C3.02344 9.71387 3.0918 9.57031 3.21484 9.41992L9.59277 1.51758C10.085 0.90918 10.8574 1.30566 10.5771 2.05078L8.49219 7.65625H12.457C12.7578 7.65625 12.9766 7.86133 12.9766 8.14844C12.9766 8.30566 12.915 8.44922 12.792 8.60645L6.40723 16.5088C5.91504 17.1104 5.14258 16.7139 5.42285 15.9688L7.50781 10.3701H3.54297C3.24219 10.3701 3.02344 10.1582 3.02344 9.87793Z"
      fill="white"
    />
  ),
})
