import { Box, Image } from "@chakra-ui/react"
import { FC, ReactNode, useCallback } from "react"
import { AttributedStringType, TimerFloatContainer } from "~/components"
import { HeaderHeadingPartWithLogo } from "~/pages/Main/Blocks/HeaderBlock/HeaderHeadingPart"
import { noop } from "~/utils"
import { useAmplitude } from "~/utils/analytics/useAmplitude"
import heroImage from "./heroImage.webp"

const Timer: FC<{ offer: ReactNode; time: ReactNode; onClick?: () => void }> = ({
  offer = "",
  time = "00:00",
  onClick = noop,
}) => {
  const log = useAmplitude()
  const onClickFn = useCallback(() => {
    log.click({ source: "header_timer" })
    onClick()
  }, [log, onClick])

  return (
    <Box
      borderRadius="full"
      color="Other/New"
      bgColor="Other/NewBg"
      paddingX={4}
      paddingY={3}
      textStyle="Subtitle/Primary"
      textAlign="center"
      w="full"
      cursor="pointer"
      onClick={onClickFn}
      _hover={{
        transform: "scale(0.95)",
      }}
      transition="transform ease-in 0.15s"
    >
      {offer} offer reserved for {time}
    </Box>
  )
}
export const Header2: FC<{
  title: AttributedStringType | string
  offer: ReactNode
  time: ReactNode
  onClick?: () => void
}> = ({ title = "", offer = "", time = "", onClick = noop }) => (
  <>
    <TimerFloatContainer variant={2} />
    <HeaderHeadingPartWithLogo title={title} />
    <Box paddingX={6} w="full">
      <Timer time={time} offer={offer} onClick={onClick} />
    </Box>
    <Image h="278px" src={heroImage} fit="cover" align="center bottom" />
  </>
)
