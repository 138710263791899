import { FC, ReactNode } from "react"
import { Flex, Heading } from "@chakra-ui/react"
import { BlockPropertyContent } from "~/components"
import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"

import Lines2 from "./lines2.svg?react"

export const HeaderHeadingPart3: FC<{ children: ReactNode }> = ({ children }) => (
  <Flex w="full">
    <Heading size="Header/Primary" whiteSpace="pre-wrap">
      {children}
    </Heading>
    <Lines2
      style={{
        position: "relative",
        top: "-25px",
        right: "18px",
        flexGrow: 1,
        overflow: "visible",
        height: "32px",
      }}
    />
  </Flex>
)

const Header3: FC = () => (
  <HeaderHeadingPart3>
    <BlockPropertyContent propKey="PB_HEADER_3.title">
      Your AI-Skincare Plan
      <br /> Is Ready
    </BlockPropertyContent>
  </HeaderHeadingPart3>
)

export const Header3Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props} variant="transparent" paddingX={6} paddingTop={6} paddingBottom={4}>
    <Header3 />
  </BlockWrapper>
)
