import { FC } from "react"
import { Box, Heading, Text, Center, useToken } from "@chakra-ui/react"
import IconHanghole from "./assets/icon-hanghole.svg?react"
import { useTranslation } from "react-i18next"

export const Header: FC = () => {
  const [radii] = useToken("radii", ["brand24"])
  const { t } = useTranslation()
  return (
    <Box
      width="100%"
      backgroundColor="Base/neutralPrimary"
      padding={6}
      shadow="LoviShort"
      borderRadius={`0 0 ${radii} ${radii}`}
      marginBottom={8}
    >
      <Center marginTop={1} marginBottom={6}>
        <IconHanghole />
      </Center>
      <Heading whiteSpace="pre-line" marginBottom={3} textAlign="center" size="Header/Primary">
        {t("instructions.title")}
      </Heading>
      <Text whiteSpace="pre-line" textStyle="Paragraph/Primary" textAlign="center">
        {t("instructions.subtitle")}
      </Text>
    </Box>
  )
}
