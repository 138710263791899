import { RadioProps } from "@chakra-ui/radio"
import { Box, useRadio, useStyleConfig } from "@chakra-ui/react"
import { FC } from "react"

export const RadioAppButton: FC<RadioProps> = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)
  const styles = useStyleConfig("RadioAppButton", props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label" flex={1}>
      <input {...input} />
      <Box {...checkbox} __css={styles} cursor="pointer">
        {props["children"]}
      </Box>
    </Box>
  )
}
