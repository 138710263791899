//import { Link as RouterLink } from "react-router-dom"
import { Link as ChakraLink } from "@chakra-ui/react"
import { FC } from "react"
import * as React from "react"

// FIXME <ChakraLink as={RouterLink}  {...props} />
// @ts-ignore FIXME
export const Link: FC<React.ComponentProps<typeof ChakraLink>> = (props) => (
  <ChakraLink {...props} />
)
