import { FC, useMemo } from "react"
import { AspectRatio, Box, createIcon, Flex, Heading, Image } from "@chakra-ui/react"
import { VFlex } from "~/components"
import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"
import { HeaderLogo } from "./HeaderLogo"
import { useBlockProperties } from "~/store/selectors"
import { formatDate } from "../../../../../../interview-app/src/utils/formatDate"
import { getProgramEndDate } from "~/utils"
import { EnhancedPhoto3Container } from "~/pages/Main/Blocks/EnhancedPhotoBlock/EnhancedPhoto3Container"

export const Underline = createIcon({
  viewBox: "0 0 120 4",
  defaultProps: {
    height: "4px",
    width: "calc(100% + 10px)",
    preserveAspectRatio: "none",
  },
  path: (
    <>
      <path
        d="M1.15234 0.868324C34.765 0.868269 87.6975 0.636516 118.845 1.4136"
        stroke="currentColor"
        strokeWidth="1.01538"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.41797 3.16406C33.7982 3.16406 75.3692 3.16412 118.354 3.16409"
        stroke="currentColor"
        strokeWidth="1.01538"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
})

const defaultProps = {
  upTitle: "Get your",
  image: "https://storage.pora.ai/9d49248295cd57b38ad188a6f8549d31.webp",
} as const

const Header5: FC<{ upTitle?: string; resultsDate: string; image?: string }> = ({
  upTitle = defaultProps.upTitle,
  resultsDate,
  image = defaultProps.image,
}) => (
  <Flex w="full">
    <VFlex paddingBottom={6} paddingX={6} alignItems="center" w="full" gap={6}>
      <Box textAlign="center" py={4}>
        <HeaderLogo />
      </Box>
      <Box textAlign="center">
        <Box textStyle="Subtitle/Secondary" mb={2}>
          {upTitle}
        </Box>
        <Heading whiteSpace="pre-wrap" as="h1" size="Header/Primary">
          Visible results by{" "}
          <Box as="span" pos="relative">
            {resultsDate}
            <Box pos="absolute" left="0" right="6px" bottom="-13px">
              <Underline color="Base/basePrimary" />
            </Box>
          </Box>
        </Heading>
      </Box>
      <Box w="full">
        <EnhancedPhoto3Container />
      </Box>
    </VFlex>
  </Flex>
)

const PROGRAM_LENGTH_WEEKS = 5
export const Header5Container: FC = () => {
  const blockProperties = useBlockProperties()

  const resultsDate = useMemo(
    () => formatDate("MONTH_DAY")(getProgramEndDate(PROGRAM_LENGTH_WEEKS)),
    []
  )

  return (
    <Header5
      upTitle={blockProperties["PB_HEADER_5.upTitle"]}
      resultsDate={resultsDate}
      image={blockProperties["PB_HEADER_5.image"]}
    />
  )
}

export const Header5Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper
    padding={0}
    borderTopRadius={0}
    borderBottomRadius="24px"
    _first={{
      borderBottomRadius: "24px",
    }}
    _last={{
      borderBottomRadius: "24px",
    }}
    {...props}
  >
    <Header5Container />
  </BlockWrapper>
)
