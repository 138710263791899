import { Box, BoxProps, Divider, Flex, FlexProps, Heading, Image, Text } from "@chakra-ui/react"
import { FC, ReactNode } from "react"
import { Link, VFlex } from "~/components"
import { useIsLoviTheme } from "~/theme/theme-from-interview/themeProvider"
import srcStampPora from "./stamp_pora.png"
import srcStampLovi from "./stamp_lovi.png"
import srcStamp from "./stamp.webp"
import srcCards from "./cards.webp"
import { PriceContentV2_PriceItem } from "~/generated/paywall"
import { formatLengthWithUnit2, formatPrice } from "~/utils"
import { useSelectedPrice } from "~/store/selectors"
import i18n from "~/i18n"
import { Trans, useTranslation } from "react-i18next"

export const TextPart: FC<FlexProps> = (props) => {
  const isLoviTheme = useIsLoviTheme()
  return (
    <Flex
      bgColor={isLoviTheme ? "transparent" : "Base/neutralSecondary"}
      borderWidth={isLoviTheme ? "1px" : 0}
      borderColor="Base/neutralDisabled"
      borderRadius="2xl"
      padding={6}
      w="full"
      direction="column"
      {...props}
    />
  )
}
const TextPartContent: FC<{ slotHeader: ReactNode; children: ReactNode }> = ({
  slotHeader,
  children,
}) => {
  const isLoviTheme = useIsLoviTheme()

  return (
    <>
      <Heading size="Header/Tertiary" variant={isLoviTheme ? undefined : "text"} marginBlockEnd={3}>
        {slotHeader}
      </Heading>
      <Text textStyle="Paragraph/Secondary" color="Base/basePrimary">
        {children}
      </Text>
    </>
  )
}

export const TextMoneyBack = () => {
  const isLoviTheme = useIsLoviTheme()
  const { t } = useTranslation()
  return (
    <>
      <TextPartContent slotHeader={t("money_back.100_guarantee")}>
        {t("money_back.we_are_confident")}
      </TextPartContent>
      <Flex direction="row" gap={2} alignItems="center" marginTop={1.5}>
        <Text textStyle="Paragraph/Secondary" color="Base/basePrimary" flexGrow={1}>
          <Trans
            i18nKey="money_back.find_more"
            components={{
              moneyBack: <Link href="https://lovi.care/money-back/" />,
            }}
          ></Trans>
        </Text>
        <Image
          src={isLoviTheme ? srcStampLovi : srcStampPora}
          alt="Money back Guarantee 30 day"
          w="80px"
          h="80px"
        />
      </Flex>
    </>
  )
}

export const TextMoneyBack3 = () => (
  <VFlex gap={4} layerStyle="attentionFrame" padding={6}>
    <Image src={srcStamp} alt="Money back Guarantee 30 day" w="110px" h="110px" />
    <VFlex gap={3}>
      <Heading size="Header/Tertiary">100% Money-Back Guarantee</Heading>
      <Text textStyle="Paragraph/Secondary">
        We’re confident that Lóvi will help you achieve your skincare goals, so we’re offering a
        money-back guarantee. If you don’t see visible results in 4 weeks and can show you’ve
        followed our plan, we’ll refund your money.
      </Text>
    </VFlex>
    <Divider color="Base/baseTertiary" />
    <VFlex gap={3}>
      <Box textStyle="Subtitle/Secondary">Guaranteed Safe Checkout</Box>
      <Image src={srcCards} alt="" minH="22px" w="full" />
    </VFlex>
  </VFlex>
)

export const TextYouInformationIsSafe = () => {
  const { t } = useTranslation()
  return (
    <TextPartContent slotHeader={t("safe_information.title")}>
      {t("safe_information.description")}
    </TextPartContent>
  )
}

export const TextSecureCheckout = () => (
  <TextPartContent slotHeader="Secure checkout">
    All information is encrypted and transmitted using Secure Sockets Layer protocol
  </TextPartContent>
)

export const TextNeedHelp = () => {
  const { t } = useTranslation()
  return (
    <TextPartContent slotHeader={t("need_help.title")}>
      <Trans
        i18nKey="need_help.description"
        components={{
          supportLink: <Link href="mailto:care@pora.ai" />,
        }}
      />
    </TextPartContent>
  )
}

export const TextCopyright = () => (
  <>
    2024 © Digital Skincare
    <br />
    <br />
    1209 Orange Street, Wilmington,
    <br />
    County of New Castle, Wilmington, Delaware 19801
  </>
)

const text3 = ({
  currency_code,
  trial_price_cents,
  subscription_price_cents,
  subscription_period_unit,
  subscription_period_length,
}: PriceContentV2_PriceItem) => {
  const t = i18n.t
  return t("prices.payment_message", {
    introductoryPrice: formatPrice(currency_code, trial_price_cents / 100),
    fullPrice: formatPrice(currency_code, subscription_price_cents / 100),
    billingPeriod: formatLengthWithUnit2(subscription_period_unit, subscription_period_length),
  })
}

const SubscriptionText: FC<{ price: PriceContentV2_PriceItem }> = ({ price }) => {
  const { t } = useTranslation()
  return (
    <>
      {t("prices.autorenewing")}
      <br />
      <br />
      {text3(price)}
      <br />
      <br />
      <Trans
        i18nKey="prices.you_can_cancel"
        components={{ support: <a href="mailto:care@lovi.care">care@lovi.care</a> }}
      ></Trans>
      <br />
      <br />
      <Trans
        i18nKey="prices.subscription_note"
        components={{
          privacyPolicy: (
            <Link href="https://lovi.care/privacy-policy/" target="_blank">
              Privacy Policy
            </Link>
          ),
          termsOfUse: (
            <Link href="https://lovi.care/terms/" target="_blank">
              Terms of Use
            </Link>
          ),
          refundPolicy: (
            <Link href="https://lovi.care/money-back/" target="_blank">
              Refund Policy
            </Link>
          ),
        }}
      />
    </>
  )
}

export const SubscriptionTextContainer: FC = () => {
  const selectedPrice = useSelectedPrice()
  return selectedPrice ? <SubscriptionText price={selectedPrice} /> : null
}

export const TextBox1: FC<BoxProps> = (props) => (
  <Box
    padding={4}
    borderRadius="brand16"
    borderWidth={1}
    borderColor="Base/neutralSecondary"
    textStyle="Paragraph/Tertiary"
    bgColor="Base/neutralPrimary"
    color="Base/baseDisabled"
    sx={{
      a: { color: "Base/basePrimary" },
      "a:hover": { color: "Base/accentPrimary" },
    }}
    {...props}
  />
)

export const TextBox2: FC<BoxProps> = (props) => (
  <Box
    padding={4}
    borderRadius="brand20"
    textStyle="Paragraph/Tertiary"
    bgColor="Base/neutralSecondary"
    color="Base/baseSecondary"
    sx={{
      a: { color: "Base/basePrimary" },
      "a:hover": { color: "Base/accentPrimary" },
    }}
    {...props}
  />
)

export const TextBox3: FC<BoxProps> = (props) => (
  <Box
    padding={4}
    borderRadius="brand20"
    textStyle="Paragraph/Tertiary"
    bgColor="Base/neutralSecondary"
    color="Base/basePrimary"
    sx={{
      a: { color: "Base/accentPrimary" },
      "a:hover": { color: "Base/accentPrimary" },
    }}
    {...props}
  />
)
