import { useMemo } from "react"
import { getUserIdFromUrl } from "@poraai/shared/url"

type UserInfo = {
  userId: UserId
}

type AuthInfo = {
  isAuthenticated: boolean
  isLoading: boolean
  loginWithRedirect: () => void
  user: UserInfo | undefined
}
const loginWithRedirect = () => {
  /* TODO move redirect to separate function, same as in src/store/actions.ts */
  window.location.replace("https://interview.lovi.care/")
}
export const useAuthInfo = (): AuthInfo => {
  const userId = getUserIdFromUrl()
  const isLoading = false
  let isAuthenticated = false
  let user: UserInfo | undefined = undefined

  if (userId) {
    isAuthenticated = true
    user = { userId }
  }

  return useMemo(
    () => ({
      isAuthenticated,
      isLoading,
      loginWithRedirect,
      user,
    }),
    [user?.userId, isAuthenticated, isLoading]
  )
}
