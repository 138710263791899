import { useCallback } from "react"
import { useNavigateWithSearchParams } from "@poraai/shared/useNavigateWithSearchParams"
import { savePaymentData } from "~/api/api"
import { PAYMENT_PROVIDER, PAYMENT_STATUSES } from "~/store"
import { updatePaymentStatus } from "~/store/actions"
import { useUserEmail, useUserId } from "~/store/selectors"
import { useDispatch } from "~/utils/redux"

export type PaymentStatus = "PENDING" | "FAIL" | "COMPLETE" | "ERROR" | "CANCEL" | "UNKNOWN"

const logPaymentSuccess = ({
  provider,
  email,
  userId,
  paymentId,
}: {
  provider: PAYMENT_PROVIDER
  email: string
  userId: string
  paymentId: string | undefined
}) => {
  savePaymentData({ provider, email, userId, paymentId })
}

export const useOnPaymentStatusChange = () => {
  const dispatch = useDispatch()
  const userId = useUserId()
  const email = useUserEmail()
  const navigate = useNavigateWithSearchParams()
  const onStatusChange = (provider: PAYMENT_PROVIDER, status: PaymentStatus, data?: unknown) => {
    window.posthog?.identify(userId as UserId)
    if (status === "PENDING") {
      // Waiting for status change, and after navigate to /processing/
      dispatch(updatePaymentStatus({ status: PAYMENT_STATUSES.PENDING, provider }))
      return undefined
    }

    if (status === "FAIL") {
      dispatch(updatePaymentStatus({ status: PAYMENT_STATUSES.FAIL, provider }))
    } else if (status === "COMPLETE") {
      const _data = Array.isArray(data) ? data[0] : data
      const paymentId = _data?.paymentId ?? undefined
      logPaymentSuccess({ provider, userId, email, paymentId })
      dispatch(updatePaymentStatus({ status: PAYMENT_STATUSES.SUCCESS, provider }))
    } else {
      dispatch(updatePaymentStatus({ status: PAYMENT_STATUSES.ERROR, provider }))
    }

    navigate("/processing/")
    return undefined
  }

  return useCallback(onStatusChange, [dispatch, navigate, userId, email])
}
