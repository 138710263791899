import { createIcon } from "@chakra-ui/react"

export const MarkIcon = createIcon({
  displayName: "MarkIcon",
  viewBox: "0 0 20 20",

  path: (
    <path
      fill="none"
      d="M6 9.75C8 13.15 9.16667 14 9.5 14L13.75 7"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  ),
})
