import antiAgingImgSrc from "./images/anti-aging.webp?url"
import dermatologistImgSrc from "./images/dermatologist.webp?url"
import productsImgSrc from "./images/products.webp?url"
import { ProgramBenefitsBetterMe } from "./ProgramBenefitsBetterMe"

const header = "What you get"
const benefits = [
  {
    title: "Personalized list of skincare products",
    description:
      "Custom recommendations, 0.01% of 100000+ products chosen to help you reach your skin goals, on any budget, free from brand bias",
    img: productsImgSrc,
  },
  {
    title: "Personal anti-aging plan",
    description:
      "Easy-to-follow exercises that are tailored to your individual goals and backed by proven results",
    img: antiAgingImgSrc,
  },
  {
    title: "Dermatologist support",
    description:
      "Connect with personal dermatologists who help with plan adjustments and answer all your questions for fastest and more effective results",
    img: dermatologistImgSrc,
  },
]

export const ProgramBenefitsBetterMeContainer = () => {
  return <ProgramBenefitsBetterMe header={header} benefits={benefits} />
}
