import { useMemo } from "react"
import { formatDate, getProgramEndDate } from "~/utils"
import { AttributedStringType, Timer, TimerFloatContainer } from "~/components"
import { useTimerData, useHeaderPrePaywallData } from "~/store/selectors"
import { useScrollToOffer } from "../scrollToOffer"
import { HeaderPrePaywall } from "./HeaderPrePaywall"

const PROGRAM_LENGTH_WEEKS = 4
const title = {
  parts: [
    {
      content: "Your ",
      format: [],
    },
    {
      content: "personalized\n",
      format: [
        {
          format: {
            emphasis: true,
            $case: "emphasis",
          },
        },
      ],
    },
    {
      content: `${PROGRAM_LENGTH_WEEKS}-week program`,
      format: [],
    },
  ],
} as AttributedStringType

export const HeaderPrePaywallContainer = () => {
  const { main_goal_text: resultsText, main_goal_img: graph } = useHeaderPrePaywallData()
  const { minutes } = useTimerData()
  const handleTimerBlockClick = useScrollToOffer("header_timer")
  const resultsDate = useMemo(
    () => formatDate("FULL_LONG")(getProgramEndDate(PROGRAM_LENGTH_WEEKS)),
    []
  )
  const timerDescription = `Your personalized skin care program has been reserved for the next ${minutes} minutes`

  return (
    <>
      <TimerFloatContainer />
      <HeaderPrePaywall
        onTimerBlockClick={handleTimerBlockClick}
        title={title}
        timerDescription={timerDescription}
        timeBlock={<Timer />}
        graphImg={graph}
        resultsDate={resultsDate}
        resultsText={resultsText}
        programLengthWeeks={PROGRAM_LENGTH_WEEKS}
      />
    </>
  )
}
