import { FC, ReactNode } from "react"
import { Flex, FlexProps, Heading, HeadingProps } from "@chakra-ui/react"

export const BlockHeading: FC<HeadingProps> = (props: HeadingProps) => (
  <Heading
    w="full"
    size="Header/Secondary"
    textAlign="center"
    marginBottom={4}
    whiteSpace="pre-line"
    sx={{
      textWrap: "balance",
      "& em": { color: "Base/accentPrimary", fontStyle: "unset" },
    }}
    {...props}
  />
)

type BlockWrapperVariant = "default" | "transparent" | "invisible"
const BlockWrapperVariantStyles: Record<BlockWrapperVariant, FlexProps> = {
  default: {
    paddingX: 6,
    paddingY: 8,
    bgColor: "white",
    borderRadius: "3xl",
    _first: {
      borderTopStartRadius: 0,
      borderTopEndRadius: 0,
    },
    _last: {
      borderBottomStartRadius: 0,
      borderBottomEndRadius: 0,
    },
    shadow: "LoviShort",
  },
  transparent: {},
  invisible: {},
}

export type BlockWithScrollAnalytics = { analyticsId: string }
export type BlockWrapperProps = FlexProps &
  BlockWithScrollAnalytics & { header?: ReactNode; variant?: BlockWrapperVariant }
export const BlockWrapper: FC<BlockWrapperProps> = ({
  analyticsId,
  header,
  variant = "default",
  children,
  ...props
}) => {
  return (
    <Flex
      className={`BlockWrapper BlockWrapper__variant-${variant}`}
      data-analytics-id={analyticsId}
      w="full"
      alignItems="center"
      direction="column"
      {...BlockWrapperVariantStyles[variant]}
      {...props}
    >
      {header && <BlockHeading>{header}</BlockHeading>}

      {children}
    </Flex>
  )
}
