import { FC } from "react"
import { Box, Heading } from "@chakra-ui/react"

import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"
import { ActionButton } from "~/pages/Main/ActionButton"
import { BlockPropertyContent, VFlex } from "~/components"

import { useTranslation } from "react-i18next"

export const ProductsImages1Block: FC<BlockWrapperProps> = (props) => {
  const { t } = useTranslation()
  return (
    <BlockWrapper maxW="450px" variant="transparent" paddingY={6} {...props}>
      <VFlex gap={4} w="full" alignItems="center">
        <Heading paddingX={6} whiteSpace="pre-line" size="Header/Secondary" textAlign="center">
          {t("products_images_1.title")}
        </Heading>

        <Box
          h="250px"
          w="full"
          bgSize="360px"
          bgPosition="center"
          bgRepeat="no-repeat"
          bgImage={t("products_images_1.image")}
        />
      </VFlex>
    </BlockWrapper>
  )
}

export const ProductsImages2Block: FC<BlockWrapperProps> = (props) => {
  const { t } = useTranslation()
  return (
    <BlockWrapper maxW="450px" variant="transparent" paddingY={4} {...props}>
      <VFlex gap={2} w="full">
        <VFlex gap={4} w="full">
          <VFlex gap={1} w="full" alignItems="center">
            <Heading paddingX={6} size="Header/Secondary" textAlign="center">
              <BlockPropertyContent propKey="PB_PRODUCTS_IMAGES_2.title">
                Other products fit
              </BlockPropertyContent>
            </Heading>
            <Box paddingX={6} textAlign={"center"}>
              <BlockPropertyContent propKey="PB_PRODUCTS_IMAGES_2.description">
                Check out products that fit your skin best
              </BlockPropertyContent>
            </Box>
          </VFlex>
          <Box
            w="full"
            h="167px"
            bgSize="auto 167px"
            bgPosition="center"
            bgRepeat="no-repeat"
            bgImage={t("products_images.jars_image")}
          />
        </VFlex>
        <Box mx={6}>
          <ActionButton actionSource="PB_PRODUCTS_IMAGES_2">
            <BlockPropertyContent propKey="PB_PRODUCTS_IMAGES_2.button_text">
              Get Full Product List
            </BlockPropertyContent>
          </ActionButton>
        </Box>
      </VFlex>
    </BlockWrapper>
  )
}
