import {
  FC,
  FormEvent,
  forwardRef,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

import {
  Box,
  Button,
  Flex,
  FlexProps,
  FormControl,
  FormLabel,
  Input,
  InputProps,
  Text,
} from "@chakra-ui/react"
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import {
  loadStripe,
  Stripe,
  type PaymentRequest,
  type StripeElementChangeEvent,
  type StripeElements,
  type PaymentIntent,
  type PaymentRequestWallet,
  type PaymentRequestPaymentMethodEvent,
} from "@stripe/stripe-js"
export type { PaymentIntent } from "@stripe/stripe-js"
import { stripeCreateIntent } from "~/api/api"
import { CONFIG } from "~/config"
import { useSelectedPrice, useUserEmail, useUserId } from "~/store/selectors"
import { noop } from "~/utils"
import { useAmplitude } from "~/utils/analytics/useAmplitude"

import { getPaymentMethodWithCard } from "./create-payment-method"
import LockIcon from "./lock-icon.svg?react"
import StripeLogo from "./stripe-logo.svg?react"
import { ApplePayButton } from "./ApplePayButton"
import { usePaymentFormContext } from "../PaymentFormContext"
import { PaymentApiError } from "~/errors"

type StripeFormType = "CARD" | "APPLE_PAY"

const stripePromise = loadStripe(CONFIG.stripe.key)
const COUNTRY = "US"
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#0C2330",
      fontFamily: "sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "24px",
      "::placeholder": {
        color: "#ABBBCC",
      },
    },
    invalid: {
      color: "#F84018",
      iconColor: "#F84018",
    },
  },
}

const getStripePaymentRequestOptions = (price: number) => ({
  country: COUNTRY,
  currency: "usd", // FIXME
  requestPayerName: false,
  requestPayerEmail: false,
  requestShipping: false,
  disableWallets: ["browserCard", "googlePay", "link"] as PaymentRequestWallet[],
  ...getStripePaymentRequestOptionsPrice(price),
})

const getStripePaymentRequestOptionsPrice = (price: number) => ({
  total: {
    label: "Lovi.care subscription",
    amount: price,
  },
})

const INITIAL_PRICE = 100 // 1 USD
class StripePaymentError extends Error {}

enum FormState {
  UNKNOWN = -1,
  LOADING,
  READY,
  SENDING,
  SUCCESS,
  ERROR,
  FAIL,
}

const formSubmitFactory =
  ({
    stripe,
    elements,
    priceId,
    email,
    userId,
    initialPriceCents,
  }: {
    stripe: Stripe
    elements: StripeElements
    priceId: PriceId
    email: string
    userId: string
    initialPriceCents: number
  }) =>
  async (addressCountry: string, addressZip: string) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      throw new StripePaymentError("Stripe is not loaded")
    }

    const cardData = "CardNumberElement"
    const elementType = "cardNumber"

    const paymentMethodId = await getPaymentMethodWithCard(stripe, elements, {
      cardData,
      addressCountry,
      addressZip,
    })
    const clientSecret = await stripeCreateIntent({
      priceId,
      paymentMethodId,
      email,
      userId,
      initialPriceCents,
    })
    const cardElement = elements.getElement(elementType)
    if (cardElement) {
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: { card: cardElement },
      })

      if (result.error) {
        throw result.error
      }

      if (result.paymentIntent) {
        return result.paymentIntent
      }
      throw new StripePaymentError("Unknown response structure")
    } else {
      throw new StripePaymentError("Can't find CardElement")
    }
  }

const processingRequest =
  ({
    stripe,
    priceId,
    email,
    userId,
    initialPriceCents,
  }: {
    stripe: Stripe
    priceId: PriceId
    email: string
    userId: string
    initialPriceCents: number
  }) =>
  async (ev: PaymentRequestPaymentMethodEvent) => {
    if (!stripe) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      throw new StripePaymentError("Stripe is not loaded")
    }

    const clientSecret = await stripeCreateIntent({
      priceId,
      paymentMethodId: ev.paymentMethod.id as PaymentMethodId,
      email,
      userId,
      initialPriceCents,
    })
    const result = await stripe.confirmCardPayment(
      clientSecret,
      { payment_method: ev.paymentMethod.id },
      { handleActions: false }
    )

    if (result.error) {
      ev.complete("fail")
      throw result.error
    }

    if (result.paymentIntent) {
      ev.complete("success")
      if (result.paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        const { error } = await stripe.confirmCardPayment(clientSecret)
        if (error) {
          // The payment failed -- ask your customer for a new payment method.
          throw new StripePaymentError("Payment failed")
        } else {
          // The payment has succeeded.
        }
      } else {
        // The payment has succeeded.
      }
      return result.paymentIntent
    }
    throw new StripePaymentError("Unknown response structure")
  }

// todo fix
// eslint-disable-next-line react/display-name
const ZipInput = forwardRef((props: InputProps) => (
  <Input
    placeholder="ZIP"
    maxLength={8}
    inputMode="numeric"
    pattern="\d*"
    type="text"
    border={0}
    focusBorderColor="transparent"
    _placeholder={CARD_ELEMENT_OPTIONS.style.base["::placeholder"]}
    fontSize={CARD_ELEMENT_OPTIONS.style.base.fontSize}
    fontWeight={CARD_ELEMENT_OPTIONS.style.base.fontWeight}
    fontStyle={CARD_ELEMENT_OPTIONS.style.base.fontStyle}
    fontFamily={CARD_ELEMENT_OPTIONS.style.base.fontFamily}
    color={CARD_ELEMENT_OPTIONS.style.base.color}
    {...props}
  />
))

const StripeFormElements: FC<{
  isDisabled: boolean
  addressZipRef: RefObject<HTMLInputElement>
  stripeElementProps: Record<string, unknown>
  zipElementProps: Record<string, unknown>
}> = ({ stripeElementProps, addressZipRef, zipElementProps, isDisabled }) => (
  <Flex direction="column" gap={4} w="full">
    <FormControl>
      <FormLabel color="Base/baseDisabled" textStyle="MediumSubtitleSecondary">
        Card
      </FormLabel>
      <Flex
        w="full"
        h="52px"
        bgColor="Base/neutralDisabled"
        borderRadius="2xl"
        padding={4}
        alignItems="center"
      >
        <Box w="full">
          <CardNumberElement
            {...stripeElementProps}
            options={{ ...CARD_ELEMENT_OPTIONS, disabled: isDisabled }}
          />
        </Box>
      </Flex>
    </FormControl>
    <FormControl>
      <FormLabel color="Base/baseDisabled" textStyle="MediumSubtitleSecondary">
        Expires on
      </FormLabel>
      <Flex
        w="full"
        direction="row"
        h="52px"
        bgColor="Base/neutralDisabled"
        borderRadius="2xl"
        padding={4}
        alignItems="center"
      >
        <Box w="30%">
          <CardExpiryElement
            {...stripeElementProps}
            options={{ ...CARD_ELEMENT_OPTIONS, disabled: isDisabled }}
          />
        </Box>
        <Box w="30%">
          <CardCvcElement
            {...stripeElementProps}
            options={{ ...CARD_ELEMENT_OPTIONS, disabled: isDisabled }}
          />
        </Box>
        <ZipInput
          w="40%"
          ref={addressZipRef}
          isDisabled={isDisabled}
          {...zipElementProps}
          bgColor="transparent"
        />
      </Flex>
    </FormControl>
  </Flex>
)

const PaymentInfoTag: FC<FlexProps> = (props) => (
  <Flex
    paddingX="10px"
    h="30px"
    borderRadius="lg"
    direction="row"
    justify="flex-start"
    align="center"
    gap={1}
    borderColor="Base/neutralDisabled"
    borderWidth="1px"
    color="Base/baseSecondary"
    textStyle="MediumHintPrimary"
    justifyContent="center"
    grow={1}
    {...props}
  />
)

const PaymentInfoTags: FC<FlexProps> = (props) => (
  <Flex direction="row" justify="flex-start" align="center" gap={1.5} {...props}>
    <PaymentInfoTag>
      <Text mr={1}>Powered by</Text>
      <StripeLogo />
    </PaymentInfoTag>
    <PaymentInfoTag>
      <LockIcon />
      <Text ml={0.5}>Secure Server</Text>
    </PaymentInfoTag>
  </Flex>
)

const CheckoutFormMultiline: FC<{
  priceId: string
  email: string
  userId: string
  initialPriceCents: number
  onPaymentComplete?: (intent: PaymentIntent) => void
  onError?: (error: unknown) => void
  type?: StripeFormType
}> = ({
  priceId,
  email,
  userId,
  initialPriceCents,
  onPaymentComplete = noop,
  onError = noop,
  type = "CARD",
}) => {
  const stripe = useStripe()
  const stripeElements = useElements()
  const addressZipRef = useRef<HTMLInputElement>(null)
  const isDisabled = false
  const [formState, setFormState] = useState<FormState>(FormState.UNKNOWN)
  const [fieldsErrorText, setFieldsErrorText] = useState<undefined | string>()
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | undefined>()
  //const [isApplePayAvailable, setApplePayAvailable] = useState(false)
  const {
    set: setApplePayAvailable,
    value: { isApplePayAvailable },
  } = usePaymentFormContext()

  const log = useAmplitude()
  const formSubmit = useMemo(
    () =>
      formSubmitFactory({
        stripe: stripe!,
        elements: stripeElements!,
        priceId: priceId as PriceId,
        email,
        userId,
        initialPriceCents,
      }),
    [userId, stripe, stripeElements, priceId, email, initialPriceCents]
  )
  const onSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      log.paymentFormButtonClick()
      setFormState(FormState.SENDING)
      event.preventDefault()
      try {
        const paymentIntent = await formSubmit(COUNTRY, addressZipRef.current?.value ?? "")
        onPaymentComplete(paymentIntent)
        log.paymentFormShowSuccess()
        setFormState(FormState.SUCCESS)
      } catch (error) {
        log.paymentError({ error: String(error) })
        setFormState(FormState.ERROR)
        if (error instanceof PaymentApiError) {
          setFieldsErrorText(error.message)
        } else {
          onError(error)
        }
      }
    },
    [formSubmit, setFormState]
  )

  useEffect(() => {
    if (stripe == null) {
      setFormState(FormState.LOADING)
    } else if (stripe) {
      setFormState(FormState.READY)
      const pr = stripe.paymentRequest(getStripePaymentRequestOptions(INITIAL_PRICE))
      setPaymentRequest(pr)
    }
  }, [stripe])

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest
        .canMakePayment()
        .then((result: { applePay?: boolean } | null) => {
          if (result && result.applePay) {
            setApplePayAvailable(true)
            log.paymentApplePayStatusChange({ status: "available" })
          } else {
            setApplePayAvailable(false)
            log.paymentApplePayStatusChange({ status: "unavailable" })
          }
        })
        .catch((err) => {
          console.error(err)
          log.paymentApplePayStatusChange({ status: "error" })
          setApplePayAvailable(false)
        })
    }
  }, [stripe, paymentRequest])

  const onApplePayClick = useCallback(() => {
    log.paymentApplePayClick()

    if (paymentRequest) {
      paymentRequest.update(getStripePaymentRequestOptionsPrice(initialPriceCents)) // set price

      // update success handler
      paymentRequest.once("paymentmethod", async (ev) => {
        setFormState(FormState.SENDING)
        try {
          const onProcessingRequest = processingRequest({
            stripe: stripe!,
            priceId: priceId as PriceId,
            email,
            userId,
            initialPriceCents,
          })

          const paymentIntent = await onProcessingRequest(ev)
          onPaymentComplete(paymentIntent)

          log.paymentFormShowSuccess()
          setFormState(FormState.SUCCESS)
        } catch (error) {
          log.paymentError({ error: String(error) })
          setFormState(FormState.ERROR)
          if (error instanceof PaymentApiError) {
            setFieldsErrorText(error.message)
          } else {
            onError(error)
          }
        }
      })
      paymentRequest.show()
    }
  }, [stripe, paymentRequest, initialPriceCents, priceId, email, log, onPaymentComplete, onError])

  const zipElementProps = useMemo(() => {
    return {
      onFocus() {
        log.paymentFormInputFocusIn({ element: "zip" })
      },
      onBlur() {
        log.paymentFormInputFocusOut({ element: "zip" })
      },
    }
  }, [])
  const stripeElementProps = useMemo(() => {
    return {
      onFocus(event: StripeElementChangeEvent) {
        log.paymentFormInputFocusIn({ element: event.elementType })
      },
      onBlur(event: StripeElementChangeEvent) {
        log.paymentFormInputFocusOut({ element: event.elementType })
      },
      onChange(event: StripeElementChangeEvent) {
        const type = event.elementType

        if (event.complete && stripeElements) {
          if (type === "cardNumber") {
            stripeElements.getElement("cardExpiry")?.focus()
          } else if (type === "cardExpiry") {
            stripeElements.getElement("cardCvc")?.focus()
          } else if (type === "cardCvc") {
            addressZipRef.current?.focus()
          }
        }

        setFieldsErrorText(event.error ? event.error.message : "")
      },
    }
  }, [addressZipRef, stripeElements, setFieldsErrorText])

  return (
    <form id="payment-form" onSubmit={onSubmit}>
      <Box hidden={!(isApplePayAvailable && type === "APPLE_PAY")}>
        <ApplePayButton w="full" onClick={onApplePayClick} />
      </Box>

      <Box hidden={type !== "CARD"}>
        <InputsWrapper>
          <StripeFormElements
            stripeElementProps={stripeElementProps}
            zipElementProps={zipElementProps}
            addressZipRef={addressZipRef}
            isDisabled={isDisabled}
          />
          <Box mt={1} textStyle="MediumSubtitleTertiary" color="Other/Error">
            {fieldsErrorText}
          </Box>
        </InputsWrapper>
        <Button
          variant="action"
          mt={6}
          w="full"
          isDisabled={!stripe || Boolean(fieldsErrorText)}
          isLoading={[FormState.SENDING, FormState.LOADING].includes(formState)}
          type="submit"
        >
          Continue
        </Button>
        {/* <PaymentInfoTags w="full" mt={6} /> */}
      </Box>
    </form>
  )
}

const InputsWrapper: FC<{ children: ReactNode }> = ({ children }) => (
  <Flex
    direction="column"
    padding={4}
    borderRadius="xl"
    borderColor="Base/neutralDisabled"
    borderWidth="1px"
    boxShadow="smallOne"
  >
    <Flex direction="column" justify="flex-start" align="flex-start" gap={1.5} alignSelf="stretch">
      <Text as="h5" textStyle="MediumSubtitlePrimary">
        Payment Info
      </Text>
      <PaymentInfoTags />
      {children}
    </Flex>
  </Flex>
)

export const PaymentFormStripeMultiline: FC<{
  priceId: string
  email: string
  userId: string
  initialPrice: number
  onPaymentComplete?: (intent: PaymentIntent) => void
  onError?: (error: unknown) => void
  type?: StripeFormType
}> = ({ userId, priceId, email, initialPrice, onPaymentComplete, onError, type = "CARD" }) => (
  <Elements stripe={stripePromise}>
    <CheckoutFormMultiline
      priceId={priceId}
      email={email}
      userId={userId}
      initialPriceCents={initialPrice}
      onError={onError}
      onPaymentComplete={onPaymentComplete}
      type={type}
    />
  </Elements>
)

export const PaymentFormStripeMultilineContainer: FC<{
  onPaymentComplete?: (intent: PaymentIntent) => void
  onError?: (error: unknown) => void
  type?: StripeFormType
}> = ({ onPaymentComplete, onError, type = "CARD" }) => {
  const selectedPrice = useSelectedPrice()
  const email = useUserEmail()
  const userId = useUserId()
  const stripePriceId = selectedPrice?.payment_providers?.stripe?.price_id
  const initialPrice = selectedPrice?.trial_price_cents
  if (stripePriceId && initialPrice) {
    return (
      <PaymentFormStripeMultiline
        priceId={stripePriceId}
        initialPrice={initialPrice}
        email={email}
        userId={userId}
        onPaymentComplete={onPaymentComplete}
        onError={onError}
        type={type}
      />
    )
  }

  return null
}
