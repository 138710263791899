export const DATA = [
  {
    image: "https://storage.pora.ai/0b2cf445c4044328644f46c6da47dc91.webp",
    name: "Joanna",
    issue: "No swellness",
    text: "I used to feel so insecure about my puffy face, but now, I can honestly say I'm seeing a huge difference. I'm feeling more confident. 🖤",
  },
  {
    image: "https://storage.pora.ai/56610ddb1d0c0da3d8d3385375708262.webp",
    name: "Melissa",
    issue: "Acne-free skin",
    text: "I've been struggling with acne since I was 12. After two weeks with Lóvi, I'm happily finding products that work.",
  },
  {
    image: "https://storage.pora.ai/6a049460d36cc1cfd2448279ae8dd328.webp",
    name: "Melissa",
    issue: "Wrinkle-free skin",
    text: (
      <>
        I was unsure about the app due to my wrinkles and cream irritation. After using their
        recommendations, <br />I saw a huge improvement.
      </>
    ),
  },
  {
    image: "https://storage.pora.ai/2404de7d76f8cce3d09e59fef1437766.webp",
    name: "Katrin",
    issue: "Got rid of double chin",
    text: "I was insecure about my double chin, despite being lean. However, Lóvi's program has significantly improved my confidence!",
  },
] as const
