import { FC } from "react"
import { BeforeAfterReviewsCarousel } from "./BeforeAfterReviewsCarousel"
import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"

const Header = () => (
  <>
    People just like you achieved
    <br /> great results using Lóvi
  </>
)
export const BeforeAfterReviewsCarouselBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper header={<Header />} paddingX={0} {...props}>
    <BeforeAfterReviewsCarousel />
  </BlockWrapper>
)
