import { FC } from "react"
import { Box, Image } from "@chakra-ui/react"

import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"
import { VFlex } from "~/components"
import { useTranslation } from "react-i18next"

const StaticExperts: FC = () => {
  const { t } = useTranslation()
  return (
    <VFlex gap={3} padding={6} layerStyle="internalFrame">
      <Image
        objectFit="contain"
        alignSelf="flex-start"
        src="https://storage.pora.ai/7171c70d8f48367ec13a0b99f6fab6f8.webp"
        h="64px"
        alt=""
      />
      <Box textStyle="Paragraph/Secondary">{t("experts_block.description")}</Box>
    </VFlex>
  )
}

export const StaticExpertsBlock: FC<BlockWrapperProps> = (props) => {
  const { t } = useTranslation()
  return (
    <BlockWrapper paddingY={6} header={t("experts_block.title")} whiteSpace="pre-line" {...props}>
      <StaticExperts />
    </BlockWrapper>
  )
}
