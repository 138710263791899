import { Box, Flex, Image, Text } from "@chakra-ui/react"
import { FC } from "react"
import compare1 from "./compare1.webp"
import compare2 from "./compare2.webp"
import compare3 from "./compare3.webp"
import likes from "./likes.png"

const ReviewItem: FC<{ image: string; author: string; review: string; likes: number }> = ({
  image,
  author,
  review,
  likes: likesCount,
}) => (
  <Flex
    direction="column"
    borderWidth={1}
    borderColor="Base/neutralSecondary"
    borderRadius="3xl"
    minW="263px"
    padding={2}
    paddingBottom={4}
  >
    <Image src={image} alt="before / after" w={247} h={155} marginBottom={3} />
    <Flex direction="column" marginX={2}>
      <Box paddingY={2} paddingX={3} bgColor="custom.reviewFb" borderRadius="xl" textStyle="small">
        <Box marginBottom={1} fontWeight="500">
          {author}
        </Box>
        <Text>{review}</Text>
      </Box>
      <Flex direction="row" justifyContent="flex-end" alignItems="center" marginTop={1}>
        <Box textStyle="small" color="Base/baseSecondary" marginX={1}>
          {likesCount}
        </Box>
        <Image src={likes} alt="" w="34px" h="20px" />
      </Flex>
    </Flex>
  </Flex>
)

export const ReviewsList: FC = () => (
  <Flex direction="row" gap={2} w="100%" overflowX="auto" paddingX={6}>
    <ReviewItem
      {...{
        image: compare1,
        author: "Melissa Joykong",
        review:
          "Wish I had found this app ages ago. I didn’t expect to see such cool results after 2 weeks of skin care routine",
        likes: 83,
      }}
    />
    <ReviewItem
      {...{
        image: compare2,
        author: "Merilyn Dean-Fox",
        review: "Love it! Great way to make time for yourself daily",
        likes: 16,
      }}
    />
    <ReviewItem
      {...{
        image: compare3,
        author: "Chelsey Johnson",
        review:
          "Practicing self-love is not easy, but once you begin this journey, it will be the best part of your life",
        likes: 28,
      }}
    />
  </Flex>
)
