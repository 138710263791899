import { FC } from "react"
import { Flex, Box, FlexProps } from "@chakra-ui/react"

import bg from "./bgLines.svg"
import LogoCircle from "./logoCircle.svg?react"

export const AffiliatedNotice: FC<FlexProps> = (props) => (
  <Flex
    direction="row"
    gap={3}
    padding={5}
    justify="center"
    align="center"
    backgroundImage={bg}
    backgroundPosition="center"
    backgroundRepeat="no-repeat"
    w="full"
    {...props}
  >
    <LogoCircle />
    <Box textStyle="MediumSubtitleTertiary">
      We are not affiliated with
      <br />
      any skincare brand
    </Box>
  </Flex>
)
