import { FC, useCallback, useMemo } from "react"
import { setPrice } from "~/store/actions"
import { usePrices, usePriceSelectData, useSelectedPrice } from "~/store/selectors"
import { formatPrice } from "~/utils"
import { useAmplitude } from "~/utils/analytics/useAmplitude"
import { useDispatch } from "~/utils/redux"
import { PriceSelectPopup } from "."

const PriceSelectPopupContainerImpl: FC<{ fullscreen?: boolean }> = ({ fullscreen }) => {
  const { priceSelectPopupShow, priceSelectPopupConfirm, priceSelectPopupClickPrice } =
    useAmplitude()
  const { pre_selector_texts, button_text } = usePriceSelectData()
  const dispatch = useDispatch()
  const prices = usePrices()
  const defaultPrice = (useSelectedPrice() ?? {}).id
  const handleConfirm = useCallback(() => {
    priceSelectPopupConfirm({ price: defaultPrice })
  }, [defaultPrice, priceSelectPopupConfirm])

  const handleChange = useCallback(
    (selectedPrice: string) => {
      priceSelectPopupClickPrice({ price: selectedPrice })
      dispatch(setPrice(selectedPrice))
    },
    [dispatch, priceSelectPopupClickPrice]
  )

  const pricesOptions = useMemo((): Array<{ value: string; title: string }> => {
    return prices.map(({ currency_code, trial_price_cents, id }) => ({
      value: id,
      title: formatPrice(currency_code, trial_price_cents / 100),
    }))
  }, [prices])

  const handleShow = useCallback(() => {
    priceSelectPopupShow({
      prices: pricesOptions.map(({ value }) => value),
      prices_titles: pricesOptions.map(({ title }) => title),
    })
  }, [priceSelectPopupShow, pricesOptions])

  if (!defaultPrice) {
    return null
  }

  return (
    <PriceSelectPopup
      onShow={handleShow}
      onChange={handleChange}
      onConfirm={handleConfirm}
      preSelectorTexts={pre_selector_texts}
      pricesOptions={pricesOptions}
      buttonText={button_text}
      defaultValue={defaultPrice}
      fullscreen={fullscreen}
    />
  )
}

export const PriceSelectFullscreenPopupContainer: FC = () => (
  <PriceSelectPopupContainerImpl fullscreen={true} />
)

export const PriceSelectPopupContainer: FC = () => (
  <PriceSelectPopupContainerImpl fullscreen={false} />
)
