import { ProgramBenefitsList } from "./ProgramBenefitsList"

const benefits = [
  { text: "Quick and visible results with Skincare360 personalized program" },
  { text: "Product picks and recommendations that perfectly fit your skin" },
  { text: "Unbiased scoring for all your skincare products" },
  { text: "Personalized insights to stay on track" },
  { text: "3D face scanner powered by AI to easily monitor your progress" },
  { text: "Face massage exercises tailored to your goals" },
  { text: "On-demand support from our certified dermatologists" },
]

export const ProgramBenefitsList2Container = () => <ProgramBenefitsList benefits={benefits} />
