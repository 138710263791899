import { FC } from "react"
import { Box, Flex, Image } from "@chakra-ui/react"
import { PriceContentV2_PriceItem } from "~/generated/paywall"
import { VFlex } from "~/components"
import { formatPrice, formatUnitWithPrefixLength } from "~/utils"

import RadioSvg from "./assets/radio.svg?react"
import FilledRadioSvg from "./assets/filled_radio.svg?react"
import { useTranslation } from "react-i18next"
import { useTranslationOrKey } from "~/i18n"

const BundlePriceItem: FC<{
  priceItem: PriceContentV2_PriceItem
  iconSrc: string | undefined
  selected: boolean
  onClick: () => void
}> = ({ priceItem, selected, iconSrc, onClick }) => {
  const { t } = useTranslation()
  const { tOrKey } = useTranslationOrKey()
  return (
    <VFlex
      p={3}
      flex={1}
      borderColor={selected ? "Base/accentSecondary" : "transparent"}
      bgColor="white"
      borderWidth="2px"
      borderRadius="20px 0px 20px 20px"
      cursor="pointer"
      onClick={onClick}
      pos="relative"
      boxShadow={
        selected
          ? "none"
          : "box-shadow: 0px 1px 2px 0px rgba(41, 40, 36, 0.06), 0px 8px 28px 0px rgba(41, 40, 36, 0.04)"
      }
    >
      <Box
        textStyle="Other/PriceTag"
        borderRadius="8px"
        borderBottomRightRadius={0}
        bgColor="Base/accentSecondary"
        color="Base/neutralPrimary"
        py="1px"
        px={2}
        pos="absolute"
        right="-2px"
        top="-10px"
      >
        {priceItem.economy_percent}% OFF
      </Box>
      <Flex>
        <VFlex mr={2}>{iconSrc && <Image height="44px" src={iconSrc} />}</VFlex>
        <VFlex justifyContent="space-between" flex={1} mr={3}>
          <Box mb={1.5} textStyle="Subtitle/Secondary">
            {tOrKey(priceItem.title)}
          </Box>
          <Box textStyle="Subtitle/Hint">
            <Box as="span" color="Base/accentPrimary">
              {formatPrice(priceItem.currency_code, parseFloat(priceItem.economy_price))}{" "}
              {t("upsale_bundle.per")}{" "}
              {formatUnitWithPrefixLength(
                priceItem.subscription_period_unit,
                priceItem.subscription_period_length
              )}
            </Box>
            <Box textDecoration="line-through" ml={1} as="span" color="Base/baseSecondary">
              {formatPrice(priceItem.currency_code, parseFloat(priceItem.previous_price))}
            </Box>
          </Box>
        </VFlex>
        <Flex alignItems="center">{selected ? <FilledRadioSvg /> : <RadioSvg />}</Flex>
      </Flex>
    </VFlex>
  )
}

const images: Record<number, string> = {
  0: "https://storage.pora.ai/702157111b958b712a8836b2c2a36da9.webp",
  1: "https://storage.pora.ai/a873131aab3d0fa53e93be04ee6b6266.webp",
}

export const UpsaleBundlePrices: FC<{
  prices: PriceContentV2_PriceItem[]
  selectedPriceId: string
  setSelectedPriceId: (id: string) => void
}> = ({ prices, selectedPriceId, setSelectedPriceId }) => (
  <VFlex gap={3} w="full">
    {prices.map((price, i) => (
      <BundlePriceItem
        key={i}
        iconSrc={images[i]}
        priceItem={price}
        selected={selectedPriceId === price.id}
        onClick={() => setSelectedPriceId(price.id)}
      />
    ))}
  </VFlex>
)
