import { FC } from "react"
import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"
import { Box, Flex, Grid, GridItem, Heading } from "@chakra-ui/react"
import { AISkinCarePlanProperties_Block } from "~/generated/paywall"
import { useAISkinCarePlanProperties } from "~/store/selectors"
import { PresentationImage } from "~/components/PresentationImage"

export const AIReviewsGeneratedPlanBlock: FC<BlockWrapperProps> = (props) => {
  const { blocks } = useAISkinCarePlanProperties()
  return (
    <BlockWrapper {...props} header="Your AI-Skincare Plan">
      <AIReviewsGeneratedPlan blocks={blocks} />
    </BlockWrapper>
  )
}

const AIReviewsGeneratedPlan: FC<{ blocks: AISkinCarePlanProperties_Block[] }> = ({ blocks }) => {
  return (
    <Box w="full">
      <Box borderRadius="24px" bgColor="Base/neutralSecondary" p={4}>
        <Flex mb={4}>
          <Box
            textStyle="Other/PriceTag"
            borderRadius="8px"
            py="1px"
            px={2}
            bgColor="Base/accentSecondary"
            color="Base/neutralPrimary"
            as="span"
          >
            AI GENERATED
          </Box>
        </Flex>
        <Grid templateColumns="repeat(2, 1fr)" templateRows="1fr">
          {blocks.map((block, i) => (
            <GridItem
              paddingRight={i % 2 === 0 ? "16px" : "none"}
              paddingLeft={i % 2 === 1 ? "16px" : "none"}
              paddingBottom={i < blocks.length - 2 ? "16px" : "none"}
              paddingTop={i >= blocks.length - 2 ? "16px" : "none"}
              borderRight={i % 2 === 0 ? "1px solid black" : "none"}
              borderBottom={i < blocks.length - 2 ? "1px solid black" : "none"}
              borderColor="Base/baseTertiary"
              key={i}
            >
              <Box mb={1.5}>
                <PresentationImage
                  w="32px"
                  h="32px"
                  source={
                    block.icon || {
                      source: {
                        $case: "url",
                        url: "https://storage.pora.ai/2ac7c66c2ed3fc6ddadad5f5de1db2cc.webp",
                      },
                    }
                  }
                />
              </Box>
              <Box textStyle="Paragraph/Secondary" color="Base/baseSecondary">
                {block.title}
              </Box>
              <Heading as="div" size="Header/Tertiary">
                {block.content[0]}
                {block.content.length > 1 && (
                  <Box color="Base/baseSecondary" as="span">
                    +{block.content.length - 1}
                  </Box>
                )}
              </Heading>
            </GridItem>
          ))}
        </Grid>
      </Box>
    </Box>
  )
}
