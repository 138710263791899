import { FC, useCallback, useEffect } from "react"
import { useNavigateWithSearchParams } from "@poraai/shared/useNavigateWithSearchParams"

export const useNavigateSuccess = () => {
  const navigate = useNavigateWithSearchParams()
  return useCallback(() => {
    navigate("/app/")
  }, [navigate])
}

export const NavigateSuccessLink: FC = () => {
  const navigate = useNavigateSuccess()

  useEffect(() => {
    navigate()
  }, [navigate])

  return null
}
