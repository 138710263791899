import { FC, ReactNode } from "react"
import { Box, Flex, Divider } from "@chakra-ui/react"
import { CurrencyCode, formatPrice, formatUnitWithPrefixLengthDash, PeriodUnit } from "~/utils"

const defaultUnit = PeriodUnit.PERIOD_UNIT_UNSPECIFIED

const CircleIconNumber = ({ children }: { children: ReactNode }) => (
  <Flex
    justifyContent="center"
    alignItems="center"
    textColor="white"
    textStyle="Subtitle/Hint"
    bgColor="rgb(41 40 36 / 30%)"
    borderRadius="full"
    height="20px"
    minWidth="20px"
  >
    {children}
  </Flex>
)

export const InvoiceSkipTrial: FC<{
  currency?: CurrencyCode
  trialLength?: number
  trialUnit?: PeriodUnit
  trialPrice?: number
  subscriptionLength?: number
  subscriptionUnit?: PeriodUnit
  subscriptionTotalPrice?: number
  subscriptionPricePerUnit?: number
  discount?: string
  priceWithTrial?: number
  diffToPay?: number
}> = ({
  currency = "USD",
  trialPrice = 100,
  subscriptionLength = 2,
  subscriptionUnit = defaultUnit,
  subscriptionTotalPrice = 100,
  priceWithTrial = 10,
  diffToPay = 10,
}) => (
  <Box>
    <Box mb={4} textStyle="Subtitle/Primary">
      Price breakdown:
    </Box>
    <Divider mb={3} />
    <Flex mb={3} justifyContent="space-between">
      <Box textStyle="Paragraph/Primary">
        {formatUnitWithPrefixLengthDash(subscriptionUnit, subscriptionLength)} Program with Trial
      </Box>
      <Box textStyle="Subtitle/Secondary">{formatPrice(currency, priceWithTrial / 100)}</Box>
    </Flex>
    <Flex textColor="Base/accentSecondary" mb={3} justifyContent="space-between">
      <Box textStyle="Paragraph/Primary">Skip Trial</Box>
      <Box textStyle="Subtitle/Secondary">– {formatPrice(currency, trialPrice / 100)}</Box>
    </Flex>
    <Divider mb={3} />
    <Flex mb={3} justifyContent="space-between">
      <Box textStyle="Paragraph/Primary">Total today:</Box>
      <Box textStyle="Subtitle/Secondary">
        {formatPrice(currency, subscriptionTotalPrice / 100)}
      </Box>
    </Flex>

    <Flex
      flexDirection="column"
      gap={4}
      borderRadius="16px"
      bgColor="Base/neutralSecondary"
      mt={3}
      p={4}
    >
      <Flex gap={3}>
        <CircleIconNumber>1</CircleIconNumber>
        <Box textColor="Base/baseSecondary" textStyle="Paragraph/Secondary">
          When you tap Start program without trial you will be charged the difference between trial
          payment amount and subscription price and your payment method will be charged{" "}
          {formatPrice(currency, diffToPay / 100)}.
        </Box>
      </Flex>
      <Flex gap={3}>
        <CircleIconNumber>2</CircleIconNumber>
        <Box textColor="Base/baseSecondary" textStyle="Paragraph/Secondary">
          Your payment method will then be charged{" "}
          {formatPrice(currency, subscriptionTotalPrice / 100)} every month until you cancel.
        </Box>
      </Flex>
      <Flex gap={3}>
        <CircleIconNumber>3</CircleIconNumber>
        <Box textColor="Base/baseSecondary" textStyle="Paragraph/Secondary">
          You can cancel any time before the first day of your next subscription period to avoid
          automatic renewal.
        </Box>
      </Flex>
    </Flex>
  </Box>
)
