import { Box, BoxProps } from "@chakra-ui/react"
import { FC } from "react"

export const Strike: FC<BoxProps> = (props) => (
  <Box as="span" textDecoration="line-through" {...props} />
)

export const Strike2: FC<BoxProps> = (props) => (
  <Strike
    textStyle="Subtitle/Secondary"
    textDecorationThickness="2px"
    textDecorationColor="Base/accentSecondary"
    {...props}
  />
)
