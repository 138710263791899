import { Box, Flex, Heading, Image, List, ListItem } from "@chakra-ui/react"
import { FireIcon, TimeIcon } from "~/pages/Main/FaceExercises/Icons"

type FaceExercise = { img: string; title: string; descriptions: string[] }

const FaceExerciseListItem = ({ img, title, descriptions }: FaceExercise) => (
  <ListItem>
    <Flex alignItems="center">
      <Image w="48px" h="48px" src={img} alt={title} mr={3} />
      <Flex direction="column" justifyContent="center">
        <Heading as="h5" variant="text" size="MediumSubtitleSecondary">
          {title}
        </Heading>
        <Flex textStyle="RegularParagraphSecondary" textColor="Base/baseDisabled" gap={3}>
          {descriptions[0] && (
            <Flex gap={1} alignItems={"center"}>
              <TimeIcon fontSize={16} />
              {descriptions[0]}
            </Flex>
          )}
          {descriptions[1] && (
            <Flex gap={1} alignItems={"center"}>
              <FireIcon fontSize={16} />
              {descriptions[1]}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  </ListItem>
)

export const FaceExercises = ({
  headerTexts,
  faceExercises,
}: {
  headerTexts: string[]
  faceExercises: FaceExercise[]
}) => {
  return (
    <>
      <Heading size="MediumHeaderSecondary" textAlign="center" marginBottom={4}>
        {headerTexts[0]}
        <Box textColor="Base/baseDisabled">{headerTexts[1]}</Box>
      </Heading>
      <List width="full" spacing={5}>
        {faceExercises.map((exercise, i) => (
          <FaceExerciseListItem {...exercise} key={i} />
        ))}
      </List>
    </>
  )
}
