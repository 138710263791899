import { FC, ReactNode } from "react"
import { Box, BoxProps, Button, Flex, Image } from "@chakra-ui/react"

import { BlockHeading, BlockWrapper, BlockWrapperProps } from "../BlockWrapper"
import { BlockPropertyContent, VFlex } from "~/components"

import photo1 from "./assets/1.webp?url"
import photo2 from "./assets/2.webp?url"
import photo3 from "./assets/3.webp?url"
import photo4 from "./assets/4.webp?url"
import { ActionButton } from "../../ActionButton"
import { useTranslation } from "react-i18next"

type TipItemData = {
  title: string
  content: string
  image: string
}

const Prefix: FC<BoxProps> = (props) => <Box as="span" color="Base/accentPrimary" {...props} />

const TipItem: FC<{
  layout: "RIGHT" | "LEFT"
  image: string
  title: ReactNode
  content: ReactNode
}> = ({ layout, image, title, content }) => (
  <Flex gap={4} padding={4} layerStyle="internalFrame">
    {layout === "LEFT" && <Image src={image} h="112px" borderRadius="brand16" />}
    <VFlex gap={2} flexGrow={1}>
      <Box fontFamily="heading" textStyle="Header/Tertiary">
        {title}
      </Box>
      <Box textStyle="Paragraph/Secondary">{content}</Box>
    </VFlex>
    {layout === "RIGHT" && <Image src={image} h="112px" borderRadius="brand16" />}
  </Flex>
)

const Tips: FC<{ data: TipItemData[] }> = ({ data }) => {
  const { t } = useTranslation()
  return (
    <VFlex gap={2}>
      {data.map(({ title, content, image }, i) => (
        <TipItem
          key={i}
          title={
            <>
              <Prefix>
                {t("tips.tip")}#{i + 1}
              </Prefix>{" "}
              {title}
            </>
          }
          content={content}
          image={image}
          layout={i % 2 === 0 ? "RIGHT" : "LEFT"}
        />
      ))}
    </VFlex>
  )
}

export const TipsBlock: FC<BlockWrapperProps> = (props) => {
  const { t } = useTranslation()
  const tipsList = t("tips.list", { returnObjects: true })
  return (
    <BlockWrapper paddingY={6} {...props}>
      <BlockHeading>
        <BlockPropertyContent propKey="PB_TIPS.title">
          Get skin changing secrets
        </BlockPropertyContent>
      </BlockHeading>
      <Tips data={tipsList} />
      <ActionButton marginTop={4} actionSource="PB_TIPS">
        <BlockPropertyContent propKey="PB_TIPS.button_text">Get Full Set</BlockPropertyContent>
      </ActionButton>
    </BlockWrapper>
  )
}
