import { Box, Flex, Heading, Image } from "@chakra-ui/react"
import { ReactNode, useCallback, useMemo } from "react"
import { AttributedStringType } from "~/components/AttributedString"
import { HeaderHeadingPartWithLogo } from "~/pages/Main/Blocks/HeaderBlock/HeaderHeadingPart"
import {
  CosmeticsIcon,
  SmileIcon,
  TargetIcon,
  Underline,
} from "~/pages/Main/HeaderPrePaywall/Icons"
import { useThemeContentSwitch } from "~/theme/theme-from-interview/themeProvider"
import { formatDate, noop } from "~/utils"
import { useAmplitude } from "~/utils/analytics/useAmplitude"

const formatDateLong = formatDate("FULL_NUMERIC")
const formatDateShort = formatDate("MONTH_DAY")

export const HeaderGT2 = ({
  title,
  startDate,
  endDate,
  timeBlock,
  resultsText,
  graphImg,
  onTimerBlockClick = noop,
  programLengthWeeks,
  timerDescription,
}: {
  title: AttributedStringType
  startDate: Date
  endDate: Date
  timeBlock: ReactNode
  resultsText: string
  graphImg: string
  onTimerBlockClick?: () => void
  programLengthWeeks: number
  timerDescription: string
}) => {
  const log = useAmplitude()
  const onTimerBlockClickFn = useCallback(() => {
    log.click({ source: "header_timer" })
    onTimerBlockClick()
  }, [log, onTimerBlockClick])

  const longEndDate = useMemo(() => formatDateLong(endDate), [endDate])
  const shortStartDate = useMemo(() => formatDateShort(startDate), [startDate])
  const shortEndDate = useMemo(() => formatDateShort(endDate), [endDate])
  const iconColor = useThemeContentSwitch({ lovi: "Base/basePrimary", pora: "Base/accentPrimary" })

  return (
    <>
      <Box mb={8} w="full">
        <HeaderHeadingPartWithLogo title={title} />
      </Box>
      {/* TODO use <GoalsList/> */}
      <Flex textStyle="RegularParagraph" gap={4} px={6} flexDirection="column" mb={8}>
        <Flex alignItems="center" gap={4}>
          <TargetIcon fontSize="36px" color={iconColor} />
          <Box>
            <Box textStyle="MediumSubtitleSecondary" as="span">
              See visible results
            </Box>{" "}
            by {longEndDate} and&nbsp;feel great
          </Box>
        </Flex>
        <Flex alignItems="center" gap={4}>
          <CosmeticsIcon fontSize="36px" color={iconColor} />
          <Box>Get personalized product recommendations</Box>
        </Flex>
        <Flex alignItems="center" gap={4}>
          <SmileIcon fontSize="36px" color={iconColor} />
          <Box>Boost the result with easy-to-follow skincare program</Box>
        </Flex>
      </Flex>
      <Box
        onClick={onTimerBlockClickFn}
        borderTopRadius="3xl"
        borderWidth={1}
        borderColor="Base/neutralActive"
        bgColor="Base/accentSecondaryBg"
        py={4}
        px={6}
      >
        <Box mb={2} textStyle="RegularParagraphSecondary">
          {timerDescription}
        </Box>
        <Box mb={2} textStyle="MediumSubtitlePrimary" textColor="Base/accentSecondary">
          Time remaining {timeBlock}
        </Box>
        <Box textStyle="RegularParagraphSecondary" textColor="Base/baseSecondary">
          Scroll down to get started!
        </Box>
      </Box>
      <Box
        borderBottomRadius="3xl"
        borderWidth={1}
        borderTopWidth={0}
        borderColor="Base/neutralActive"
        bgColor="white"
        textAlign="center"
        pt={8}
        px={6}
        pb={5}
      >
        <Box textStyle="Paragraph/Secondary" textColor="Base/baseSecondary">
          Based on your answers you can reach&nbsp;your&nbsp;goal
        </Box>
        <Heading as="h2" mt={3} size="Header/Secondary">
          <Box textColor="Base/accentPrimary">{resultsText}</Box>
          <Box>
            by{" "}
            <Box as="span" pos="relative">
              {shortEndDate}
              <Box pos="absolute" left="-9px" right="6px" bottom="-13px">
                <Underline color="Base/accentPrimary" />
              </Box>
            </Box>
          </Box>
        </Heading>
        <Box mt={3} mb={6} textStyle="Paragraph/Secondary">
          Personalized treatment that works <br />
          best just for you
        </Box>
        <Image src={graphImg} alt="graph" mb={2} />
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <Box as="span" textColor="Base/basePrimary" textStyle="MediumSubtitleTertiary">
              {shortStartDate}
            </Box>
            <Box as="span" textColor="Base/baseDisabled" textStyle="MediumSubtitleTertiary">
              {" "}
              Today
            </Box>
          </Box>
          <Box textColor="Base/accentActive">
            <Box as="span" opacity={0.7} textStyle="MediumSubtitleTertiary">
              in {programLengthWeeks} weeks
            </Box>
            <Box as="span" textStyle="MediumSubtitleTertiary">
              {" "}
              {shortEndDate}
            </Box>
          </Box>
        </Flex>
      </Box>
    </>
  )
}
