import { FC, ReactNode } from "react"
import {
  Box,
  Heading,
  Image,
  Button,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalOverlay,
  ModalProps,
  AspectRatio,
} from "@chakra-ui/react"
import { VFlex } from "~/components/VFlex"

const Content: FC<{ children: ReactNode; discount: string }> = ({ children, discount }) => (
  <VFlex
    gap={6}
    alignItems="center"
    borderRadius="32px"
    paddingX={8}
    pt={16}
    pb={8}
    bgColor="white"
  >
    <VFlex gap={2} alignItems="center">
      <Heading size="Header/Secondary" textAlign="center">
        Did you know?
      </Heading>
      <Box textStyle="Paragraph/Primary" textAlign="center">
        Lóvi users who start their program with Product Scanner get visible results 34%&nbsp;faster
      </Box>
    </VFlex>
    <Box w="full">
      <AspectRatio ratio={539 / 345}>
        <Image
          src="https://storage.pora.ai/0eb6040eeee15fbb768440b881242f3f.webp"
          alt="Results 34% faster with ai scanner"
        />
      </AspectRatio>
    </Box>
    <Box
      textStyle="Paragraph/Primary"
      color="Base/baseSecondary"
      textAlign="center"
      sx={{
        "& em": {
          color: "Base/accentPrimary",
          fontWeight: 600,
          fontStyle: "normal",
        },
      }}
    >
      We want you to see visible results, so we’re offering you an extra{" "}
      <em>{discount}&nbsp;off</em> the AI-Product Scan!
    </Box>
    {children}
  </VFlex>
)

export const Step3Modal: FC<Pick<ModalProps, "onClose" | "isOpen"> & { discount: string }> = ({
  isOpen,
  onClose,
  discount,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent mx={6} bg="transparent">
        <ModalCloseButton borderRadius="full" color="Base/baseSecondary" />
        <Content discount={discount}>
          <Button w="full" variant="action" onClick={onClose}>
            Continue
          </Button>
        </Content>
      </ModalContent>
    </Modal>
  )
}
