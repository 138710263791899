import { Box, Flex, Icon } from "@chakra-ui/react"
import { FC, ReactNode } from "react"
import { formatPrice, formatUnitWithPrefixLength, noop } from "~/utils"

import { CheckedRadio, UncheckedRadio } from "./Icons"
import { Strike } from "./Strike"
import type { PriceItemProps } from "./types"

const PriceLayout: FC<{
  isSelected?: boolean
  label?: ReactNode
  title: ReactNode
  subtitle: ReactNode
  price: ReactNode
  prevPricePerDay: ReactNode
  onClick?: () => void
}> = ({ isSelected = false, label, title, subtitle, price, prevPricePerDay, onClick = noop }) => (
  <Flex
    direction="column"
    bgColor={label ? "Other/Success" : "none"}
    borderTopRadius="3xl"
    borderBottomRadius="huge"
    color="white"
    onClick={onClick}
    w="100%"
    cursor="pointer"
    userSelect="none"
  >
    {label && (
      <Box textAlign="center" textStyle="small" marginY={0.5}>
        {label}
      </Box>
    )}
    <Flex
      direction="row"
      gap={3}
      role="group"
      w="100%"
      paddingY={2}
      paddingRight={2}
      paddingLeft={4}
      alignItems="center"
      borderRadius="3xl"
      color={isSelected ? "Base/basePrimary" : "Base/baseSecondary"}
      bgColor={isSelected ? "white" : "Base/neutralSecondary"}
      boxShadow={isSelected ? "bigOnePrice" : undefined}
    >
      <Icon as={isSelected ? CheckedRadio : UncheckedRadio} fontSize={28} />
      <Flex direction="column" grow={1} gap={1}>
        <Box fontFamily="heading" textStyle="Header/Secondary">
          {title}
        </Box>
        <Box textStyle="Subtitle/Hint" color="Base/baseSecondary">
          {subtitle}
        </Box>
      </Flex>
      <Flex
        direction="column"
        alignItems="center"
        bgColor={isSelected ? "Other/NewBg" : "transparent"}
        borderColor={isSelected ? "transparent" : "Base/baseDisabled"}
        borderWidth="1px"
        borderRadius="2xl"
        paddingX={3}
        paddingTop={2}
        paddingBottom={4}
        color="Base/baseSecondary"
      >
        <Box
          textStyle="Header/Secondary"
          textDecorationLine="line-through"
          textDecorationColor="Other/Error"
        >
          {prevPricePerDay}
        </Box>
        <Box fontFamily="heading" textStyle="Header/Secondary">
          {price}
        </Box>
        <Box textStyle="Subtitle/Hint">per day</Box>
      </Flex>
    </Flex>
  </Flex>
)

export const PriceItem1: FC<PriceItemProps> = ({
  currency_code,
  isSelected,
  title,
  price,
  pricePerDay,
  previousPrice,
  previousPricePerDay,
  isPopular = false,
  onClick = noop,
  subscription_period_length,
  subscription_period_unit,
}) => {
  let label = null
  const subtitle = (
    <>
      <Strike>{formatPrice(currency_code, parseFloat(previousPrice))}</Strike>
      {` ${formatPrice(currency_code, price / 100)} per ${formatUnitWithPrefixLength(
        subscription_period_unit,
        subscription_period_length
      )} `}
    </>
  )
  if (isPopular) {
    label = "most popular"
  }

  return (
    <PriceLayout
      isSelected={isSelected}
      label={label}
      title={title}
      subtitle={subtitle}
      price={formatPrice(currency_code, parseFloat(pricePerDay))}
      prevPricePerDay={formatPrice(currency_code, parseFloat(previousPricePerDay))}
      onClick={onClick}
    />
  )
}
