import { FC } from "react"
import { Box, Flex, Divider } from "@chakra-ui/react"
import { CurrencyCode, formatPrice, formatUnitWithPrefixLengthDash, PeriodUnit } from "~/utils"

const defaultUnit = PeriodUnit.PERIOD_UNIT_UNSPECIFIED

export const InvoiceSkipTrialDiscounted: FC<{
  currency?: CurrencyCode
  trialLength?: number
  trialUnit?: PeriodUnit
  trialPrice?: number
  subscriptionLength?: number
  subscriptionUnit?: PeriodUnit
  subscriptionTotalPrice?: number
  subscriptionPricePerUnit?: number
  discount?: string
  priceWithTrial?: number
  diffToPay?: number
  previousPrice?: number
  economyPrice?: number
}> = ({
  currency = "USD",
  trialPrice = 100,
  subscriptionLength = 2,
  subscriptionUnit = defaultUnit,
  subscriptionTotalPrice = 100,
  discount = "10",
  priceWithTrial = 10,
  diffToPay = 10,
  previousPrice = 10,
  economyPrice = 10,
}) => (
  <Box>
    <Box mb={4} textStyle="Subtitle/Primary">
      Price breakdown:
    </Box>
    <Divider mb={3} />
    <Flex mb={3} justifyContent="space-between">
      <Box textStyle="Paragraph/Primary">
        {formatUnitWithPrefixLengthDash(subscriptionUnit, subscriptionLength)} Program with Trial
      </Box>
      <Box textStyle="Subtitle/Secondary">{formatPrice(currency, priceWithTrial / 100)}</Box>
    </Flex>
    <Divider mb={3} />
    <Flex mb={3} justifyContent="space-between">
      <Box textStyle="Paragraph/Primary">Skip Trial</Box>
      <Box textStyle="Subtitle/Secondary">– {formatPrice(currency, trialPrice / 100)}</Box>
    </Flex>
    <Flex mb={3} justifyContent="space-between" alignItems="center">
      <Box
        px={2}
        py={1}
        borderRadius="16px"
        bgColor="Base/accentSecondary"
        textColor="Base/neutralPrimary"
        textStyle="Subtitle/Secondary"
      >
        {discount} OFF
      </Box>
      <Box textColor="Base/baseSecondary" textStyle="Paragraph/Primary">
        Billed at {formatPrice(currency, previousPrice / 100)}
      </Box>
    </Flex>
    <Flex textColor="Base/accentSecondary" mb={3} justifyContent="space-between">
      <Box textStyle="Paragraph/Primary">Total Savings</Box>
      <Box textStyle="Subtitle/Secondary">– {formatPrice(currency, economyPrice / 100)}</Box>
    </Flex>
    <Divider mb={3} />
    <Flex mb={3} justifyContent="space-between">
      <Box textStyle="Paragraph/Primary">Total today:</Box>
      <Box textStyle="Subtitle/Secondary">
        {formatPrice(currency, subscriptionTotalPrice / 100)}
      </Box>
    </Flex>
    <Flex
      flexDirection="column"
      gap={4}
      borderRadius="16px"
      bgColor="Base/neutralSecondary"
      mt={3}
      p={4}
      textStyle="Paragraph/Secondary"
      textColor="Base/baseSecondary"
    >
      When you tap Start program without trial you will be charged the difference between trial
      payment amount and subscription price and your payment method will be charged{" "}
      {formatPrice(currency, diffToPay / 100)}. Your payment method will then be charged{" "}
      {formatPrice(currency, subscriptionTotalPrice / 100)} every month until you cancel. You can
      cancel any time before the first day of your next subscription period to avoid automatic
      renewal.
    </Flex>
  </Box>
)
