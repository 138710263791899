import { Flex, FlexProps } from "@chakra-ui/react"
import { FC } from "react"

export const CardPreview: FC<FlexProps> = (props) => (
  // @ts-ignore FIXME
  <Flex
    // @ts-ignore FIXME
    borderRadius="brand-lg"
    bgColor="Base/neutralDisabled"
    p={4}
    gap={4}
    direction="column"
    {...props}
  />
)
