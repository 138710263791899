import { Box, Flex } from "@chakra-ui/react"
import CheckIcon from "./assets/check_icon.svg?react"
import LineIcon from "./assets/line.svg?react"
import GreyLineIcon from "./assets/gray_line.svg?react"
import Step2Icon from "./assets/step_2_icon.svg?react"
import Step3Icon from "./assets/step_3_icon.svg?react"
import { VFlex } from "~/components"

export const Navigation = ({ onClickSkip }: { onClickSkip: () => void }) => {
  return (
    <Flex>
      <Flex justifyContent="space-between" flex={1}>
        <VFlex position="relative" alignItems="center" flexShrink={0}>
          <Flex>
            <CheckIcon />
          </Flex>
          <Box mt={0.5} textStyle="Other/PriceTag">
            BUY PLAN
          </Box>
          <Box left="50%" zIndex={-1} position="absolute" top="11px">
            <LineIcon />
          </Box>
        </VFlex>
        <VFlex position="relative" alignItems="center" flexShrink={0}>
          <Flex>
            <Step2Icon />
          </Flex>
          <Box mt={0.5} textStyle="Other/PriceTag">
            BUY PLAN
          </Box>
          <Box right="50%" zIndex={-1} position="absolute" top="11px">
            <LineIcon />
          </Box>
          <Box left="50%" zIndex={-1} position="absolute" top="11px">
            <GreyLineIcon />
          </Box>
        </VFlex>
        <VFlex position="relative" alignItems="center" flexShrink={0}>
          <Flex>
            <Step3Icon />
          </Flex>
          <Box mt={0.5} color="Base/baseDisabled" textStyle="Other/PriceTag">
            USE IT
          </Box>
          <Box right="50%" zIndex={-1} position="absolute" top="11px">
            <GreyLineIcon />
          </Box>
        </VFlex>
      </Flex>
      <Flex position="relative" top="4px" ml={4} alignItems="flex-start">
        <Flex onClick={onClickSkip} alignItems="center">
          <Box textStyle="Subtitle/Hint">Skip</Box>
          <Flex>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4 2L8 6L4 10" stroke="#292824" strokeLinecap="round" />
            </svg>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
