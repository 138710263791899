import { FC } from "react"
import { AttributedString, AttributedStringType } from "~/components"
import { CardExpertAdvice, CardProducts } from "./Cards"
import { OrderedList, OrderedListItem } from "./OrderedList"
import { SkinIssueList, SkinIssues } from "./SkinIssueList"

export const ProgramList2: FC<{
  title: string | AttributedStringType
  subtitle: string
  issues: SkinIssues[]
}> = ({ title, subtitle, issues }) => (
  <OrderedList w="full">
    <OrderedListItem
      icon={1}
      title={<AttributedString>{title}</AttributedString>}
      subtitle={subtitle}
    >
      <SkinIssueList marginX={-8} paddingX={8} items={issues} />
    </OrderedListItem>
    <OrderedListItem icon={2} title="Personalized skin care routine">
      <CardProducts />
    </OrderedListItem>
    <OrderedListItem icon={3} title="Dermatologist support">
      <CardExpertAdvice />
    </OrderedListItem>
  </OrderedList>
)
