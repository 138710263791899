import { FC } from "react"

import { BlockWrapperProps, BlockWrapper } from "../BlockWrapper"
import { EnhancedPhotoContainer } from "./EnhancedPhotoContainer"

export const EnhancedPhotoBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper variant="transparent" mb={4} mt={-2} {...props}>
    <EnhancedPhotoContainer />
  </BlockWrapper>
)
