import { FC, SVGProps } from "react"
import { Icon, IconProps } from "@chakra-ui/react"

const svgIcons = import.meta.glob<FC<SVGProps<SVGSVGElement>>>("./icons/*.svg", {
  eager: true,
  import: "ReactComponent",
})

const filenameToIconName = (filename: string) => filename.split(".svg")[0]?.split("/").at(-1)

export default Object.fromEntries(
  Object.entries(svgIcons).map(([name, Component]) => [
    filenameToIconName(name),
    (props: IconProps) => (
      <Icon viewBox="0 0 48 48" {...props}>
        <Component />
      </Icon>
    ),
  ])
)
