import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { CONFIG } from "~/config"
import { useUpsaleSubscriptionPrice, useUpsaleSubscriptionDiscountedPrice } from "~/store/selectors"

import { SkipTrial } from "./SkipTrial"

const stripePromise = loadStripe(CONFIG.stripe.key)

export function SkipTrialContainer() {
  const skipTrialPrice = useUpsaleSubscriptionPrice()
  const skipTrialDiscountedPrice = useUpsaleSubscriptionDiscountedPrice()
  if (!skipTrialPrice || !skipTrialDiscountedPrice) {
    return null
  }

  return (
    <Elements stripe={stripePromise}>
      <SkipTrial
        skipTrialPrice={skipTrialPrice}
        skipTrialDiscountedPrice={skipTrialDiscountedPrice}
      />
    </Elements>
  )
}
