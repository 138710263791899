import { Box, Center, Flex, Heading, List, ListItem } from "@chakra-ui/react"
import { CrossLineIcon, MarkIcon, RingIcon, StarIcon, UnlockIcon } from "./Icons"

export const HowTrialWorks = ({ showDay2 = true }: { showDay2?: boolean }) => {
  return (
    <>
      <Heading size="MediumHeaderSecondary" textAlign="center" marginBottom={6}>
        How your trial works
      </Heading>
      <List>
        <ListItem>
          <Flex>
            <Flex alignItems="center" mr={3} flexDirection="column">
              <Center alignSelf="self-start" p={2} bgColor="Other/Green" borderRadius="14px">
                <MarkIcon />
              </Center>
              <Box flex={1} width="4px" bgColor="Other/Green" />
            </Flex>
            <Box mb={7}>
              <Box textStyle="Subtitle/Primary" textColor="Other/Green">
                <Box as="span" pos="relative">
                  Sign up for Pora
                  <CrossLineIcon pos="absolute" left={0} right={0} top="44%" />
                </Box>
              </Box>
              <Box textStyle="Paragraph/Secondary" mt={1}>
                You successfully started your journey to healthier and glowing skin.
              </Box>
            </Box>
          </Flex>
        </ListItem>
        <ListItem>
          <Flex>
            <Flex alignItems="center" mr={3} flexDirection="column">
              <Center
                boxShadow="0px 0px 0px 5px rgba(69, 184, 124, 0.15), 0px 0px 0px 10px rgba(69, 184, 124, 0.05);"
                alignSelf="self-start"
                p={2}
                bgColor="Other/Green"
                borderRadius="14px"
              >
                <UnlockIcon />
              </Center>
              <Box flex={1} width="4px" bgColor="Other/Green" />
            </Flex>
            <Box mb={7}>
              <Box textStyle="Subtitle/Primary">Today</Box>
              <Box textStyle="Paragraph/Secondary" mt={1}>
                Your trial begins. Enjoy full access to all the premium content.
              </Box>
            </Box>
          </Flex>
        </ListItem>
        {showDay2 && (
          <ListItem>
            <Flex>
              <Flex alignItems="center" mr={3} flexDirection="column">
                <Center alignSelf="self-start" p={2} bgColor="Other/Green" borderRadius="14px">
                  <RingIcon />
                </Center>
                <Box flex={1} width="4px" bgColor="Base/accentPrimary" />
              </Flex>
              <Box mb={7}>
                <Box textStyle="Subtitle/Primary">Day 2</Box>
                <Box textStyle="Paragraph/Secondary" mt={1}>
                  Get a reminder about the time your trial will end. You can cancel it anytime.
                </Box>
              </Box>
            </Flex>
          </ListItem>
        )}
        <ListItem>
          <Flex>
            <Flex alignItems="center" mr={3} flexDirection="column">
              <Center alignSelf="self-start" p={2} bgColor="Base/accentPrimary" borderRadius="14px">
                <StarIcon />
              </Center>
              <Box
                flex={1}
                width="4px"
                background="linear-gradient(180deg, #00C0EB 0%, rgba(77, 193, 255, 0.56) 66.74%, rgba(0, 192, 235, 0.00) 100%);"
              />
            </Flex>
            <Box>
              <Box textStyle="Subtitle/Primary">Day 3</Box>
              <Box textStyle="Paragraph/Secondary" mt={1}>
                Your trial will be conveted to a full price unless it&apos;s canceled.
              </Box>
            </Box>
          </Flex>
        </ListItem>
      </List>
    </>
  )
}
