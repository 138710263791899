import { FC, ReactNode } from "react"
import { Box, Flex, FlexProps, Icon } from "@chakra-ui/react"

import { ExternalBox, VFlex } from "~/components"
import { CurrencyCode, formatPrice, noop } from "~/utils"

import { CheckedRadio, UncheckedRadio } from "./Icons"
import { Strike } from "./Strike"
import type { PriceItemProps } from "./types"
import tagBgSrc from "./assets/tagBg.webp"
import { useTranslation } from "react-i18next" // Webp because SVG in mask-image in Safari doesn't resize correctly

const PriceItemBadge: FC<{ children: ReactNode }> = ({ children }) => (
  <Box
    paddingX={2}
    paddingY="1px"
    borderRadius="8px"
    borderBottomLeftRadius={0}
    background="Base/accentSecondary"
    color="Base/neutralPrimary"
    textStyle="Other/PriceTag"
  >
    {children}
  </Box>
)

const PriceLayout: FC<{
  isSelected?: boolean
  label?: ReactNode
  children: ReactNode
  onClick?: () => void
  unselectedBorderColor?: FlexProps["borderColor"]
}> = ({
  isSelected = false,
  label,
  children,
  onClick = noop,
  unselectedBorderColor = "Base/neutralPrimary",
}) => (
  <Box as="label" aria-checked={isSelected}>
    <input type="checkbox" aria-checked={isSelected} hidden />

    {label && (
      <ExternalBox top="-8px">
        <PriceItemBadge>{label}</PriceItemBadge>
      </ExternalBox>
    )}
    <Flex
      paddingStart={4}
      paddingEnd={1.5}
      direction="row"
      justify="flex-start"
      align="center"
      gap={3}
      borderColor={unselectedBorderColor}
      bgColor="Base/neutralPrimary"
      borderWidth={2}
      borderRadius={20}
      borderTopLeftRadius={label ? 0 : undefined}
      onClick={onClick}
      w="full"
      cursor="pointer"
      userSelect="none"
      aria-checked={isSelected}
      _checked={{
        borderColor: "Base/accentSecondary",
        bgColor: "Base/accentSecondaryBg",
      }}
    >
      <Icon
        fontSize={28}
        as={isSelected ? CheckedRadio : UncheckedRadio}
        color="Base/baseDisabled"
        aria-checked={isSelected}
        _checked={{
          color: "Base/accentSecondary",
        }}
      />
      {children}
    </Flex>
  </Box>
)

const PriceNumbers: FC<{
  currency: CurrencyCode
  prevPricePerPeriod: number
  pricePerPeriod: number
  isSelected?: boolean
}> = ({ currency, pricePerPeriod, prevPricePerPeriod, isSelected = false }) => {
  const { t } = useTranslation()
  return (
    <VFlex
      minH="84px"
      minW="111px"
      paddingY={1}
      paddingLeft={5}
      paddingRight={3}
      alignItems="center"
      justifyContent="center"
      color="Base/baseSecondary"
      bgColor="Base/neutralSecondary"
      aria-checked={isSelected}
      _checked={{
        bgColor: "Base/accentSecondaryBg2",
      }}
      sx={{
        maskImage: `url(${tagBgSrc})`,
        maskPosition: "center right",
        maskRepeat: "no-repeat",
        maskType: "alpha",
        maskSize: "100% 72px",
      }}
    >
      <Box textStyle="Subtitle/Hint">
        <Strike>{formatPrice(currency, prevPricePerPeriod)}</Strike>
      </Box>
      <Box
        textStyle="Header/MonospacedSecondary"
        fontFamily="heading"
        aria-checked={isSelected}
        _checked={{
          color: "Base/basePrimary",
        }}
      >
        {formatPrice(currency, pricePerPeriod)}
      </Box>
      <Box textStyle="Subtitle/Hint">{t("prices.per_day")}</Box>
    </VFlex>
  )
}

const PriceLayoutTitle: FC<{ title: ReactNode; subtitle: ReactNode }> = ({ title, subtitle }) => (
  <VFlex gap={1} flex="1">
    <Box textStyle="Subtitle/Secondary">{title}</Box>

    <Flex direction="row" gap={1} textStyle="Subtitle/Hint">
      {subtitle}
    </Flex>
  </VFlex>
)

const PriceItemWrapper: FC<
  PriceItemProps & { children: ReactNode; unselectedBorderColor: FlexProps["borderColor"] }
> = ({
  children,
  isSelected,
  pricePerDay,
  previousPricePerDay,
  isPopular = false,
  onClick = noop,
  unselectedBorderColor,
  currency_code,
}) => {
  const { t } = useTranslation()
  let label = null
  if (isPopular) {
    label = t("prices.most_popular")
  }

  return (
    <PriceLayout
      unselectedBorderColor={unselectedBorderColor}
      isSelected={isSelected}
      label={label}
      onClick={onClick}
    >
      {children}
      <PriceNumbers
        currency={currency_code}
        isSelected={isSelected}
        /* FIXME parseFloat */
        pricePerPeriod={parseFloat(pricePerDay)}
        /* FIXME parseFloat */
        prevPricePerPeriod={parseFloat(previousPricePerDay)}
      />
    </PriceLayout>
  )
}

const PriceItem5Wrapper: FC<
  PriceItemProps & { unselectedBorderColor: FlexProps["borderColor"] }
> = (props) => {
  const { currency_code, title: _title, trialPrice, previousPrice } = props
  const { t, i18n } = useTranslation()
  const title = i18n.exists(_title) ? t(_title as any) : _title
  const subtitle = (
    <Box color="Base/baseSecondary">
      <Strike textDecorationColor="Base/accentSecondary">
        {formatPrice(currency_code, parseFloat(previousPrice))}
      </Strike>
      {" → "}
      {formatPrice(currency_code, trialPrice / 100)}
    </Box>
  )

  return (
    <PriceItemWrapper {...props}>
      <PriceLayoutTitle title={title} subtitle={subtitle} />
    </PriceItemWrapper>
  )
}

export const PriceItem5: FC<PriceItemProps> = (props) => (
  <PriceItem5Wrapper unselectedBorderColor="Base/neutralPrimary" {...props} />
)

export const PriceItem6: FC<PriceItemProps> = (props) => (
  <PriceItem5Wrapper unselectedBorderColor="Base/neutralSecondary" {...props} />
)
