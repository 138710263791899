import { Image, SimpleGrid } from "@chakra-ui/react"
import { FC } from "react"
import { ListItemGeneric } from "~/components"
import book from "./icons/book.svg"
import chart from "./icons/chart.svg"
import cosmetics from "./icons/cosmetics.svg"
import drops from "./icons/drops.svg"
import face from "./icons/face.svg"

const ProgramListItem: FC<{
  image: string
  title: string
  description?: string
}> = ({ image, title, description }) => (
  <ListItemGeneric title={title} description={description}>
    <Image src={image} alt="" />
  </ListItemGeneric>
)

export const ProgramList: FC = () => (
  <SimpleGrid columns={1} spacing={4} w="311px">
    <ProgramListItem
      image={cosmetics}
      title="Skincare products recommendation"
      description="Handpicked for your skin from 50,000+ options"
    />
    <ProgramListItem
      image={face}
      title="Face exercises"
      description="The best ones for your age and goals"
    />
    <ProgramListItem
      image={drops}
      title="Easy-to-follow daily routine"
      description="Based on your skin analysis"
    />
    <ProgramListItem
      image={chart}
      title="Face scans"
      description="To detect skin issues and track your progress at every step"
    />
    <ProgramListItem
      image={book}
      title="Essential insights"
      description="To help keep your skin healthy"
    />
  </SimpleGrid>
)
