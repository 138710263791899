// as soon as I18n takes language from query params, which used after in modules in const declarations
// it's important to keep i18n first import
// todo for sure its better to convert all places which uses a language from declarations to functions
import "./i18n"
import { useEffect, FC } from "react"
import { createRoot } from "react-dom/client"
import { Global } from "@emotion/react"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import { Provider as ReduxProvider } from "react-redux"
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom"

import { App } from "./App"
import { TimerProvider } from "./components"
import { CONFIG } from "./config"
import { store } from "./store"
import { ThemeProvider, ChakraProviderWithTheme, commonFontFaces } from "./theme"
import { sendMarketingAttributes } from "./api/api"
import { SetLanguageCSSVariable } from "~/i18n"
import "./global.css"

const SENTRY_DSN = CONFIG.sentry.dsn

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: CONFIG.environment,

    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    tracesSampleRate: 1.0,
  })
}

const AppProviders: FC = () => (
  <Sentry.ErrorBoundary fallback={<div>An error has occurred</div>}>
    <SetLanguageCSSVariable />
    <ThemeProvider theme={CONFIG.theme}>
      <ChakraProviderWithTheme>
        <Global styles={commonFontFaces(CONFIG.theme)} />
        <ReduxProvider store={store}>
          <TimerProvider>
            <App />
          </TimerProvider>
        </ReduxProvider>
      </ChakraProviderWithTheme>
    </ThemeProvider>
  </Sentry.ErrorBoundary>
)

const main = (): Promise<unknown> => {
  if (import.meta.env["REACT_APP_MOCK"]) {
    return import("./mocks/browser").then(({ worker }) => {
      worker.start()
    })
  }
  return Promise.resolve(undefined)
}

main().finally(() => {
  const rootEl = document.getElementById("root")
  if (rootEl) {
    const root = createRoot(rootEl)
    root.render(<AppProviders />)
  }
  sendMarketingAttributes()
})

if (typeof window._AutofillCallbackHandler === "undefined") {
  window._AutofillCallbackHandler = () => void 0
}
