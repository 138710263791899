import { Box, Grid, GridItem, Heading, Image } from "@chakra-ui/react"

type Company = { img: string; title: string }
const CompanyGridItem = ({ img, title }: Company) => {
  return (
    <GridItem>
      <Image width="full" src={img} alt={title} />
      <Box
        whiteSpace="pre-wrap"
        textAlign="center"
        textStyle="MediumHintPrimary"
        textColor="Base/baseSecondary"
        mt={1.5}
      >
        {title}
      </Box>
    </GridItem>
  )
}

export const WeArePalta = ({ companies }: { companies: Company[] }) => {
  return (
    <>
      <Heading size="MediumHeaderSecondary" textAlign="center" marginBottom={6}>
        We are a part of{" "}
        <Box as="span" textColor="#33CC33">
          Palta
        </Box>{" "}
        family
        <Box textColor="Base/baseDisabled">Our mission is your well&#8209;being</Box>
      </Heading>
      {/*Column width is 65 (64 in design, but wrong font style used) because pora text can't fit in 64 in 2 lines*/}
      <Grid columnGap={6} rowGap={4} templateColumns="repeat(3, 65px)">
        {companies.map((company, i) => (
          <CompanyGridItem key={i} {...company} />
        ))}
      </Grid>
    </>
  )
}
