import { FC } from "react"
import { Heading } from "@chakra-ui/react"
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHidden,
  ModalOverlay,
} from "~/components/ModalHidden"
import { TextBelowPaymentFormContainer } from "~/components/PaymentTexts"
import { useSelectedPrice } from "~/store/selectors"
import { noop } from "~/utils"
import { Invoice } from "../Invoice"
import { PaymentFormContainer } from "../PaymentForm"
import { transformPriceItem } from "./PriceSelectRadio"

export const PaymentFormPopupContainer: FC<{ hidden?: boolean; onClose?: () => void }> = ({
  hidden = false,
  onClose = noop,
}) => {
  const selectedPrice = useSelectedPrice()
  if (!selectedPrice) {
    return null
  }

  const paymentHeader = selectedPrice.trial_price ? (
    <>
      Select Payment Method
      <br />
      For The Trial
    </>
  ) : (
    "Select Payment Method"
  )

  return (
    <ModalHidden
      isOpen={!hidden}
      onClose={onClose}
      size="full"
      motionPreset="none"
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent borderRadius="none" maxW="450px">
        <ModalCloseButton bgColor="white" borderRadius="full" />
        <ModalBody paddingBottom={8} paddingTop={12} paddingX={6} zIndex={-1}>
          <Heading size="Header/Secondary" marginBottom={6} textAlign="center">
            {paymentHeader}
          </Heading>
          <PaymentFormContainer>
            <Invoice {...transformPriceItem(selectedPrice)} />
          </PaymentFormContainer>
          <TextBelowPaymentFormContainer mt={3} />
        </ModalBody>
      </ModalContent>
    </ModalHidden>
  )
}
