import { FC } from "react"
import { useUpsaleSubscriptionPrice } from "~/store/selectors"
import { InvoiceSkipTrial } from "~/pages/Main/Invoice/InvoiceSkipTrial"

export const InvoiceSkipTrialContainer: FC = () => {
  const {
    currency_code: currency,
    trial_period_length: trialLength = 0,
    trial_period_unit: trialUnit,
    trial_price_cents: trialPrice = Number.NaN,
    subscription_period_length: subscriptionLength = 0,
    subscription_period_unit: subscriptionUnit,
    subscription_price_per_unit_cents: subscriptionPricePerUnit = Number.NaN,
    subscription_price_cents: subscriptionTotalPrice = Number.NaN,
    economy_percent: discount = "",
    price_with_trial_cents: priceWithTrial = Number.NaN,
    price_diff_to_pay_cents: diffToPay = Number.NaN,
  } = useUpsaleSubscriptionPrice() ?? {}
  const props = {
    currency,
    priceWithTrial,
    diffToPay,
    trialLength,
    trialUnit,
    trialPrice,
    subscriptionLength,
    subscriptionUnit,
    subscriptionPricePerUnit,
    subscriptionTotalPrice,
    discount,
  }
  return <InvoiceSkipTrial {...props} />
}
