import { createIcon } from "@chakra-ui/react"

export const CheckIcon = createIcon({
  viewBox: "0 0 22 22",
  defaultProps: {
    width: "22px",
    height: "22px",
    fill: "none",
    color: "Other/Green",
  },
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4926 0.918631C15.1079 0.837365 19.2685 3.78665 20.6539 8.11048C22.002 12.3179 20.06 16.7804 16.3895 19.3226C12.8785 21.7544 8.18879 21.6441 4.7325 19.1379C1.35734 16.6906 0.150278 12.3843 1.39427 8.44961C2.69449 4.33702 6.10813 0.995834 10.4926 0.918631Z"
        fill="currentColor"
      />
      <path
        d="M6.875 11.0166C7.46429 12.2229 8.05357 13.8754 9.41474 14.6013C10.7759 15.3273 13.3571 9.87786 15.125 7.33398"
        stroke="white"
        strokeWidth="1.46667"
        strokeLinecap="round"
      />
    </>
  ),
})

export const FlashIcon = createIcon({
  viewBox: "0 0 16 18",
  defaultProps: {
    width: "16px",
    height: "18px",
    fill: "none",
  },
  path: (
    <path
      d="M3.02344 9.87793C3.02344 9.71387 3.0918 9.57031 3.21484 9.41992L9.59277 1.51758C10.085 0.90918 10.8574 1.30566 10.5771 2.05078L8.49219 7.65625H12.457C12.7578 7.65625 12.9766 7.86133 12.9766 8.14844C12.9766 8.30566 12.915 8.44922 12.792 8.60645L6.40723 16.5088C5.91504 17.1104 5.14258 16.7139 5.42285 15.9688L7.50781 10.3701H3.54297C3.24219 10.3701 3.02344 10.1582 3.02344 9.87793Z"
      fill="white"
    />
  ),
})
