import { createIcon } from "@chakra-ui/react"

export const StarIcon = createIcon({
  displayName: "FireIcon",
  viewBox: "0 0 18 18",
  path: (
    <>
      <path d="M18 0H0V18H18V0Z" fill="#00B67A" />
      <path
        d="M8.99971 12.1317L11.7372 11.4379L12.881 14.9629L8.99971 12.1317ZM15.2997 7.57541H10.481L8.99971 3.03791L7.51846 7.57541H2.69971L6.59971 10.3879L5.11846 14.9254L9.01846 12.1129L11.4185 10.3879L15.2997 7.57541Z"
        fill="white"
      />
    </>
  ),
})
