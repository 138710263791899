import { FC } from "react"

import { useEnhancedPhotoData } from "~/store/selectors"
import { EnhancedPhotoProperties_EnhancementStatus } from "~/generated/paywall"

import { EnhancedPhoto3 } from "./EnhancedPhoto3"

const convertStatus = (status: EnhancedPhotoProperties_EnhancementStatus) =>
  status === EnhancedPhotoProperties_EnhancementStatus.ENHANCEMENT_STATUS_SUCCESS
    ? "user"
    : "default"

export const EnhancedPhoto3Container = () => {
  const { status, original_photo_url, enhanced_photo_url } = useEnhancedPhotoData()
  return (
    <EnhancedPhoto3
      type={convertStatus(status)}
      originalPhoto={original_photo_url}
      enhancedPhoto={enhanced_photo_url}
    />
  )
}
