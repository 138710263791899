import { FC } from "react"
import { Box, Flex, FlexProps, Tag, Text } from "@chakra-ui/react"
import { ThemeContentSwitch } from "~/theme/theme-from-interview/themeProvider"
import {
  type CurrencyCode,
  formatLengthWithUnit,
  formatPrice,
  formatUnitWithPrefixLength,
  formatUnitWithPrefixLengthDash,
  PeriodUnit,
} from "~/utils"
import { VFlex } from "~/components"

const defaultUnit = PeriodUnit.PERIOD_UNIT_UNSPECIFIED

const Row: FC<FlexProps> = (props) => (
  <Flex justify="space-between" align="center" alignSelf="stretch" direction="row" {...props} />
)

const TrialPriceTable: FC<{
  currency: CurrencyCode
  trialLength?: number
  trialUnit?: PeriodUnit
  trialPrice?: number
}> = ({ currency, trialLength = 3, trialUnit = defaultUnit, trialPrice = 100 }) => (
  <VFlex gap={3}>
    <VFlex
      paddingY={3}
      gap={1.5}
      borderColor="Base/neutralDisabled"
      borderTopWidth={1}
      borderBottomWidth={1}
    >
      <Row gap={4}>
        <Box textStyle="Paragraph/Primary">
          {`${formatUnitWithPrefixLengthDash(trialUnit, trialLength)} trial`}
        </Box>
        <Box textStyle="Subtitle/Secondary" color="Base/accentPrimary">
          {formatPrice(currency, trialPrice / 100)}
        </Box>
      </Row>
    </VFlex>
    <Text textStyle="Paragraph/Primary" color="Base/baseSecondary">
      You’ll have {formatUnitWithPrefixLength(trialUnit, trialLength)} to try out{" "}
      <ThemeContentSwitch pora="Pora's" lovi="Lovi's" /> science-backed approach to improve your
      skin health.
    </Text>
  </VFlex>
)

const SubscriptionPriceTable: FC<{
  currency: CurrencyCode
  subscriptionLength?: number
  subscriptionUnit?: PeriodUnit
  subscriptionTotalPrice?: number
  subscriptionPricePerUnit?: number
  discount?: string
}> = ({
  currency,
  subscriptionLength = 2,
  subscriptionUnit = defaultUnit,
  subscriptionTotalPrice = 100,
  subscriptionPricePerUnit = 100,
  discount = "",
}) => (
  <VFlex gap={3} borderColor="Base/neutralDisabled" borderTopWidth={1}>
    <Row paddingTop={3} gap={0.5}>
      <Box textStyle="Paragraph/Primary">
        {`${formatUnitWithPrefixLengthDash(subscriptionUnit, subscriptionLength)} plan`}
      </Box>
      <Box textStyle="Subtitle/Secondary">
        {formatPrice(currency, subscriptionPricePerUnit / 100)} /{" "}
        {formatLengthWithUnit(subscriptionUnit, 1)}
      </Box>
    </Row>
    <Row gap={0.5}>
      <Tag variant="priceDiscount">-{discount}% OFF</Tag>
      <Box textStyle="Subtitle/Secondary" color="Base/baseSecondary" textAlign="end">
        Billed at {formatPrice(currency, subscriptionTotalPrice / 100)}
      </Box>
    </Row>
  </VFlex>
)

export const Invoice2: FC<{
  currency: CurrencyCode
  trialLength?: number
  trialUnit?: PeriodUnit
  trialPrice?: number
  subscriptionLength?: number
  subscriptionUnit?: PeriodUnit
  subscriptionTotalPrice?: number
  subscriptionPricePerUnit?: number
  discount?: string
}> = ({
  currency,
  trialLength = 3,
  trialUnit = defaultUnit,
  trialPrice = 100,
  subscriptionLength = 2,
  subscriptionUnit = defaultUnit,
  subscriptionTotalPrice = 100,
  subscriptionPricePerUnit = 100,
  discount = "10",
}) => (
  <VFlex gap="40px">
    <VFlex gap={4}>
      <Box textStyle="Subtitle/Primary">Total today:</Box>
      <TrialPriceTable
        currency={currency}
        trialLength={trialLength}
        trialUnit={trialUnit}
        trialPrice={trialPrice}
      />
    </VFlex>
    <VFlex gap={4} w="full">
      <Box textStyle="Subtitle/Primary">Price after trial:</Box>
      <SubscriptionPriceTable
        currency={currency}
        subscriptionLength={subscriptionLength}
        subscriptionUnit={subscriptionUnit}
        subscriptionTotalPrice={subscriptionTotalPrice}
        subscriptionPricePerUnit={subscriptionPricePerUnit}
        discount={discount}
      />
    </VFlex>
  </VFlex>
)
