import { FC } from "react"
import { Box, SpaceProps } from "@chakra-ui/react"
import { formatDate } from "~/utils"
import { Timer } from "~/components"
import { useSelectedPrice } from "~/store/selectors"
import { TimerNotice1 } from "./TimerNotice1"
import { TimerNotice2, TimerNotice2Props } from "./TimerNotice2"
import { useUpsaleSubscriptionOnExit } from "../Blocks/UpsaleSubscriptionOnExitBlock"

const getMonth = formatDate("MONTH_ONLY_SHORT")
const today = new Date()
const generateCode = (prefix: string, date: Date = today) =>
  `${prefix}_${getMonth(date)}${date.getFullYear() % 100}`.toLocaleLowerCase()

const CODE = generateCode("newcomer")
const UPSALE_CODE = generateCode("lastcall")

const TimerNotice1Container: FC<SpaceProps> = (props) => (
  <TimerNotice1 {...props}>
    The offer ends in{" "}
    <Box as="span" display="inline-block" minW="5ex">
      <Timer />
    </Box>{" "}
    min
  </TimerNotice1>
)

const TimerNotice2Container: FC<Pick<TimerNotice2Props, "variant"> & SpaceProps> = ({
  variant,
  ...props
}) => {
  const { active, data } = useUpsaleSubscriptionOnExit()
  const price = useSelectedPrice()
  const discount = parseFloat(price?.economy_percent ?? "0")
  const dataProps = active
    ? {
        previousCode: CODE,
        code: UPSALE_CODE,
        discount: discount,
        previousDiscount: discount - data.discount_percent,
      }
    : {
        previousCode: undefined,
        code: CODE,
        discount: discount,
        previousDiscount: 0,
      }
  return <TimerNotice2 variant={variant} {...dataProps} {...props} />
}

export const TimerNoticeContainer: FC<{ variant: string } & SpaceProps> = ({
  variant,
  ...props
}) => {
  if (variant === "1") {
    return <TimerNotice1Container {...props} />
  }
  if (variant === "2" || variant === "3") {
    return <TimerNotice2Container variant={variant} {...props} />
  }
  return null
}
