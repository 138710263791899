import { Button } from "@chakra-ui/react"
import { FC, useCallback } from "react"
import { useAmplitude } from "~/utils/analytics/useAmplitude"
import { Invoice2Container } from "../../Invoice"
import { useScrollToOffer } from "../../scrollToOffer"
import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"

export const Invoice2BlockContainerImpl = () => {
  const scrollToOffer = useScrollToOffer("invoice_action_button")
  const log = useAmplitude()
  const onClickFn = useCallback(() => {
    log.click({ source: "invoice_action_button" })
    scrollToOffer()
  }, [log, scrollToOffer])

  return (
    <>
      <Invoice2Container />
      <Button mt={8} variant="action" w="full" onClick={onClickFn}>
        Get my program
      </Button>
    </>
  )
}

export const Invoice2BlockContainer: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props} header="Get your program">
    <Invoice2BlockContainerImpl />
  </BlockWrapper>
)

export const InvoicePrePaywallBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props} header="">
    <Invoice2BlockContainerImpl />
  </BlockWrapper>
)
