import { FC } from "react"
import { BlockWrapper, BlockWrapperProps } from "~/pages/Main/Blocks/BlockWrapper"
import { HeaderGTContainer } from "../HeaderGT/HeaderGTContainer"
import { AttributedStringType, TimerFloatContainer } from "~/components"

const title = {
  parts: [
    {
      content: "Your ",
      format: [],
    },
    {
      content: "personalized\n",
      format: [
        {
          format: {
            emphasis: true,
            $case: "emphasis",
          },
        },
      ],
    },
    {
      content: "skincare program",
      format: [],
    },
  ],
} as AttributedStringType

export const HeaderGTIBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props} paddingTop={4} overflow="hidden">
    <TimerFloatContainer variant={2} />
    <HeaderGTContainer title={title} />
  </BlockWrapper>
)
