import { FC, ReactNode, useCallback, useEffect, useRef, useState } from "react"
import { Box, Flex, Icon } from "@chakra-ui/react"
import { VFlex } from "~/components"
import { noop } from "~/utils"

import IconSoundOn from "./iconSoundOn.svg?react"
import IconSoundOff from "./iconSoundOff.svg?react"

const defaultMuted = Array.from({ length: 3 }, () => true) as [boolean, boolean, boolean]

interface VideoItemProps {
  src: string
  poster: string
  isMuted: boolean
  onClick?: () => void
}
export const VideoItem: FC<VideoItemProps> = ({ src, poster, isMuted, onClick = noop }) => {
  const ref = useRef<HTMLVideoElement>(null)
  useEffect(() => {
    if (ref.current) {
      ref.current.muted = isMuted
    }
  }, [isMuted, ref])

  const _onClick = useCallback(() => {
    if (ref.current) {
      ref.current.play()
      onClick()
    }
  }, [onClick, ref])

  return (
    <Box
      borderRadius="full"
      w="240px"
      h="240px"
      bgColor="black"
      onClick={_onClick}
      overflow="hidden"
      display="grid"
      gridTemplate="'container' 1fr"
    >
      <Box
        w="100%"
        h="100%"
        bgGradient="linear-gradient(180deg, transparent 67%, black 100%)"
        display="flex"
        gridArea="container"
        alignItems="flex-end"
        justifyContent="center"
        zIndex={1}
      >
        <Icon
          w="40px"
          h="40px"
          as={isMuted ? IconSoundOff : IconSoundOn}
          color="white"
          position="relative"
          bottom="8px"
        />
      </Box>
      <video
        ref={ref}
        muted
        src={src}
        poster={poster}
        style={{ width: "100%", height: "100%", background: "black", gridArea: "container" }}
        disableRemotePlayback={true}
        disablePictureInPicture={true}
        autoPlay={true}
        playsInline={true}
        preload="metadata"
      ></video>
    </Box>
  )
}

const VideoReviewItem: FC<VideoItemProps & { title: ReactNode }> = ({ title, ...props }) => (
  <VFlex gap={3} alignItems="center">
    <VideoItem {...props} />
    <Box textStyle="Paragraph/Primary">{title}</Box>
  </VFlex>
)

export const VideoReviews: FC = () => {
  const [isMuted, setIsMuted] = useState([true, true, true] as typeof defaultMuted)
  const onClick = useCallback(
    (i: 0 | 1 | 2) => () => {
      const muted: typeof defaultMuted = [...defaultMuted]
      muted[i] = !isMuted[i]
      setIsMuted(muted)
    },
    [isMuted]
  )
  return (
    <Flex gap={6} paddingX={6} w="full" minH="274px" overflowX="scroll">
      <VideoReviewItem
        poster="https://storage.pora.ai/b0f79420ccf1c76969a31c9bfe2c5866.webp"
        src="https://storage.pora.ai/8f88357f84cc7bb292f2def52817f5b5.mp4"
        isMuted={isMuted[0]}
        onClick={onClick(0)}
        title="Janet Anderson"
      />
      <VideoReviewItem
        src="https://storage.pora.ai/5469a7a46f58ee451bda33a228327837.mp4"
        poster="https://storage.pora.ai/06e7e1f0451d319ff1a41a26854af653.webp"
        isMuted={isMuted[1]}
        onClick={onClick(1)}
        title="Tessa Jowell"
      />
      <VideoReviewItem
        src="https://storage.pora.ai/8705735c8e2dfc011bd99c053ebb54b4.mp4"
        poster="https://storage.pora.ai/cee5ea94898e596d1e3ce3c68344901f.webp"
        isMuted={isMuted[2]}
        onClick={onClick(2)}
        title="Cora Swift"
      />
    </Flex>
  )
}
