import { Box, Center, Flex, Heading, Image, List, ListItem, Text } from "@chakra-ui/react"
import { MoneyIcon, PoraLogoIcon } from "~/pages/Main/PersonalProducts/Icons"

type Product = { img: string; title: string; description: string }

const PersonalProductListItem = ({ img, title, description }: Product) => (
  <ListItem>
    <Flex alignItems="center">
      <Image w="48px" h="48px" src={img} alt={title} mr={3} />
      <Flex direction="column" justifyContent="center">
        <Heading as="h5" size="MediumSubtitleSecondary" variant="text">
          {title}
        </Heading>
        <Flex
          gap={1}
          alignItems={"center"}
          textStyle="RegularParagraphSecondary"
          textColor="Base/baseDisabled"
        >
          <MoneyIcon fontSize={16} />
          {description}
        </Flex>
      </Flex>
    </Flex>
  </ListItem>
)

export const PersonalProducts = ({ productsList }: { productsList: Product[] }) => {
  return (
    <>
      <Heading size="MediumHeaderSecondary" textAlign="center" marginBottom={4}>
        Personal list
        <br />
        of skincare products
        <Box textColor="Base/baseDisabled">Out of 50000+</Box>
      </Heading>
      <List width="full" spacing={5} mb={8}>
        {productsList.map((product, i) => (
          <PersonalProductListItem {...product} key={i} />
        ))}
      </List>
      <Center
        borderRadius="2xl"
        w="full"
        borderColor="Base/neutralDisabled"
        borderWidth={1}
        textStyle="MediumHintPrimary"
        textColor="Base/basePrimary"
        p={3}
      >
        <PoraLogoIcon fontSize={33} />
        <Text as="span" ml={3}>
          pora is not affiliated with
          <br />
          any skincare brand
        </Text>
      </Center>
    </>
  )
}
