import { FC, ReactNode, useMemo } from "react"
import { Box, Flex, FlexProps, Text } from "@chakra-ui/react"
import { VFlex } from "~/components"
import { PROGRAM_LENGTH_WEEKS } from "~/constants"
import { CosmeticsIcon, SmileIcon, TargetIcon } from "~/pages/Main/HeaderPrePaywall/Icons"
import type { SvgIconComponent } from "~/types"
import { formatDate, getProgramEndDate } from "~/utils"

const formatDateLong = formatDate("FULL_LONG")

const GoalListItem: FC<{ icon: SvgIconComponent; children: ReactNode }> = ({
  icon: Icon,
  children,
}) => (
  <Flex alignItems="center" gap={4}>
    <Icon fontSize="36px" color="Base/basePrimary" />
    <Box>{children}</Box>
  </Flex>
)

export const GoalsList: FC<FlexProps> = (props) => {
  const endDate = useMemo(() => getProgramEndDate(PROGRAM_LENGTH_WEEKS), [])
  const longEndDate = useMemo(() => formatDateLong(endDate), [endDate])

  return (
    <VFlex textStyle="Regular/Paragraph" gap={3} {...props}>
      <GoalListItem icon={TargetIcon}>
        <Text textStyle="Subtitle/Secondary" as="span">
          See visible results
        </Text>{" "}
        by {longEndDate} and&nbsp;feel great
      </GoalListItem>
      <GoalListItem icon={CosmeticsIcon}>Get personalized product recommendations</GoalListItem>
      <GoalListItem icon={SmileIcon}>
        Boost the result with easy-to-follow skincare program
      </GoalListItem>
    </VFlex>
  )
}
