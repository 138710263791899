import { FC, useCallback, useMemo } from "react"
import { AttributedStringType, TT, Timer } from "~/components"
import { getProgramEndDate } from "~/utils"
import { PROGRAM_LENGTH_WEEKS } from "~/constants"
import { useBlockProperties, useTimerData, useHeaderLuvlyData } from "~/store/selectors"

import { useScrollToOffer } from "../../scrollToOffer"
import { useAmplitude } from "~/utils/analytics/useAmplitude"

import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"
import { HeaderLuvly } from "./HeaderLuvly"

const formatToAttributedString = (s: string): AttributedStringType =>
  s.split("**").reduce(
    (acc, content, currIdx) => {
      if (currIdx % 2 === 1) {
        acc.parts.push({ content, format: [{ format: { $case: "emphasis", emphasis: true } }] })
      } else {
        acc.parts.push({ content, format: [] })
      }
      return acc
    },
    { parts: [] } as AttributedStringType
  )

const HeaderLuvlyContainer = () => {
  const { main_goal_text: resultsText, main_goal_img: graph } = useHeaderLuvlyData()
  const log = useAmplitude()

  const timerBlockClick = useScrollToOffer("header_timer")
  const onTimerBlockClick = useCallback(() => {
    log.click({ source: "header_timer" })
    timerBlockClick()
  }, [log, timerBlockClick])

  const endDate = useMemo(() => getProgramEndDate(PROGRAM_LENGTH_WEEKS), [])
  const { minutes } = useTimerData()
  const timerDescription = `Your personalized skin care program has been reserved for the next ${minutes} minutes`
  const blockProperties = useBlockProperties()
  const title = useMemo(() => {
    const result = blockProperties["PB_HEADER_LUVLY.title"] ?? `Your personalized\nplan is ready!`
    return formatToAttributedString(result)
  }, [blockProperties])

  return (
    <HeaderLuvly
      onTimerBlockClick={onTimerBlockClick}
      title={<TT>{title}</TT>}
      timerDescription={timerDescription}
      timeBlock={<Timer />}
      graphImg={graph}
      endDate={endDate}
      resultsText={resultsText}
      programLengthWeeks={PROGRAM_LENGTH_WEEKS}
      variant={1}
    />
  )
}

export const HeaderLuvlyBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props} paddingTop={4} overflow="hidden">
    <HeaderLuvlyContainer />
  </BlockWrapper>
)
