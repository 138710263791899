import { FC, memo } from "react"
import { PriceItem1 } from "./PriceItem1"
import { PriceItem2 } from "./PriceItem2"
import { PriceItem3 } from "./PriceItem3"
import { PriceItem4 } from "./PriceItem4"
import { PriceItem5, PriceItem6 } from "./PriceItem5"
import { PriceItem7, PriceItem8 } from "./PriceItem7"
import { PriceItem9 } from "./PriceItem9"
import type { PriceItemProps, PriceSelectVariants } from "./types"

const _PriceSelectItem: FC<{ variant: PriceSelectVariants } & PriceItemProps> = ({
  variant,
  ...props
}) => {
  const Item = {
    1: PriceItem1,
    2: PriceItem2,
    3: PriceItem3,
    4: PriceItem4,
    5: PriceItem5,
    6: PriceItem6,
    7: PriceItem7,
    8: PriceItem8,
    9: PriceItem9,
  }[variant]
  return <Item {...props} />
}

export const PriceSelectItem = memo(_PriceSelectItem)
