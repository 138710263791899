import { FC } from "react"

import { BlockWrapperProps, BlockWrapper } from "../BlockWrapper"
import { EnhancedPhoto3Container } from "./EnhancedPhoto3Container"

export const EnhancedPhoto3Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props}>
    <EnhancedPhoto3Container />
  </BlockWrapper>
)
