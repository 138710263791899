import reviewImg1 from "./images/avatars/1.webp?url"
import reviewImg2 from "./images/avatars/2.webp?url"
import reviewImg3 from "./images/avatars/3.webp?url"
import { Review, Reviews5 } from "./Reviews5"

const reviews: Review[] = [
  {
    author: "Megan H.",
    img: reviewImg1,
    usageLength: 3,
    date: "Apr 24th, 2024",
    title: "Just thank you!",
    text: "Dealing with my shiny, oily skin was tough. Breakouts were a regular thing. Lóvi changed the game for me. They picked out products that really work and taught me how to use them right. My skin is way less oily and it feels fresh. I'm not worried about acne like before. I feel fabulous!",
  },
  {
    author: "Melissa J.",
    img: reviewImg2,
    usageLength: 3,
    date: "Jan 27th, 2023",
    title: "Lovi is a game-changer!",
    text: "Wow! I was recommended products that fits me 100% and more importantly don't cost like a spaceship. Finally, I don't need to overpay. Thanks, Lovi! From me and my wallet :)",
  },
  {
    author: "Merilyn D.",
    img: reviewImg3,
    usageLength: 2,
    date: "Jan 18th, 2023",
    title: "Simply Love it!",
    text: "Great way to make time for yourself daily",
  },
]

export const Reviews5Container = () => {
  return <Reviews5 reviews={reviews} />
}
