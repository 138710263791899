import { FC, ReactNode } from "react"
import { Box, Flex, FlexProps, BoxProps, List, ListIcon, ListItem } from "@chakra-ui/react"
import { PriceContentV2_PriceItem } from "~/generated/paywall"
import { MarkIcon } from "../../pages/Main/PaymentForm/PaymentFormStripe/Icons"
import { useSelectedPrice } from "~/store/selectors"
import { textAbove, text1, text2, list1, list2 } from "./texts"
import { useThemeContentSwitch } from "~/theme/theme-from-interview/themeProvider"

const BelowList: FC<{ list: string[] }> = ({ list }) => (
  <List
    spacing={3}
    textStyle="Paragraph/Secondary"
    color="Base/baseSecondary"
    sx={{
      " svg": {
        marginInlineStart: "-22px",
        marginInlineEnd: 0,
      },
      marginLeft: "22px",
    }}
  >
    {list.map((title, i) => (
      <ListItem key={i}>
        <ListIcon as={MarkIcon} color="Base/accentPrimary" fontSize="20px" />
        {title}
      </ListItem>
    ))}
  </List>
)

const TextBelowPaymentForm: FC<FlexProps & { list: string[]; text: string }> = ({
  list,
  text,
  ...props
}) => (
  <Flex gap={6} direction="column" {...props}>
    <BelowList list={list} />

    <Box color="Base/baseSecondary" textStyle="Paragraph/Secondary">
      {text}
    </Box>
  </Flex>
)

export const TextBelowPaymentFormContainer: FC<FlexProps & { variant?: 1 | 2 }> = ({
  variant = 1,
  ...props
}) => {
  const price = useSelectedPrice()
  const app_name = useThemeContentSwitch({ pora: "Pora", lovi: "Lovi" })
  if (price && variant === 1) {
    return <TextBelowPaymentForm list={list1(price)} text={text1(price, app_name)} {...props} />
  } else if (price && variant === 2) {
    return <TextBelowPaymentForm list={list2(price)} text={text2(price)} {...props} />
  }

  return null
}

export const TextPriceContainer: FC<
  BoxProps & { formatter: (p: PriceContentV2_PriceItem) => ReactNode }
> = ({ formatter, ...props }) => {
  const selectedPrice = useSelectedPrice()
  if (selectedPrice) {
    return (
      <Box textStyle="Paragraph/Secondary" color="Base/baseSecondary" {...props}>
        {formatter(selectedPrice)}
      </Box>
    )
  }

  return null
}

export const TextAbovePaymentFormContainer: FC<BoxProps> = (props) => (
  <TextPriceContainer textAlign="center" formatter={textAbove} {...props} />
)

export const TextBelowPriceSelectContainer: FC<BoxProps> = (props) => (
  <TextPriceContainer textStyle="Paragraph/Tertiary" formatter={text2} {...props} />
)
