import { Box, Center, Flex, Image, SimpleGrid } from "@chakra-ui/react"
import { FC, ReactNode } from "react"
import { useSummaryList } from "~/store/selectors"
import { ListItemGeneric } from "~/components"

const BadgeOnIcon: FC<{ children: ReactNode }> = ({ children }) => (
  <div style={{ position: "absolute" }}>
    <Center
      position="relative"
      right="-59px"
      top="-3px"
      minW="18px"
      h="18px"
      color="white"
      bgColor="Base/basePrimary"
      borderRadius="full"
      textStyle="Paragraph/Tertiary"
      paddingX={1}
      textAlign="right"
    >
      {children}
    </Center>
  </div>
)

const SummaryListItem: FC<{
  image: string
  title: string
  description?: string
  badge?: string
}> = ({ image, badge, title, description }) => (
  <ListItemGeneric title={title} description={description}>
    <Box>
      {badge && <BadgeOnIcon>{badge}</BadgeOnIcon>}
      <Flex
        w="74px"
        h="44px"
        alignItems="center"
        justifyContent="center"
        borderRadius="xl"
        bgColor="Base/neutralSecondary"
      >
        <Image src={image} alt="" w="74px" h="44px" objectFit="contain" />
      </Flex>
    </Box>
  </ListItemGeneric>
)

type ListItem = {
  image?: string
  title?: string
  description?: string
  badge?: string
}

export const SummaryList: FC<{ list: ListItem[] }> = ({ list }) => (
  <SimpleGrid columns={1} spacing={4} alignSelf="flex-start" w="100%">
    {list.map(
      ({ image, title, description, badge }) =>
        image &&
        title && (
          <SummaryListItem
            key={title}
            image={image}
            title={title}
            description={description}
            badge={badge}
          />
        )
    )}
  </SimpleGrid>
)

export const SummaryListContainer: FC = () => {
  const list = useSummaryList()
  return <SummaryList list={list} />
}
