import { FC, ReactNode } from "react"
import { Box, Flex } from "@chakra-ui/react"
import type { PriceItemProps } from "./types"
import { PriceItemWrapper } from "./PriceItem2"

const PriceLayoutTitle2: FC<{ title: ReactNode }> = ({ title }) => (
  <Flex gap={1} direction="column" flex="1">
    <Box textStyle="Subtitle/Tertiary" maxW="80px">
      {title}
    </Box>
  </Flex>
)

export const PriceItem3: FC<PriceItemProps> = (props) => (
  <PriceItemWrapper {...props}>
    <PriceLayoutTitle2 title={props.title} />
  </PriceItemWrapper>
)
