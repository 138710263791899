import { FC, ReactNode, useCallback, useContext, useEffect, useState } from "react"
import { Box, Center, Flex, Heading, Image, useBoolean } from "@chakra-ui/react"
import { VFlex } from "~/components/VFlex"
import { TextMoneyBack } from "~/components/StaticTexts"

import { useAmplitude } from "~/utils/analytics/useAmplitude"
import { useBlockPropertiesExtended } from "~/store/selectors"
import { PriceContentV2_PriceItem } from "~/generated/paywall"
import { useMakePurchase } from "~/utils/useMakePurchase"
import { Logo } from "~/pages/Main/SkipTrial/Icons"

import {
  BenefitCardList,
  DiscountCard,
  LegalNotice,
  PriceBox,
  PromoPriceText,
  SubmitButtons,
  TimerBlock,
  WhyLoviList,
  PaymentStatus,
  PaymentSuccessVariant,
  UpsaleContext,
} from "../components"

import { CheckIcon } from "./icons"
import { Step2Modal2 } from "./Step2Modal2"

enum UpsaleAIStep {
  START,
  UPSALE_SKIPPED,
  DISCOUNTED_OFFER,
}

const VideoHero = () => (
  <video
    muted
    src="https://storage.pora.ai/9f2a485c2bb9aab3fa9828068414f37f.mp4"
    poster="https://storage.pora.ai/42e16a18665316a9d106a0f6961e62b6.webp"
    style={{ width: "100%", height: "100%", aspectRatio: "1" }}
    disableRemotePlayback={true}
    disablePictureInPicture={true}
    loop={true}
    autoPlay={true}
    playsInline={true}
    preload="metadata"
  />
)

const UnorderedListItem = ({ children }: { children: ReactNode }) => (
  <Flex gap={1.5}>
    <CheckIcon />
    <Box textStyle="Subtitle/Secondary">{children}</Box>
  </Flex>
)

const BundleIncludes = () => (
  <Box>
    <VFlex gap={6} p={6} layerStyle="attentionFrame">
      <Heading as="h2" size="Header/Secondary">
        What&apos;s Included in the All-In-One Lóvi Bundle
      </Heading>
      <VFlex gap={3}>
        <UnorderedListItem>Unbiased Advice</UnorderedListItem>
        <UnorderedListItem>Expert Video Guidance</UnorderedListItem>
        <UnorderedListItem>Tailored Product Suggestions</UnorderedListItem>
        <UnorderedListItem>Ingredient Safety Insights</UnorderedListItem>
        <UnorderedListItem>Curated Alternatives</UnorderedListItem>
      </VFlex>
      <Image src="https://storage.pora.ai/1fd5b0cc401576e1f5055b5ff800d7c7.webp" />
    </VFlex>
    <Box mt={4} color="Base/baseSecondary" textStyle="Subtitle/Secondary">
      Enhance your skincare routines with AI-Powered scanner
    </Box>
  </Box>
)

const PriceBoxInstance: FC<{ price: PriceContentV2_PriceItem }> = ({ price }) => (
  <PriceBox price={price}>Amount of messages</PriceBox>
)

export const UpsaleAIAssistant2: FC<{
  step: UpsaleAIStep
  isLoading: boolean
  discount: string
  onPay: () => void
  onSkip: () => void
  selectedPrice: PriceContentV2_PriceItem
  onConfirmUpsaleModal: () => void
}> = ({ step, isLoading, discount, onPay, onSkip, selectedPrice, onConfirmUpsaleModal }) => {
  return (
    <Box pos="relative" bgColor="white" paddingX={6}>
      <VFlex gap={10}>
        <Box>
          <Center mb={4} height="56px" as="header">
            <Logo />
          </Center>
          {step === UpsaleAIStep.DISCOUNTED_OFFER && <DiscountCard discount={discount} />}
          {selectedPrice && (
            <VFlex py={4} layerStyle="attentionFrame" gap={6}>
              <VideoHero />
              <PromoPriceText price={selectedPrice} />
              <Box mx={6}>
                <SubmitButtons isLoading={isLoading} onPay={onPay} onSkip={onSkip} />
              </Box>
            </VFlex>
          )}
        </Box>
        {selectedPrice && <PriceBoxInstance price={selectedPrice} />}
        {selectedPrice && <LegalNotice price={selectedPrice} />}
        <VFlex gap={4}>
          <Heading as="h2" size="Header/Secondary">
            Why It Is Better To Get The Lóvi Bundle?
          </Heading>
          <BenefitCardList />
        </VFlex>
        <BundleIncludes />
        <WhyLoviList />

        {selectedPrice && <PriceBoxInstance price={selectedPrice} />}
        <Box p={6} layerStyle="attentionFrame">
          <TextMoneyBack />
        </Box>
        {selectedPrice && <LegalNotice price={selectedPrice} />}
      </VFlex>
      <TimerBlock />
      <Step2Modal2
        discount={discount}
        isOpen={step === UpsaleAIStep.UPSALE_SKIPPED}
        onClose={onConfirmUpsaleModal}
      />
    </Box>
  )
}

export const UpsaleAIAssistant2Container: FC = () => {
  const [step, setStep] = useState<UpsaleAIStep>(UpsaleAIStep.START)
  const { goNext } = useContext(UpsaleContext)
  const makePurchase = useMakePurchase()
  const blockProperties = useBlockPropertiesExtended()

  const price = blockProperties["PB_UPSALE_AI_ASSISTANT_2.prices"]?.prices?.[0]
  const discountPrice = blockProperties["PB_UPSALE_AI_ASSISTANT_2.discountedPrices"]?.prices[0]

  const selectedPrice = step === UpsaleAIStep.START ? price : discountPrice
  const [paymentPageVariant, setPaymentPageVariant] = useState<PaymentSuccessVariant>()

  const [isLoading, setLoading] = useBoolean()
  const log = useAmplitude()
  useEffect(() => {
    log.upsaleAIAssistantView({ step })
  }, [log, step])

  useEffect(() => {
    document.querySelector("html")?.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (step === UpsaleAIStep.DISCOUNTED_OFFER) {
      document.querySelector("html")?.scrollTo(0, 0)
    }
  }, [step])

  const onSuccess = useCallback(() => {
    log.upsaleAIAssistantBuySuccess()
    setPaymentPageVariant("aiAssistantSuccess")
  }, [log, setPaymentPageVariant])

  const onConfirmUpsaleModal = () => {
    setStep(UpsaleAIStep.DISCOUNTED_OFFER)
  }

  const onSkip = () => {
    if (step === UpsaleAIStep.START) {
      setStep(UpsaleAIStep.UPSALE_SKIPPED)
    }

    if (step === UpsaleAIStep.DISCOUNTED_OFFER) {
      log.upsaleAIAssistantExit()
      setPaymentPageVariant("aiAssistantFail")
    }
  }

  const pay = async () => {
    try {
      if (!selectedPrice) {
        throw new Error("Unknown Price")
      }
      setLoading.on()
      log.upsaleAIAssistantBuy({ priceId: selectedPrice.id })
      const result = await makePurchase(selectedPrice)
      if (result) {
        setLoading.off()
        onSuccess()
        return true
      }
    } catch (error) {
      log.upsaleAIAssistantBuyError()
      setPaymentPageVariant("aiAssistantFail")
    }
    return false
  }

  const onPay = async () => {
    pay()
  }

  if (!price || !discountPrice || !selectedPrice) {
    console.error("empty prices", price, discountPrice, selectedPrice)
    goNext()
    return null
  }

  return (
    <>
      {!paymentPageVariant && (
        <UpsaleAIAssistant2
          step={step}
          discount={discountPrice.subscription_text || "10%"}
          selectedPrice={selectedPrice}
          isLoading={isLoading}
          onPay={onPay}
          onSkip={onSkip}
          onConfirmUpsaleModal={onConfirmUpsaleModal}
        />
      )}
      {paymentPageVariant && <PaymentStatus variant={paymentPageVariant} />}
    </>
  )
}
