import { ReviewsBetterMe } from "~/pages/Main/ReviewsBetterMe/ReviewsBetterMe"

const header = "Users love our plans"
const reviews = [
  {
    title: "How the program helps me",
    date: "25 Feb",
    author: "Courtney Pade",
    text: "I’ve attempted facial exercises numerous times and bought cosmetics that went unused. Lovi has become a valuable addition to my list of good habits. I now consistently wash my face, apply tonic, serum, and cream daily!!!, and the results are amazing. Surprisingly, I use fewer cosmetics and the outcome is even better. My skin has tightened and my face has a youthful glow. While I don’t always perform the exercises, I follow the product application tutorial videos, and I now enjoy looking at myself in the morning. Thank you, Lovi!",
  },
  {
    title: "Just thank you!",
    date: "11 Feb",
    author: "Pam Rose",
    text: "Wow, what a game-changer! Thank you to the genius behind this incredible skincare app. Finding the right cosmetics has never been easier, and the recommendations have been spot on. The step-by-step video instructions made it easy to start using the products and now I can apply them with confidence on my own. My colleagues even commented on how rested and refreshed my face looks, as if I just got back from a vacation. Thank you so much!",
  },
  {
    title: "Vegan Friendly!🌱🥦🌳🌵",
    date: "23 Jan",
    author: "Claudia Reed",
    text: "I am overjoyed to have found a skincare app that aligns with my values. No longer do I have to choose between effective and vegan cosmetics, this app offers the best of both worlds! The products recommended are not only cruelty-free, but also environmentally conscious. This app represents the perfect harmony between science and sustainable consumption, something that should be a standard in our modern world. Thank you for making it possible!",
  },
]

export const ReviewsBetterMeContainer = () => {
  return <ReviewsBetterMe header={header} reviews={reviews} />
}
