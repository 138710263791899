import { FC } from "react"

import { BlockWrapperProps, BlockWrapper, BlockHeading } from "../BlockWrapper"
import { FaceScanResult } from "./FaceScanResult"
import { BlockPropertyContent } from "~/components"
import { Box } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

export const FaceScanResultBlock: FC<BlockWrapperProps> = (props) => {
  const { t } = useTranslation()
  return (
    <BlockWrapper paddingX={6} paddingY={6} {...props}>
      <BlockHeading>
        <BlockPropertyContent propKey="PB_FACESCAN_RESULTS.title">
          {t("face_scan_result.analysis_overview")}
        </BlockPropertyContent>
      </BlockHeading>
      <Box mt={2} w="full">
        <FaceScanResult />
      </Box>
    </BlockWrapper>
  )
}
