import { FC } from "react"

import { BlockWrapperProps, BlockWrapper } from "../BlockWrapper"
import { EnhancedPhoto2Container } from "./EnhancedPhoto2Container"

export const EnhancedPhoto2Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper variant="transparent" {...props}>
    <EnhancedPhoto2Container />
  </BlockWrapper>
)
