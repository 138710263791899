import { FC } from "react"
import { Timer } from "~/components"
import { useHeader2Data, useSelectedPrice } from "~/store/selectors"
import { formatPercent } from "~/utils"
import { useScrollToOffer } from "../../scrollToOffer"
import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"
import { Header2 } from "./Header2"

export const Header2Container: FC = () => {
  const { title = "" } = useHeader2Data()
  const { economy_percent } = useSelectedPrice() ?? { economy_percent: "0" }
  const discountText = `-${formatPercent(parseFloat(economy_percent))}`
  const onClick = useScrollToOffer("header_timer")

  return <Header2 title={title} offer={discountText} time={<Timer />} onClick={onClick} />
}

export const Header2Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props} gap={6} paddingX={0} paddingBottom={0} paddingTop={4} overflow="hidden">
    <Header2Container />
  </BlockWrapper>
)
