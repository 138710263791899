import { FC } from "react"
import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"
import { SubscriptionTextContainer, TextBox3 } from "~/components/StaticTexts"

export const SubscriptionInfoTextBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props}>
    <TextBox3>
      <SubscriptionTextContainer />
    </TextBox3>
  </BlockWrapper>
)
