import { Box, Flex, Heading, List, ListItem, Text } from "@chakra-ui/react"
import { QuestionIcon } from "~/pages/Main/PeopleOftenAsk/Icons"

type Question = {
  question: string
  responseTexts: string[]
}

const PeopleOftenAskListItem = ({ question, responseTexts }: Question) => {
  return (
    <ListItem>
      <Flex gap={2}>
        <QuestionIcon fontSize={28} />
        <Box>
          <Heading as="h4" size="MediumSubtitlePrimary" variant="text" mb={2}>
            {question}
          </Heading>
          {responseTexts.map((response, i) => (
            <Text
              key={i}
              textStyle="RegularParagraphSecondary"
              textColor="Base/baseSecondary"
              _notLast={{ mb: 2 }}
            >
              {response}
            </Text>
          ))}
        </Box>
      </Flex>
    </ListItem>
  )
}

export const PeopleOftenAsk = ({
  header,
  questions,
}: {
  header: string
  questions: Question[]
}) => {
  return (
    <>
      <Heading size="MediumHeaderSecondary" textAlign="center" marginBottom={4}>
        {header}
      </Heading>
      <List width="full" spacing={8}>
        {questions.map((question, i) => (
          <PeopleOftenAskListItem {...question} key={i} />
        ))}
      </List>
    </>
  )
}
