import { FC } from "react"
import { Image } from "@chakra-ui/react"
import { useBlockProperties } from "~/store/selectors"
import { BlockPropertyContent, VFlex } from "~/components"

import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"
import { ActionButton } from "../../ActionButton"

const ImageBase: FC<BlockWrapperProps & { blockName: string }> = ({ blockName, ...props }) => {
  const blockProperties = useBlockProperties()

  const title = blockProperties[`${blockName}.title`] ?? "Why Lóvi?"
  const image =
    blockProperties[`${blockName}.image`] ??
    "https://storage.pora.ai/a7330c8e5807ab50452d1787f0780eae.webp"
  return (
    <BlockWrapper header={title} paddingX={0} paddingBottom={0} overflow="hidden" {...props}>
      <Image src={image} alt={title} />
    </BlockWrapper>
  )
}

export const ImageBlock: FC<BlockWrapperProps> = (props) => (
  <ImageBase blockName="PB_IMAGE" {...props} />
)
export const Image2Block: FC<BlockWrapperProps> = (props) => (
  <ImageBase blockName="PB_IMAGE_2" {...props} />
)
export const Image3Block: FC<BlockWrapperProps> = (props) => (
  <ImageBase blockName="PB_IMAGE_3" {...props} />
)

export const ImageWithButtonBlock: FC<BlockWrapperProps> = (props) => {
  const blockProperties = useBlockProperties()
  const image =
    blockProperties["PB_IMAGE_WITH_BUTTON.image"] ??
    "https://storage.pora.ai/81314436963dc716465ab8f9930eaad5.webp"
  return (
    <BlockWrapper {...props}>
      <VFlex bgColor="Base/accentPrimaryBg2" borderRadius="brand24" p={6} gap={6}>
        <Image src={image} alt="" />
        <ActionButton actionSource="PB_IMAGE_WITH_BUTTON">
          <BlockPropertyContent propKey="PB_IMAGE_WITH_BUTTON.button_text">
            Get My Plan
          </BlockPropertyContent>
        </ActionButton>
      </VFlex>
    </BlockWrapper>
  )
}
