import { createIcon } from "@chakra-ui/react"

export const StarIcon = createIcon({
  displayName: "FireIcon",
  viewBox: "0 0 18 18",
  path: (
    <>
      <path
        d="M7.93737 3.05706C8.37913 1.78616 8.60001 1.15072 9 1.15072C9.39999 1.15072 9.62087 1.78616 10.0626 3.05706L10.859 5.34809C10.9833 5.70569 11.0454 5.88449 11.1882 5.9882C11.331 6.09192 11.5202 6.09578 11.8987 6.10349L14.3237 6.15291C15.6689 6.18032 16.3415 6.19403 16.4651 6.57444C16.5887 6.95485 16.0526 7.36129 14.9804 8.17416L13.0476 9.63951C12.7459 9.86823 12.5951 9.98259 12.5406 10.1504C12.4861 10.3182 12.5409 10.4994 12.6505 10.8618L13.3529 13.1833C13.7425 14.4712 13.9373 15.1151 13.6137 15.3502C13.2901 15.5853 12.7379 15.2011 11.6335 14.4325L9.64258 13.0471C9.33183 12.8309 9.17645 12.7228 9 12.7228C8.82355 12.7228 8.66817 12.8309 8.35742 13.0471L6.36652 14.4325C5.26211 15.2011 4.7099 15.5853 4.38631 15.3502C4.06271 15.1151 4.25752 14.4712 4.64714 13.1833L5.3495 10.8618C5.45913 10.4994 5.51395 10.3182 5.45942 10.1504C5.40489 9.98259 5.25405 9.86823 4.95237 9.63951L3.01956 8.17415C1.94737 7.36128 1.41128 6.95485 1.53489 6.57444C1.65849 6.19403 2.33109 6.18032 3.6763 6.15291L6.10129 6.10349C6.4798 6.09578 6.66905 6.09192 6.8118 5.9882C6.95455 5.88449 7.0167 5.70569 7.141 5.34809L7.93737 3.05706Z"
        fill="#FF7F00"
      />
    </>
  ),
})
