import { createIcon } from "@chakra-ui/react"

export const LockIcon = createIcon({
  viewBox: "0 0 25 24",
  defaultProps: {
    fill: "none",
  },
  path: (
    <>
      <path
        d="M5.564 12.09A2.7 2.7 0 018.26 9.255h8.488a2.7 2.7 0 012.697 2.835l-.32 6.404a2.7 2.7 0 01-2.697 2.565H8.58a2.7 2.7 0 01-2.696-2.565l-.32-6.404z"
        fill="currentColor"
      />
      <path
        d="M8.473 9.498C8.001 5.5 10.002 3 12.501 3c2.5 0 4.5 2.5 4 6.498"
        stroke="currentColor"
        strokeWidth="2.7"
        strokeLinecap="round"
      />
    </>
  ),
})
